import React from 'react';
import { Button, Modal, Spin } from 'antd';
import { ENotificationType } from 'common/const/enum';

interface IComponentProps {
  open: boolean;
  type: ENotificationType;
  header?: string;
  description: React.ReactNode;
  loading?: boolean;
  error?: string | null;
  confirmTitle?: string;
  cancelTitle?: string;
  width?: number;
  onConfirmClick: () => void;
  onCancelClick: () => void;
}

export const Notification: React.FC<IComponentProps> = (props) => {
  const {
    open,
    type,
    header,
    description,
    loading = false,
    error,
    confirmTitle = 'Подтвердить',
    cancelTitle = 'Отменить',
    width = 380,
    onConfirmClick,
    onCancelClick,
  } = props;

  return (
    <Modal className="modal-gray" open={open} footer={false} closeIcon={false} width={width}>
      <Spin spinning={loading}>
        <div className="modal-gray__body">
          {header && <div className="modal-gray__header">{header}</div>}

          <div className="modal-gray__title">{description}</div>

          {!!error && <div className="modal-gray__error">{error}</div>}
        </div>

        <div className="modal-gray__footer">
          <Button className="btn btn-default" onClick={onCancelClick}>
            {cancelTitle}
          </Button>

          <Button
            className={`btn ${type === ENotificationType.Notification ? 'btn-primary' : 'btn-red'}`}
            onClick={onConfirmClick}
          >
            {confirmTitle}
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};
