import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import {
  IStock,
  IStockListParams,
  IStockListState,
  IStockCreatePayload,
  IStockDeletePayload,
  IStockState,
  IStockUpdatePayload,
} from 'entities/Stock/Stock.models';
import { stockTransport } from 'entities/Stock/Stock.transport';
import { updateStockListState } from 'entities/Stock/Stock.helper';

export const stockListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IStockListState,
  reducers: {
    setStockList: (state, payload: IStock[]) => ({ ...state, data: payload }),
    setStockListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    addStock: updateStockListState.addStock,
    updateStock: updateStockListState.updateStock,
    deleteStock: updateStockListState.deleteStock,
  },
  effects: (dispatch) => ({
    async getStockList(params: IStockListParams) {
      dispatch.stockListState.setStockListLoading(true);

      try {
        const response = await stockTransport.getStockList(params);
        dispatch.stockListState.setStockList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.stockListState.setStockListLoading(false);
      }
    },
  }),
});

export const stockState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IStockState,
  reducers: {
    setStock: (state, payload: IStock) => ({ ...state, data: payload }),
    setStockLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async createStock(payload: IStockCreatePayload) {
      dispatch.stockState.setStockLoading(true);

      try {
        const response = await stockTransport.createStock(payload);
        dispatch.stockListState.addStock(response);
        dispatch.stockState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.stockState.setStockLoading(false);
      }
    },
    async updateStock(payload: IStockUpdatePayload) {
      dispatch.stockState.setStockLoading(true);

      try {
        const response = await stockTransport.updateStock(payload);
        dispatch.stockListState.updateStock(response);
        dispatch.stockState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.stockState.setStockLoading(false);
      }
    },
    async deleteStock(payload: IStockDeletePayload) {
      dispatch.stockState.setStockLoading(true);

      try {
        const response = await stockTransport.deleteStock(payload);
        dispatch.stockListState.deleteStock(response.id);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.stockState.setStockLoading(false);
      }
    },
  }),
});
