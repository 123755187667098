import React, { FC } from 'react';
import { AutoComplete, Button, Form, FormInstance, Input, Modal, Spin, Tabs } from 'antd';
import { EAddressModalTabKey } from 'common/const/enum';
import { IFormValues } from 'common/models';
import { stockFormTabs } from 'entities/Stock/Stock.const';
import { IAddressSuggestion } from 'entities/Addresses/Addresses.models';
import { stockFormSubmitDisabled } from 'entities/Stock/Stock.helper';

interface IComponentProps {
  open: boolean;
  form: FormInstance;
  tab: string;
  loading: boolean;
  submitBtnTitle: string;
  searchOptions?: IAddressSuggestion[];
  searchLoading: boolean;
  onTabChange: (value: string) => void;
  onSearchChange: (value: string) => void;
  onCancelClick: () => void;
  onSubmit: (values: IFormValues) => void;
}

export const StockFormModal: FC<IComponentProps> = (props) => {
  const {
    open,
    form,
    tab,
    loading,
    submitBtnTitle,
    searchOptions,
    searchLoading,
    onTabChange,
    onSearchChange,
    onCancelClick,
    onSubmit,
  } = props;

  return (
    <Modal className="modal-gray stock-form-modal" open={open} footer={false} closeIcon={false} width={360}>
      <Spin spinning={loading}>
        <Form className="stock-form-modal__form" form={form} onFinish={onSubmit} layout="vertical">
          <div className="modal-gray__body stock-form-modal__body">
            <Form.Item name="name" label="Название">
              <Input placeholder="Введите название" />
            </Form.Item>

            <div>
              <div className="modal-gray__title stock-form-modal__title">Адрес</div>

              <Tabs items={stockFormTabs} activeKey={tab} onChange={onTabChange} />
            </div>

            {tab === EAddressModalTabKey.AutomaticSearch ? (
              <Form.Item name="address">
                <AutoComplete
                  placeholder="Введите адрес"
                  onChange={onSearchChange}
                  suffixIcon={searchLoading ? <Spin size="small" /> : null}
                >
                  {searchOptions?.map((option, index) => {
                    return (
                      <AutoComplete.Option key={index} value={option.name} disabled={!option.city}>
                        {option.name}
                      </AutoComplete.Option>
                    );
                  })}
                </AutoComplete>
              </Form.Item>
            ) : (
              <>
                <Form.Item name="manualAddress">
                  <Input placeholder="Введите адрес" />
                </Form.Item>

                <Form.Item name="manualCity">
                  <Input placeholder="Введите город" />
                </Form.Item>
              </>
            )}
          </div>

          <div className="modal-gray__footer">
            <Button className="btn btn-default" onClick={onCancelClick}>
              Отмена
            </Button>

            <Form.Item shouldUpdate>
              {({ getFieldValue }) => {
                const disabled = stockFormSubmitDisabled(getFieldValue, tab, searchOptions);

                return (
                  <Button className="btn btn-primary" htmlType="submit" disabled={disabled}>
                    {submitBtnTitle}
                  </Button>
                );
              }}
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};
