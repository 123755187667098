import React, { FC } from 'react';
import { Progress } from 'antd';

interface IComponentProps {
  label: string;
  count: number;
  limit: number;
}

export const NeedListItemProgress: FC<IComponentProps> = ({ label, count, limit }) => {
  return (
    <div className="need-list-item-progress">
      <div className="need-list-item-progress__container">
        <span className="text-lists">{label}</span>

        <span className="text-tag-accent">{`${count} из ${limit}`}</span>
      </div>

      <Progress percent={(count * 100) / limit} showInfo={false} strokeColor="#69A443" />
    </div>
  );
};
