import React, { FC } from 'react';
import { ESupplyStatus, ESupplyStatusLabel } from 'common/const/enum';
import { ReactComponent as DeliveryIcon } from 'app/assets/images/redesign/delivery.svg';

interface IComponentProps {
  status: string;
  isCancelledBySeller?: boolean;
}

export const SupplyStatus: FC<IComponentProps> = ({ status, isCancelledBySeller }) => (
  <div className={`supply-status ${status}`}>
    <DeliveryIcon />

    <span className="text-tag-accent">
      {isCancelledBySeller ? 'Отказ поставщика' : ESupplyStatusLabel[status as ESupplyStatus]}
    </span>
  </div>
);
