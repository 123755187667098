import React, { FC, ReactNode, useState } from 'react';
import { Button, Calendar, Popover } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { EDateFormat } from 'common/const/enum';
import { disabledDate } from 'common/helpers/date.helper';
import { ReactComponent as ArrowLeftShortIcon } from 'app/assets/images/redesign/arrow-left-short.svg';
import { ReactComponent as ArrowRightShortIcon } from 'app/assets/images/redesign/arrow-right-short.svg';

interface IComponentProps {
  children: ReactNode;
  onApply: (value: string) => void;
}

export const ChangeSupplyDeliveryDatePopover: FC<IComponentProps> = ({ children, onApply }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<Dayjs>(dayjs());

  const onCancelClick = () => {
    setOpen(false);
    setValue(dayjs());
  };

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      overlayClassName="redesign"
      overlayStyle={{ width: 420 }}
      placement="bottom"
      content={
        <div className="custom-popover__content">
          <span className="text-h4" style={{ marginBottom: 6 }}>
            Выберите новую дату
          </span>

          <span className="text-tag color-dark-grey">
            Новая дата дотавки будет применена к выбранным партиям поставок и всем поставкам внутри.
          </span>

          <Calendar
            value={value}
            onChange={setValue}
            fullscreen={false}
            locale={locale}
            disabledDate={disabledDate}
            headerRender={() => {
              return (
                <div className="calendar-custom-header">
                  <Button
                    className="button-circle transparent"
                    icon={<ArrowLeftShortIcon />}
                    onClick={() => setValue(value.add(-1, 'months'))}
                  />

                  <span className="text-h4-item-name calendar-custom-header__label">
                    {dayjs(value).locale('ru').format('MMMM YYYY')}
                  </span>

                  <Button
                    className="button-circle transparent"
                    icon={<ArrowRightShortIcon />}
                    onClick={() => setValue(value.add(1, 'months'))}
                  />
                </div>
              );
            }}
          />

          <div className="divider custom-popover__divider" />

          <div className=" custom-popover__footer">
            <Button className="button-s secondary" onClick={onCancelClick}>
              Отмена
            </Button>

            <Button
              className="button-s primary"
              onClick={() => {
                onApply(value.format(EDateFormat.FullDateReverse));
                onCancelClick();
              }}
              disabled={value.isBefore(dayjs(), 'day')}
            >
              Применить
            </Button>
          </div>
        </div>
      }
      trigger="click"
    >
      {children}
    </Popover>
  );
};
