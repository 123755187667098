import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Input } from 'antd';
import { EModalCollectionKey } from 'common/const/enum';
import { dataToTree } from 'common/helpers/tree.helper';
import { RootDispatch, RootState } from 'app/store';
import { SelectSubdivisionModal } from 'entities/Modal/components/SelectSubdivisionModal';
import { ISubdivision } from 'entities/Subdivisions/Subdivisions.models';
import { IUser } from 'entities/Users/Users.models';

interface IComponentProps {
  subdivisions?: ISubdivision[];
  user: IUser;
  loading: boolean;
  disabled?: boolean;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const UserSubdivisionComponent: React.FC<AllType> = (props) => {
  const { subdivisions, user, loading, disabled, changeUserSubdivisionModalParams, updateUserByAdmin, showModal, hideModal } =
    props;
  const { open } = changeUserSubdivisionModalParams;

  const [subdivisionId, setSubdivisionId] = useState<number>();

  const treeData = dataToTree<ISubdivision>(subdivisions);

  const onChangeUserSubdivisionClick = () => {
    showModal(EModalCollectionKey.SelectSubdivision);
  };

  const onSelectSubdivisionModalCancelClick = () => {
    setSubdivisionId(user.subdivisionId);
    hideModal(EModalCollectionKey.SelectSubdivision);
  };

  const onSelectSubdivisionModalConfirmClick = () => {
    updateUserByAdmin({
      id: user.id,
      subdivisionId,
      onSuccess: () => {
        hideModal(EModalCollectionKey.SelectSubdivision);
      },
    });
  };

  useEffect(() => {
    setSubdivisionId(user.subdivisionId);
  }, [user]);

  return (
    <>
      <div>Подразделение</div>

      <Input
        value={user.subdivisionName}
        placeholder="Не выбрано"
        readOnly
        onClick={onChangeUserSubdivisionClick}
        disabled={disabled}
      />

      <SelectSubdivisionModal
        open={open}
        treeData={treeData}
        subdivisionId={subdivisionId}
        loading={loading}
        onSelect={(subdivisionId) => setSubdivisionId(subdivisionId)}
        onConfirmClick={onSelectSubdivisionModalConfirmClick}
        onCancelClick={onSelectSubdivisionModalCancelClick}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  changeUserSubdivisionModalParams: state.modalCollection.selectSubdivisionModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  updateUserByAdmin: dispatch.userState.updateUserByAdmin,
  showModal: dispatch.modalCollection.showModal,
  hideModal: dispatch.modalCollection.hideModal,
});

export const UserSubdivision = connect(mapState, mapDispatch)(UserSubdivisionComponent);
