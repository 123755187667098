import { createModel } from '@rematch/core';
import { IRequestSuccess } from 'common/models';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store';
import { invitationTransport } from 'entities/Invitation/Invitation.transport';
import {
  IInvitationActivatePayload,
  IInvitationCreatePayload,
  IInvitation,
  IInvitationState,
} from 'entities/Invitation/Invitation.models';

export const invitationState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
    error: null,
  } as IInvitationState,
  reducers: {
    setInvitation: (state, payload: IInvitation) => ({ ...state, data: payload }),
    setInvitationLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    setInvitationError: (state, payload: string | null) => ({ ...state, error: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getInvitation(hash: string) {
      dispatch.invitationState.setInvitationLoading(true);

      try {
        const response = await invitationTransport.getInvitation(hash);
        dispatch.invitationState.setInvitation(response);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.invitationState.setInvitationLoading(false);
      }
    },
    async activateInvitation(payload: IInvitationActivatePayload) {
      const { email, password, ...rest } = payload;

      dispatch.invitationState.setInvitationLoading(true);

      try {
        await invitationTransport.activateInvitation({ ...rest, password });
        dispatch.authState.addAuth({ email: email as string, password });
        dispatch.invitationState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.invitationState.setInvitationLoading(false);
      }
    },
    async inviteUser(payload: IInvitationCreatePayload) {
      dispatch.invitationState.setInvitationLoading(true);

      try {
        await invitationTransport.inviteUser(payload);
        dispatch.invitationState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error, dispatch.invitationState.setInvitationError);
      } finally {
        dispatch.invitationState.setInvitationLoading(false);
      }
    },
  }),
});
