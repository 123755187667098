import { createModel } from '@rematch/core';
import { IRequestSuccess } from 'common/models';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store';
import { qrfpTransport } from 'entities/Qrfp/Qrfp.transport';
import {
  IQrfpListState,
  IQrfpListParams,
  IQrfp,
  IQrfpGoodsCreatePayload,
  IQrfpState,
  IQrfpCompletePayload,
  IQrfpCreatePayload,
} from 'entities/Qrfp/Qrfp.models';

export const qrfpListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IQrfpListState,
  reducers: {
    setQrfpList: (state, payload: IQrfp[]) => ({ ...state, data: payload }),
    setQrfpListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getQrfpList(params: IQrfpListParams) {
      dispatch.qrfpListState.setQrfpListLoading(true);

      try {
        const response = await qrfpTransport.getQrfpList(params);
        dispatch.qrfpListState.setQrfpList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.qrfpListState.setQrfpListLoading(false);
      }
    },
  }),
});

export const qrfpState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IQrfpState,
  reducers: {
    setQrfp: (state, payload: IQrfp) => ({ ...state, data: payload }),
    setQrfpLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getQrfp(id: number) {
      dispatch.qrfpState.setQrfpLoading(true);

      try {
        const response = await qrfpTransport.getQrfp(id);
        dispatch.qrfpState.setQrfp(response);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.qrfpState.setQrfpLoading(false);
      }
    },
    async createQrfp(payload: IQrfpCreatePayload) {
      dispatch.qrfpState.setQrfpLoading(true);

      try {
        const response = await qrfpTransport.createQrfp(payload);
        dispatch.qrfpState.setQrfp(response);
        dispatch.qrfpState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.qrfpState.setQrfpLoading(false);
      }
    },
    async completeQrfp(payload: IQrfpCompletePayload) {
      dispatch.qrfpState.setQrfpLoading(true);

      try {
        await qrfpTransport.completeQrfp(payload);
        dispatch.qrfpState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.qrfpState.setQrfpLoading(false);
      }
    },
    async createQrfpGoods(payload: IQrfpGoodsCreatePayload) {
      dispatch.qrfpState.setQrfpLoading(true);

      try {
        await qrfpTransport.createQrfpGoods(payload);
        dispatch.qrfpState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.qrfpState.setQrfpLoading(false);
      }
    },
  }),
});
