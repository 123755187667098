import axios from 'axios';
import {
  ICatalog,
  ICatalogCollectionDto,
  ICatalogCreatePayload,
  ICatalogDeletePayload,
  ICatalogListParams,
  ICatalogUpdatePayload,
} from 'entities/Catalog/Catalog.models';

const basePath = 'catalog';

export const catalogTransport = {
  getCatalogList: (params: ICatalogListParams): Promise<ICatalogCollectionDto> => axios.get(`${basePath}`, { params }),
  getCatalogById: (id: number): Promise<ICatalog> => axios.get(`${basePath}/${id}`),
  createCatalog: (payload: ICatalogCreatePayload): Promise<ICatalog> => axios.post(`${basePath}`, payload),
  updateCatalog: (payload: ICatalogUpdatePayload): Promise<ICatalog> => axios.patch(`${basePath}`, payload),
  deleteCatalog: (payload: ICatalogDeletePayload): Promise<ICatalog> => axios.delete(`${basePath}`, { data: payload }),
};
