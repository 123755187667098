import { DEFAULT_EMPTY_VALUE } from 'common/config';
import { ENeedStatus, ENeedStatusLabel } from 'common/const/enum';

export const needListFilterStatusOptionsForUser = [
  {
    label: 'Все статусы',
    value: DEFAULT_EMPTY_VALUE,
  },
  {
    label: ENeedStatusLabel.created,
    value: ENeedStatus.Created,
  },
  {
    label: ENeedStatusLabel.pending,
    value: ENeedStatus.Pending,
  },
  {
    label: ENeedStatusLabel.approved,
    value: ENeedStatus.Approved,
  },
];
