import React, { FC, useState } from 'react';
import { AutoComplete, Button, Spin } from 'antd';
import { connect } from 'react-redux';
import { useDebounce } from 'common/hooks/useDebounce';
import { RootDispatch, RootState } from 'app/store';

interface IComponentProps {
  catalogId?: number;
  onGoodsClick: (id: number) => void;
  onCategoryClick: (id: number) => void;
  onFindClick: (value: string) => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const ShopSearchComponent: FC<AllType> = (props) => {
  const {
    catalogId,
    onGoodsClick,
    onCategoryClick,
    onFindClick,
    searchSuggestionsCategories,
    searchSuggestionsGoods,
    searchSuggestionsLoading,
    resetSearchSuggestions,
    getSearchSuggestions,
  } = props;

  const [search, setSearch] = useState<string>('');

  const onSearchValueChange = (value: string) => {
    setSearch(value);
  };

  const handleGoodsClick = (id: number) => {
    onGoodsClick(id);
    setSearch('');
  };

  const handleCategoryClick = (id: number) => {
    onCategoryClick(id);
    setSearch('');
  };

  const handleSearchClick = () => {
    onFindClick(search);
    setSearch('');
  };

  useDebounce(async () => {
    if (search.trim().length >= 3 && catalogId) {
      getSearchSuggestions({ search, catalogId });
    } else {
      resetSearchSuggestions();
    }
  }, [search, catalogId]);

  return (
    <div className="shop-search">
      <AutoComplete
        placeholder="Поиск"
        value={search}
        onChange={onSearchValueChange}
        suffixIcon={searchSuggestionsLoading && <Spin size="small" />}
        popupMatchSelectWidth={false}
        dropdownRender={() => {
          return (
            <div className="shop-search__dropdown">
              {!!searchSuggestionsGoods.length && (
                <div className="shop-search__dropdown-block">
                  <div className="shop-search__dropdown-title">Товары</div>

                  <div>
                    {searchSuggestionsGoods.map(({ id, name }) => {
                      return (
                        <Button className="shop-search__dropdown-item" title={name} key={id} onClick={() => handleGoodsClick(id)}>
                          {name}
                        </Button>
                      );
                    })}
                  </div>
                </div>
              )}

              {!!searchSuggestionsCategories.length && (
                <div className="shop-search__dropdown-block">
                  <div className="shop-search__dropdown-title">Категории</div>

                  <div className={`${searchSuggestionsCategories.length > 8 ? 'shop-search__dropdown-table' : ''}`}>
                    {searchSuggestionsCategories.map(({ id, displayName }) => {
                      return (
                        <Button
                          className="shop-search__dropdown-item"
                          title={displayName}
                          key={id}
                          onClick={() => handleCategoryClick(id)}
                        >
                          {displayName}
                        </Button>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          );
        }}
      />

      <Button style={{ display: 'none' }} className="btn btn-primary" onClick={handleSearchClick} disabled={!search.length}>
        Найти
      </Button>
    </div>
  );
};

const mapState = (state: RootState) => ({
  searchSuggestionsCategories: state.searchSuggestionsState.data.categories,
  searchSuggestionsGoods: state.searchSuggestionsState.data.goods,
  searchSuggestionsLoading: state.searchSuggestionsState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  resetSearchSuggestions: dispatch.searchSuggestionsState.resetSearchSuggestions,
  getSearchSuggestions: dispatch.searchSuggestionsState.getSearchSuggestions,
});

export const ShopSearch = connect(mapState, mapDispatch)(ShopSearchComponent);
