import { createModel } from '@rematch/core';
import { IRequestSuccess } from 'common/models';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { destroyMessage, showLoadingMessage, showSuccessMessage } from 'common/helpers/message.helper';
import { IRootModel } from 'app/store';
import { subdivisionsTransport } from 'entities/Subdivisions/Subdivisions.transport';
import {
  ISubdivisionListState,
  ISubdivisionListParams,
  ISubdivision,
  ISubdivisionState,
  ISubdivisionCreatePayload,
  ISubdivisionDeletePayload,
  ISubdivisionUpdatePayload,
} from 'entities/Subdivisions/Subdivisions.models';
import { updateSubdivisionListState } from 'entities/Subdivisions/Subdivisions.helper';

export const subdivisionListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as ISubdivisionListState,
  reducers: {
    setSubdivisionList: (state, payload: ISubdivision[]) => ({ ...state, data: payload }),
    setSubdivisionListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    addSubdivision: updateSubdivisionListState.addSubdivision,
    updateSubdivision: updateSubdivisionListState.updateSubdivision,
    deleteSubdivision: updateSubdivisionListState.deleteSubdivision,
  },
  effects: (dispatch) => ({
    async getSubdivisionList(params: ISubdivisionListParams) {
      dispatch.subdivisionListState.setSubdivisionListLoading(true);

      try {
        const response = await subdivisionsTransport.getSubdivisionList(params);
        dispatch.subdivisionListState.setSubdivisionList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.subdivisionListState.setSubdivisionListLoading(false);
      }
    },
  }),
});

export const subdivisionState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as ISubdivisionState,
  reducers: {
    setSubdivision: (state, payload: ISubdivision) => ({ ...state, data: payload }),
    setSubdivisionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async createSubdivision(payload: ISubdivisionCreatePayload) {
      dispatch.subdivisionState.setSubdivisionLoading(true);

      try {
        const response = await subdivisionsTransport.createSubdivision(payload);
        dispatch.subdivisionListState.addSubdivision(response);
        dispatch.subdivisionState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.subdivisionState.setSubdivisionLoading(false);
      }
    },
    async deleteSubdivision(payload: ISubdivisionDeletePayload) {
      dispatch.subdivisionState.setSubdivisionLoading(true);

      try {
        const response = await subdivisionsTransport.deleteSubdivision(payload);
        dispatch.subdivisionListState.deleteSubdivision(response.id);
        dispatch.subdivisionState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.subdivisionState.setSubdivisionLoading(false);
      }
    },
    async updateSubdivision(payload: ISubdivisionUpdatePayload) {
      dispatch.subdivisionState.setSubdivisionLoading(true);

      showLoadingMessage(0);

      try {
        const response = await subdivisionsTransport.updateSubdivision(payload);
        dispatch.subdivisionListState.updateSubdivision(response);
        dispatch.subdivisionState.onSuccess(payload);
        showSuccessMessage();
      } catch (error) {
        destroyMessage();
        axiosErrorHandler(error);
      } finally {
        dispatch.subdivisionState.setSubdivisionLoading(false);
      }
    },
  }),
});
