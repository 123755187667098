import dayjs from 'dayjs';
import { DEFAULT_DEBOUNCE_DELAY, INTEGER_PATTERN } from 'common/config';
import { EColor } from 'common/const/enum';
import { IGroupedByDeliveryDateListItem, IGroupedByUpdatedAtListItem, IGroupedByUserIdListItem, IOption } from 'common/models';
import { ISupplyAccountShort, ISupplyAddressShort, ISupplyUserShort } from 'entities/Supply/Supply.models';
import { getUserName } from 'entities/Users/Users.helper';

export const toDataSourceMapper = <T>(data?: T[] | null): T[] | undefined =>
  data?.map((item, index) => ({ key: index, ...item }));

export const getId = () => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
};

export const debounce = <A = unknown, R = void>(func: (args: A) => R) => {
  let timeoutId: NodeJS.Timeout;

  return (args: A) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func(args);
    }, DEFAULT_DEBOUNCE_DELAY);
  };
};

export const getUniqOptions = (options: IOption[]) => {
  return options.reduce((acc: IOption[], currentItem) => {
    if (!acc.some((item) => item.value === currentItem.value)) {
      acc.push(currentItem);
    }

    return acc;
  }, []);
};

export const getGoodsItemStatusColor = (status?: string) => {
  if (status === 'pending') {
    return EColor.Orange;
  }

  return EColor.Gray;
};

export const stringToNumber = (string: string) => Number(string);

export const numberToString = (number: number) => number.toString();

export const isInteger = (number: number) => INTEGER_PATTERN.test(number.toString());

export const mapToMenuWithDivider = <T>(list: T[]): T[] => {
  return list.reduce<T[]>((acc, curr, index) => {
    if (index > 0) {
      acc.push({ type: 'divider' } as T);
    }

    acc.push(curr);

    return acc;
  }, []);
};

export const getCounterWidth = (count: number, responsive?: boolean) => {
  // 11 - estimated letter width in pixels, 80 - sum of button widths and horizontal padding
  return responsive ? `${Math.min(count.toString().length * 11 + 80, 200)}px` : '100%';
};

export const generateUid = () => Math.random().toString(16).slice(2);

export const groupList = {
  byCreatedAt: <T extends { createdAt: string }>(list: T[]) => {
    return list.reduce((acc: { createdAt: string; dataSource: T[] }[], item) => {
      const { createdAt } = item;
      const existingGroup = acc.find((group) => dayjs(createdAt).isSame(group.createdAt, 'day'));

      if (existingGroup) {
        existingGroup.dataSource.push(item);
      } else {
        acc.push({
          createdAt,
          dataSource: [item],
        });
      }

      return acc;
    }, []);
  },
  byUpdatedAt: <T extends { updatedAt: string }>(list: T[]) => {
    return list.reduce((acc: { updatedAt: string; dataSource: T[] }[], item) => {
      const { updatedAt } = item;
      const existingGroup = acc.find((group) => dayjs(updatedAt).isSame(group.updatedAt, 'day'));

      if (existingGroup) {
        existingGroup.dataSource.push(item);
      } else {
        acc.push({
          updatedAt,
          dataSource: [item],
        });
      }

      return acc;
    }, []);
  },
  byUserId: <
    T extends {
      address: ISupplyAddressShort;
      legalName: string;
      status: string;
      account: ISupplyAccountShort;
      user: ISupplyUserShort;
    },
  >(
    list: T[],
  ) => {
    return list.reduce((acc: IGroupedByUserIdListItem<T>[], item) => {
      const { address, legalName, status, account, user } = item;
      let dateGroup = acc.find((group) => group.user === getUserName(user.firstName, user.lastName));

      if (!dateGroup) {
        dateGroup = {
          user: getUserName(user.firstName, user.lastName),
          dataSource: [],
        };
        acc.push(dateGroup);
      }

      let subgroup = dateGroup.dataSource.find((group) => {
        return group.address === address?.name && group.legalName === legalName;
      });

      if (!subgroup) {
        subgroup = {
          address: address?.name,
          legalName,
          status,
          account: account.name,
          user: getUserName(user.firstName, user.lastName),
          dataSource: [],
        };
        dateGroup.dataSource.push(subgroup);
      }

      subgroup.dataSource.push(item);

      return acc;
    }, []);
  },
  byDeliveryDate: <
    T extends {
      deliveryDate: string;
      address: ISupplyAddressShort;
      legalName: string;
      status: string;
      account: ISupplyAccountShort;
      user: ISupplyUserShort;
    },
  >(
    list: T[],
  ) => {
    return list.reduce((acc: IGroupedByDeliveryDateListItem<T>[], item) => {
      const { deliveryDate, address, legalName, status, account, user } = item;
      let dateGroup = acc.find((group) => deliveryDate === null || dayjs(deliveryDate).isSame(group.deliveryDate, 'day'));

      if (!dateGroup) {
        dateGroup = {
          deliveryDate,
          dataSource: [],
        };
        acc.push(dateGroup);
      }

      let subgroup = dateGroup.dataSource.find((group) => {
        return (
          group.address === address?.name &&
          group.legalName === legalName &&
          group.user === getUserName(user.firstName, user.lastName)
        );
      });

      if (!subgroup) {
        subgroup = {
          address: address?.name,
          legalName,
          status,
          account: account.name,
          user: getUserName(user.firstName, user.lastName),
          dataSource: [],
        };
        dateGroup.dataSource.push(subgroup);
      }

      subgroup.dataSource.push(item);

      return acc;
    }, []);
  },
  supplyListByUpdatedAt: <
    T extends {
      updatedAt: string;
      address: ISupplyAddressShort;
      legalName: string;
      status: string;
      account: ISupplyAccountShort;
      user: ISupplyUserShort;
    },
  >(
    list: T[],
  ) => {
    return list.reduce((acc: IGroupedByUpdatedAtListItem<T>[], item) => {
      const { updatedAt, address, legalName, status, account, user } = item;
      let dateGroup = acc.find((group) => dayjs(updatedAt).isSame(group.updatedAt, 'day'));

      if (!dateGroup) {
        dateGroup = {
          updatedAt,
          dataSource: [],
        };
        acc.push(dateGroup);
      }

      let subgroup = dateGroup.dataSource.find((group) => {
        return (
          group.address === address?.name &&
          group.legalName === legalName &&
          group.user === getUserName(user.firstName, user.lastName)
        );
      });

      if (!subgroup) {
        subgroup = {
          address: address?.name,
          legalName,
          status,
          account: account.name,
          user: getUserName(user.firstName, user.lastName),
          dataSource: [],
        };
        dateGroup.dataSource.push(subgroup);
      }

      subgroup.dataSource.push(item);

      return acc;
    }, []);
  },
};
