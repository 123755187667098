import { RuleType, FieldData } from 'rc-field-form/lib/interface';
import { RuleObject } from 'antd/es/form';
import { EFormFieldMessage, ESearchParams } from 'common/const/enum';
import { IFormValues } from 'common/models';
import { IGoodsPosition } from 'entities/Goods/Goods.models';
import { ICategoryPosition } from 'entities/Categories/Categories.models';

export const getFormItemLabel = (name: string, unitOfMeasurement: string | null) => {
  return unitOfMeasurement ? `${name}, ${unitOfMeasurement}` : name;
};

export const rules = {
  required: (required = true) => ({ required, message: EFormFieldMessage.RequiredField }),
  type: (type: RuleType, message: string) => ({ type, message }),
  pattern: (pattern: RegExp, message: string) => ({ pattern, message }),
  min: (min: number, message: string) => ({ min, message }),
  max: (max: number, message: string) => ({ max, message }),
  rangeFrom: (
    name: string | number,
    getFieldValue: (field: (string | number)[]) => string,
    setFields: (fields: FieldData[]) => void,
  ) => ({
    validator(_: RuleObject, value: string) {
      const toFieldValue = getFieldValue([name, 0, 'to']);
      const setFieldErrors = (errors?: string[]) => setFields([{ name: [name, 0, 'to'], errors }]);

      if (!value || toFieldValue === undefined || toFieldValue === null) {
        setFieldErrors();
        return Promise.resolve();
      }

      if (value && toFieldValue && value <= toFieldValue) {
        setFieldErrors();
        return Promise.resolve();
      }

      return Promise.reject(EFormFieldMessage.InvalidRange);
    },
  }),
  rangeTo: (
    name: string | number,
    getFieldValue: (field: (string | number)[]) => string,
    setFields: (fields: FieldData[]) => void,
  ) => ({
    validator(_: RuleObject, value: string) {
      const fromFieldValue = getFieldValue([name, 0, 'from']);
      const setFieldErrors = (errors?: string[]) => setFields([{ name: [name, 0, 'from'], errors }]);

      if (value === undefined || value === null || !fromFieldValue) {
        setFieldErrors();
        return Promise.resolve();
      }

      if (value && fromFieldValue && value >= fromFieldValue) {
        setFieldErrors();
        return Promise.resolve();
      }

      setFieldErrors([EFormFieldMessage.InvalidRange]);
      return Promise.reject();
    },
  }),
};

export const formValuesToPositions = (formValues: IFormValues): (IGoodsPosition | ICategoryPosition)[] => {
  const positions: (IGoodsPosition | ICategoryPosition)[] = [];

  Object.entries(formValues).forEach(([key, value]) => {
    const propertyId = Number(key);

    switch (true) {
      case key === ESearchParams.CatalogId: {
        break;
      }
      case typeof value === 'string': {
        positions.push({ propertyId, values: [value] });
        break;
      }
      case Array.isArray(value): {
        if (typeof value[0] === 'object') {
          const from = value[0].from;
          const to = value[0].to;

          if (from && to) {
            positions.push({ propertyId, range: { from, to } });
            break;
          } else if (from) {
            positions.push({ propertyId, range: { from } });
            break;
          } else if (to) {
            positions.push({ propertyId, range: { to } });
            break;
          }

          break;
        } else {
          if (value.length) {
            positions.push({ propertyId, values: value });
          }
          break;
        }
      }
      default: {
        break;
      }
    }
  });

  return positions;
};
