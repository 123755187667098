import React from 'react';
import { Button, Image } from 'antd';
import Placeholder from 'app/assets/images/placeholder.png';
import { ICategory } from 'entities/Categories/Categories.models';

interface IComponentProps {
  category: ICategory;
  changeCategory: (id: number) => void;
}

export const ShopCategory: React.FC<IComponentProps> = ({ category, changeCategory }) => {
  return (
    <Button className="shop__categories_item" onClick={() => changeCategory(category.id)}>
      {category.image ? (
        <Image preview={false} width={73} height={73} src={category.image} />
      ) : (
        <Image preview={false} width={73} height={73} src={Placeholder} />
      )}

      {category.displayName}
    </Button>
  );
};
