import { IAddress, IAddressSuggestion } from 'entities/Addresses/Addresses.models';

export const addressDataToOptions = (data?: IAddressSuggestion[]) => {
  if (!data) {
    return [];
  }

  return data.map((item) => ({ key: item.id, label: item.name, value: item.name, postalCode: item.postalCode }));
};

export const addressListToOptionsWithId = (data: IAddress[]) => data.map((item) => ({ label: item.name, value: item.id }));

export const getAddressSuggestionCity = (address: string, addressSuggestions?: IAddressSuggestion[]) => {
  return addressSuggestions?.find((suggestionItem) => suggestionItem.name === address)?.city;
};
