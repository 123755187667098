import React from 'react';
import { Spin } from 'antd';
import { Navigate, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { ERoute, EUserRole } from 'common/const/enum';
import { Sidebar } from 'common/components/Sidebar-r';
import { useJivo } from 'common/hooks/useJivo';
import { CatalogProvider } from 'common/hooks/useCatalogContext';
import { RootState } from 'app/store';
import { Profile } from 'entities/Users/components/Profile';
import { Settings } from 'entities/Settings/components/Settings';
import { Workspace } from 'entities/Workspaces/components/Workspace';
import { WorkspacePosition } from 'entities/Workspaces/components/WorkspacePosition';
import { Shop } from 'entities/Shop/components/Shop';
import { Listing } from 'entities/Listing/components/Listing';
import { PreviousRequests } from 'entities/Requests/components/PreviousRequests';
import { Specification } from 'entities/Specifications/components/Specification';
import { PropertiesSettings } from 'entities/Property/components/PropertiesSettings';
import { CatalogListSettings } from 'entities/Settings/components/CatalogListSettings';
import { CatalogSettings } from 'entities/Settings/components/CatalogSettings';
import { QrfpPosition } from 'entities/Qrfp/components/QrfpPosition';
import { SpecificationList } from 'entities/Specifications/components/SpecificationList';
import { ActiveBasketList } from 'entities/Basket/components/ActiveBasketList';
import { ActiveBasket } from 'entities/Basket/components/ActiveBasket';
import { Catalog } from 'entities/Catalog/components/Catalog';
import { AccountList } from 'entities/Account/components/AccountList';
import { ContractListForSuperAdmin } from 'entities/Contract/components/ContractListForSuperAdmin';
import { ContractListForAccountAdmin } from 'entities/Contract/components/ContractListForAccountAdmin';
import { Contract } from 'entities/Contract/components/Contract';
import { DeliveryForContract } from 'entities/Delivery/components/DeliveryForContract';
import { Basket } from 'entities/Basket/components/Basket';
import { Requests } from 'entities/Requests/components/Requests';

type AllType = ReturnType<typeof mapState>;

const PrivateLayoutComponent: React.FC<AllType> = ({ auth, authLoading }) => {
  const isSuperAdmin = auth ? auth.access.roles.includes(EUserRole.SuperAdmin) : false;

  useJivo(auth);

  if (!auth && !authLoading) {
    return <Navigate to={ERoute.Login} />;
  }

  if (!auth && authLoading) {
    return <Spin spinning />;
  }

  return (
    <div className="private-layout">
      <Sidebar />

      <div className="private-layout__container">
        <Routes>
          {/* Admin */}
          <Route path={ERoute.Workspace} element={<Workspace />} />
          <Route path={ERoute.WorkspacePosition} element={<WorkspacePosition />} />

          {/* Manager */}
          <Route path={ERoute.SpecificationList} element={<SpecificationList />} />
          <Route path={ERoute.Specification} element={<Specification />} />

          {/* Common */}
          <Route path={ERoute.Shop} element={<Shop />} />
          <Route path={ERoute.Listing} element={<Listing />} />
          <Route path={ERoute.PreviousRequests} element={<PreviousRequests />} />
          <Route path={ERoute.QrfpPosition} element={<QrfpPosition />} />

          {/* Redesign */}
          {/* Super Admin */}
          <Route path={ERoute.CatalogList} element={<CatalogListSettings />} />
          <Route path={ERoute.CatalogListItem} element={<CatalogSettings />} />
          <Route path={ERoute.PropertiesSettings} element={<PropertiesSettings />} />
          <Route path={ERoute.AccountList} element={<AccountList />} />
          <Route path={ERoute.ContractList} element={<ContractListForSuperAdmin />} />

          {/* Admin */}
          <Route path={ERoute.Settings} element={<Settings />}>
            <Route path=":tab" element={<Settings />}>
              <Route path=":id" element={<Settings />} />
            </Route>
          </Route>
          <Route path={ERoute.AdminContractList} element={<ContractListForAccountAdmin />} />
          <Route path={ERoute.Contract} element={<Contract />} />
          <Route path={ERoute.DeliveryForContract} element={<DeliveryForContract />} />

          {/* Seller Manager */}
          <Route path={ERoute.ActiveBasketList} element={<ActiveBasketList />} />
          <Route path={ERoute.ActiveBasket} element={<ActiveBasket />} />

          {/* Common */}
          <Route path={ERoute.Catalog} element={<CatalogProvider children={<Catalog />} />}>
            <Route path=":catalogId" element={<Catalog />}>
              <Route path=":categoryId" element={<Catalog />} />
            </Route>
          </Route>
          <Route path={ERoute.Basket} element={<Basket />} />
          <Route path={ERoute.Requests} element={<Requests />}>
            <Route path=":role" element={<Requests />}>
              <Route path=":tab" element={<Requests />} />
            </Route>
            <Route path="*" element={<Requests />} />
          </Route>
          <Route path={ERoute.Profile} element={<Profile />} />

          {isSuperAdmin ? (
            <Route path="*" element={<Navigate to={ERoute.AccountList} />} />
          ) : (
            <Route path="*" element={<Navigate to={ERoute.Profile} />} />
          )}
        </Routes>
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  auth: state.authState.data,
  authLoading: state.authState.loading,
});

export const PrivateLayout = connect(mapState)(PrivateLayoutComponent);
