import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store';
import { IBuyer, IBuyerListState } from 'entities/Buyers/Buyers.models';
import { buyersTransport } from 'entities/Buyers/Buyers.transport';

export const buyerListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IBuyerListState,
  reducers: {
    setBuyerList: (state, payload: IBuyer[]) => ({ ...state, data: payload }),
    setBuyerListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getBuyerList() {
      dispatch.buyerListState.setBuyerListLoading(true);

      try {
        const response = await buyersTransport.getBuyerList();
        dispatch.buyerListState.setBuyerList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.buyerListState.setBuyerListLoading(false);
      }
    },
  }),
});
