import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { uploadExcelFile } from 'common/helpers/file';
import { IRootModel } from 'app/store';
import { basketTransport } from 'entities/Basket/Basket.transport';
import {
  IBasketListState,
  IBasketListParams,
  IBasket,
  IBasketState,
  IBasketBasedOnRequestCreatePayload,
  IBasketRequestCreatePayload,
  IBasketUpdatePayload,
  IBasketItem,
} from 'entities/Basket/Basket.models';

export const basketListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IBasketListState,
  reducers: {
    setBasketList: (state, payload: IBasketItem[]) => ({ ...state, data: payload }),
    setBasketListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getBasketList(params: IBasketListParams) {
      dispatch.basketListState.setBasketListLoading(true);

      try {
        const response = await basketTransport.getBasketList(params);
        dispatch.basketListState.setBasketList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.basketListState.setBasketListLoading(false);
      }
    },
  }),
});

export const basketState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
    goodsCount: 0,
  } as IBasketState,
  reducers: {
    setBasket: (state, payload: IBasket | null) => ({ ...state, data: payload }),
    setBasketLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    setBasketGoodsCount: (state, payload: number) => ({ ...state, goodsCount: payload }),
    increaseBasketGoodsCount: (state) => ({ ...state, goodsCount: state.goodsCount + 1 }),
    decreaseBasketGoodsCount: (state) => ({ ...state, goodsCount: state.goodsCount - 1 }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getBasketById(id: number) {
      dispatch.basketState.setBasketLoading(true);

      try {
        const response = await basketTransport.getBasketById(id);
        dispatch.basketState.setBasket(response);
        dispatch.basketState.setBasketGoodsCount(response.goods.length);
        return response;
      } catch (error) {
        axiosErrorHandler(error);
        return null;
      } finally {
        dispatch.basketState.setBasketLoading(false);
      }
    },
    async updateBasket(payload: IBasketUpdatePayload) {
      dispatch.basketState.setBasketLoading(true);

      try {
        const response = await basketTransport.updateBasket(payload);
        dispatch.basketState.setBasket(response);
        dispatch.basketState.setBasketGoodsCount(response.goods.length);
        dispatch.basketState.onSuccess(payload);
        return response;
      } catch (error) {
        axiosErrorHandler(error);
        return null;
      } finally {
        dispatch.basketState.setBasketLoading(false);
      }
    },
    async createBasketBasedOnRequest(payload: IBasketBasedOnRequestCreatePayload) {
      dispatch.basketState.setBasketLoading(true);

      try {
        const response = await basketTransport.createBasketBasedOnRequest(payload);
        dispatch.basketState.setBasket(response);
        dispatch.basketState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.basketState.setBasketLoading(false);
      }
    },
    async createBasketRequest(payload: IBasketRequestCreatePayload) {
      dispatch.basketState.setBasketLoading(true);

      try {
        const response = await basketTransport.createBasketRequest(payload);
        dispatch.basketState.setBasket(response);
        dispatch.basketState.setBasketGoodsCount(response.goods.length);
        dispatch.basketState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.basketState.setBasketLoading(false);
      }
    },
    async uploadBasketExcel(id: number) {
      dispatch.basketState.setBasketLoading(true);

      try {
        const response = await basketTransport.uploadBasketExcel(id);
        uploadExcelFile(response, `basket_${id}`);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.basketState.setBasketLoading(false);
      }
    },
  }),
});
