import React, { FC, useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { connect } from 'react-redux';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { EMessage } from 'common/const/enum';
import { toDataSourceMapper } from 'common/helpers/data.helper';
import { IFormValues } from 'common/models';
import { RootDispatch, RootState } from 'app/store';
import {
  getLegalCustomerList,
  getLegalPayload,
  getLegalSellerOptions,
  renderLegalEntityListRecords,
} from 'entities/LegalEntity/LegalEntity.helper';
import { LegalEntityModal } from 'entities/Modal/components/LegalEntityModal';
import { ILegalCustomer } from 'entities/LegalEntity/LegalEntity.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export const LegalEntityListComponent: FC<AllType> = (props) => {
  const {
    legalListState,
    sellerListState,
    legalState,
    getLegalList,
    getSellerList,
    getLegalById,
    createLegal,
    updateLegal,
    deleteLegal,
    setLegal,
    setLegalError,
  } = props;

  const { data: legalList } = legalListState;
  const { data: sellerList } = sellerListState;
  const { data: legal, loading: legalLoading, error: legalError } = legalState;

  const [customerList, setCustomerList] = useState<ILegalCustomer[]>([]);
  const [openLegalModal, setOpenLegalModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [form] = useForm();

  const dataSource = toDataSourceMapper(legalList);
  const columns = renderLegalEntityListRecords(onEditLegalEntityClick, onDeleteLegalEntityClick);
  const sellerOptions = getLegalSellerOptions(sellerList, customerList);

  async function onEditLegalEntityClick(id: number) {
    await getLegalById(id);
    setIsEdit(true);
    setOpenLegalModal(true);
  }

  function onDeleteLegalEntityClick(legalEntityId: number) {
    deleteLegal({ id: legalEntityId });
  }

  const onLegalEntityModalAddCustomerClick = (customerId: string, sellerId: number) => {
    const seller = sellerList.find((sellerItem) => sellerItem.id === sellerId);

    const newCustomer = {
      id: Date.now(),
      customerId,
      sellerId,
      sellerName: seller?.name,
    };

    setCustomerList((prev) => [...prev, newCustomer]);
  };

  const onLegalEntityModalDeleteCustomerClick = (id: number) => {
    setCustomerList((prev) => prev.filter((customer) => customer.id !== id));
  };

  const onLegalEntityModalCancelClick = () => {
    setOpenLegalModal(false);
    setLegal(null);
    setLegalError(null);
    setIsEdit(false);
    setCustomerList([]);
    form.resetFields();
  };

  const onLegalEntityModalAddSubmit = (values: IFormValues) => {
    const payload = getLegalPayload(values, customerList);

    setLegalError(null);
    createLegal({ ...payload, onSuccess: onLegalEntityModalCancelClick });
  };

  const onLegalEntityModalChangeSubmit = (values: IFormValues) => {
    if (legal) {
      const payload = getLegalPayload(values, customerList);

      setLegalError(null);
      updateLegal({ id: legal.id, ...payload, onSuccess: onLegalEntityModalCancelClick });
    }
  };

  useEffect(() => {
    if (legal) {
      const customerList = getLegalCustomerList(legal, sellerList);

      form.setFieldsValue({ name: legal.name, inn: legal.inn, kpp: legal.kpp });

      setCustomerList(customerList);
    }
  }, [legal, sellerList]);

  const { contentLoading } = useContentLoader(async () => {
    await getLegalList({ limit: 0 });
    await getSellerList({ limit: 0 });
  });

  if (contentLoading) {
    return <ContentLoader />;
  }

  return (
    <div className="legal-entity-list">
      <div className="legal-entity-list__title">Юридические лица</div>

      <Button className="btn btn-primary" onClick={() => setOpenLegalModal(true)}>
        <PlusOutlined />

        <span>Новое юр.лицо</span>
      </Button>

      <Table
        className="legal-entity-list__table"
        dataSource={dataSource}
        columns={columns}
        showHeader={false}
        pagination={false}
        loading={legalLoading}
        locale={{ emptyText: EMessage.LegalEntityListIsEmpty }}
      />

      <LegalEntityModal
        open={openLegalModal}
        form={form}
        loading={legalLoading}
        error={legalError}
        sellerOptions={sellerOptions}
        customerList={customerList}
        confirmBtnLabel={isEdit ? 'Изменить' : 'Добавить'}
        addCustomer={onLegalEntityModalAddCustomerClick}
        deleteCustomer={onLegalEntityModalDeleteCustomerClick}
        onCancelClick={onLegalEntityModalCancelClick}
        onSubmit={isEdit ? onLegalEntityModalChangeSubmit : onLegalEntityModalAddSubmit}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  legalListState: state.legalListState,
  sellerListState: state.sellerListState,
  legalState: state.legalState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getLegalList: dispatch.legalListState.getLegalList,
  getSellerList: dispatch.sellerListState.getSellerList,
  getLegalById: dispatch.legalState.getLegalById,
  createLegal: dispatch.legalState.createLegal,
  updateLegal: dispatch.legalState.updateLegal,
  deleteLegal: dispatch.legalState.deleteLegal,
  setLegal: dispatch.legalState.setLegal,
  setLegalError: dispatch.legalState.setLegalError,
});

export const LegalEntityList = connect(mapState, mapDispatch)(LegalEntityListComponent);
