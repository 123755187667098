import React, { useEffect, useState } from 'react';
import { Layout, Spin, message } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { ECommonErrorMessage, ENotificationType, ESearchParams, EPositionFormMode, EMessage } from 'common/const/enum';
import { IFormValues } from 'common/models';
import { SimilarPositions } from 'common/components/SimilarPositions';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { findDefaultPositionCascaderPath, getSimilarPositionList } from 'common/helpers/position.helper';
import { getWorkspacePositionPath } from 'common/helpers/router.helper';
import { PositionForm } from 'common/components/PositionForm';
import { PositionCascader } from 'common/components/PositionCascader';
import { RootDispatch, RootState } from 'app/store';
import { getPositionName, getWorkspacePayload } from 'entities/Workspaces/Workspace.helper';
import { Notification } from 'entities/Modal/components/Notification';
import { IWorkspacePosition } from 'entities/Workspaces/Workspaces.models';
import { ICategory } from 'entities/Categories/Categories.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const WorkspacePositionComponent: React.FC<AllType> = (props) => {
  const {
    categoryListState,
    propertyListState,
    workspaceState,
    getCategoryList,
    getPropertyListByCategoryId,
    getWorkspaceById,
    updateWorkspace,
    setWorkspaceError,
  } = props;

  const { data: categoryList } = categoryListState;
  const { data: propertyList, loading: propertyListLoading } = propertyListState;
  const { data: workspace, loading: workspaceLoading, error } = workspaceState;

  const [categoryCascaderPath, setCategoryCascaderPath] = useState<number[]>([]);
  const [category, setCategory] = useState<ICategory | null>(null);
  const [formValues, setFormValues] = useState<IFormValues | null>(null);
  const [formIsChanged, setFormIsChanged] = useState<boolean>(false);
  const [positionToDelete, setPositionToDelete] = useState<IWorkspacePosition | null>(null);
  const [openPositionUnsavedModal, setOpenPositionUnsavedModal] = useState<boolean>(false);
  const [openDeletePositionModal, setOpenDeletePositionModal] = useState<boolean>(false);
  const { searchParams, setSearchParam, removeSearchParam } = useSearchParamsHook();
  const { mode } = useParams();
  const navigate = useNavigate();

  const workspaceId = searchParams.get(ESearchParams.WorkspaceId);
  const categoryId = searchParams.get(ESearchParams.CategoryId);
  const positionId = searchParams.get(ESearchParams.PositionId);
  const position = workspace?.positions.find((positionItem) => positionItem.positionId === Number(positionId));
  const similarPositionList = getSimilarPositionList(Number(categoryId), Number(positionId), mode, workspace?.positions);

  const onCategoryChange = (value: (string | number)[] | undefined) => {
    if (value) {
      const selectedCategoryId = value[value.length - 1];

      if (selectedCategoryId !== Number(categoryId)) {
        setCategoryCascaderPath(value as number[]);
        setSearchParam(ESearchParams.CategoryId, selectedCategoryId);
        getPropertyListByCategoryId(Number(selectedCategoryId));
        const category = categoryList.find((category) => category.id === Number(selectedCategoryId));

        if (category) {
          setCategory(category);
        }
      }
    } else {
      removeSearchParam(ESearchParams.CategoryId);
      removeSearchParam(ESearchParams.PositionId);
      setCategory(null);
      setCategoryCascaderPath([]);
    }
  };

  const onPositionFormCancelClick = (values: IFormValues) => {
    if (mode === EPositionFormMode.Edit && formIsChanged) {
      setFormValues(values);
      setOpenPositionUnsavedModal(true);
    } else {
      setWorkspaceError(null);
      navigate(`/workspace/${workspaceId}`, { replace: true });
    }
  };

  const onSubmit = (values: IFormValues) => {
    if (workspace) {
      const payload = getWorkspacePayload.createPosition(workspace, Number(categoryId), values, propertyList);

      // @ts-ignore
      updateWorkspace({ ...payload, onSuccess: onPositionFormCancelClick });
    } else {
      message.error(ECommonErrorMessage.UnexpectedError);
      Sentry.captureMessage('workspace is undefined');
    }
  };

  const onUpdateSubmit = (values: IFormValues) => {
    if (workspace) {
      const payload = getWorkspacePayload.updatePosition(workspace, Number(positionId), values, propertyList);

      // @ts-ignore
      updateWorkspace({
        ...payload,
        onSuccess: () => {
          setWorkspaceError(null);
          navigate(`/workspace/${workspaceId}`, { replace: true });
        },
      });
    } else {
      message.error(ECommonErrorMessage.UnexpectedError);
      Sentry.captureMessage('workspace is undefined');
    }
  };

  const onPositionUnsavedModalCancelClick = () => {
    setWorkspaceError(null);
    navigate(`/workspace/${workspaceId}`, { replace: true });
  };

  const onPositionUnsavedModalConfirmClick = () => {
    if (formValues) {
      onUpdateSubmit(formValues);
    }
  };

  const editPosition = (categoryId: number, positionId: number) => {
    setWorkspaceError(null);
    navigate(getWorkspacePositionPath(EPositionFormMode.Edit, Number(workspaceId), categoryId, positionId));
  };

  const copyPosition = (categoryId: number, positionId: number) => {
    setWorkspaceError(null);
    navigate(getWorkspacePositionPath(EPositionFormMode.Copy, Number(workspaceId), categoryId, positionId));
  };

  const deletePosition = (positionId: number) => {
    const position = workspace?.positions.find((position) => position.positionId === positionId);

    if (position) {
      setPositionToDelete(position);
      setOpenDeletePositionModal(true);
    }
  };

  const onDeletePositionModalCancelClick = () => {
    setPositionToDelete(null);
    setOpenDeletePositionModal(false);
  };

  const onDeletePositionModalConfirmClick = () => {
    if (workspace && positionToDelete) {
      const payload = getWorkspacePayload.deletePosition(workspace, positionToDelete.positionId);

      updateWorkspace({
        ...payload,
        onSuccess: () => {
          setWorkspaceError(null);
          onDeletePositionModalCancelClick();
        },
      });
    }
  };

  useEffect(() => {
    return () => {
      setWorkspaceError(null);
    };
  }, []);

  useEffect(() => {
    if (categoryId && !categoryCascaderPath.length) {
      const defaultCategoryCascaderPath = findDefaultPositionCascaderPath(categoryList, Number(categoryId));

      if (defaultCategoryCascaderPath.length) {
        setCategoryCascaderPath(defaultCategoryCascaderPath);
      }
    }
  }, [categoryId, categoryList]);

  const { contentLoading } = useContentLoader(async () => {
    const categoryList = await getCategoryList({ limit: 0 });
    await getWorkspaceById(Number(workspaceId));
    await getPropertyListByCategoryId(Number(categoryId));
    const category = categoryList?.find((categoryItem) => categoryItem.id === Number(categoryId));

    if (category) {
      setCategory(category);
    }
  });

  return (
    <Layout className="position">
      {contentLoading ? (
        <ContentLoader />
      ) : (
        <Spin wrapperClassName="position__spin" spinning={workspaceLoading}>
          <div className="position__title">Добавить позицию в карту рабочего места</div>

          <PositionCascader
            categoryList={categoryList}
            value={categoryCascaderPath}
            disabled={mode === EPositionFormMode.Edit}
            onChange={onCategoryChange}
          />

          {!!error && <div className="position__error">{error}</div>}

          {category && (
            <>
              <PositionForm
                loading={propertyListLoading}
                propertyList={propertyList}
                category={category}
                position={position}
                cancelBtnTitle="Отменить"
                submitBtnTitle={mode === EPositionFormMode.Edit ? 'Сохранить' : 'Добавить позицию'}
                setFormIsChanged={setFormIsChanged}
                onCancel={onPositionFormCancelClick}
                onSubmit={mode === EPositionFormMode.Edit ? onUpdateSubmit : onSubmit}
              />

              <SimilarPositions
                loading={propertyListLoading}
                positionList={similarPositionList}
                editPosition={editPosition}
                copyPosition={copyPosition}
                deletePosition={deletePosition}
              />
            </>
          )}
        </Spin>
      )}

      <Notification
        open={openPositionUnsavedModal}
        type={ENotificationType.Notification}
        description={EMessage.ChangesWereNotSaved}
        loading={workspaceLoading}
        error={error}
        confirmTitle="Сохранить"
        cancelTitle="Отменить изменения"
        onConfirmClick={onPositionUnsavedModalConfirmClick}
        onCancelClick={onPositionUnsavedModalCancelClick}
      />

      <Notification
        open={openDeletePositionModal}
        type={ENotificationType.Warning}
        description={`Позиция “${getPositionName(positionToDelete)}” будет удалена`}
        loading={workspaceLoading}
        onConfirmClick={onDeletePositionModalConfirmClick}
        onCancelClick={onDeletePositionModalCancelClick}
      />
    </Layout>
  );
};

const mapState = (state: RootState) => ({
  categoryListState: state.categoryListState,
  propertyListState: state.propertyListState,
  workspaceState: state.workspaceState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getCategoryList: dispatch.categoryListState.getCategoryList,
  getPropertyListByCategoryId: dispatch.propertyListState.getPropertyListByCategoryId,
  getWorkspaceById: dispatch.workspaceState.getWorkspaceById,
  updateWorkspace: dispatch.workspaceState.updateWorkspace,
  setWorkspaceError: dispatch.workspaceState.setWorkspaceError,
});

export const WorkspacePosition = connect(mapState, mapDispatch)(WorkspacePositionComponent);
