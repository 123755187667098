import axios from 'axios';
import {
  IBrandDiscount,
  IBrandDiscountCreatePayload,
  IBrandDiscountDeletePayload,
  IBrandsDiscountCollectionDto,
  IBrandsDiscountListParams,
  IDiscount,
  IDiscountCollectionDto,
  IDiscountListParams,
  IDiscountCreatePayload,
  IDiscountUpdatePayload,
} from 'entities/Discount/Discount.models';

const basePath = 'discount';

export const discountTransport = {
  getDiscountList: (params: IDiscountListParams): Promise<IDiscountCollectionDto> => axios.get(`${basePath}`, { params }),
  getDiscountById: (id: number): Promise<IDiscount> => axios.get(`${basePath}/${id}`),
  createDiscount: (payload: IDiscountCreatePayload): Promise<IDiscount> => axios.post(`${basePath}`, payload),
  updateDiscount: (payload: IDiscountUpdatePayload): Promise<IDiscount> => axios.patch(`${basePath}`, payload),
  getBrandsDiscountList: (params: IBrandsDiscountListParams): Promise<IBrandsDiscountCollectionDto> =>
    axios.get(`${basePath}/brand`, { params }),
  createBrandDiscount: (payload: IBrandDiscountCreatePayload): Promise<IBrandDiscount> =>
    axios.post(`${basePath}/brand`, payload),
  deleteBrandDiscount: (payload: IBrandDiscountDeletePayload): Promise<IBrandDiscount> =>
    axios.delete(`${basePath}/brand`, { data: payload }),
};
