import { ICatalog } from 'entities/Catalog/Catalog.models';
import { ICategoryDetailed } from 'entities/Categories/Categories.models';

export const categoryToBreadcrumbItems = (category: ICategoryDetailed | null, onClick: (id: number) => void) => {
  if (!category) {
    return [];
  }

  const categoryParents = category.parents.map((parent) => ({
    title: parent.categoryName,
    onClick: () => onClick(parent.categoryId),
  }));

  return [
    { title: category.catalogName, onClick: () => onClick(category.catalogId) },
    ...categoryParents,
    { title: category.displayName },
  ];
};

export const getShopCategoryCollectionParams = (catalogId: string, categoryId: string | null) => {
  return {
    catalogId: Number(catalogId),
    withoutParents: !categoryId,
    parentId: categoryId ? Number(categoryId) : undefined,
  };
};

export const getShopCatalogOptions = (catalogList: ICatalog[]) => {
  return catalogList.map((item) => ({ label: item.name, value: item.id.toString() }));
};
