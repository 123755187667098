import React from 'react';
import { Button, Form, Skeleton, Tag } from 'antd';
import { IFormValues } from 'common/models';
import { usePropertyForm } from 'common/hooks/usePropertyForm';
import { EPropertyType } from 'common/const/enum';
import { renderPositionFormField } from 'common/helpers/position.helper';
import { IProperty } from 'entities/Property/Property.models';
import { ICategory } from 'entities/Categories/Categories.models';
import { IWorkspacePosition } from 'entities/Workspaces/Workspaces.models';

interface IComponentProps {
  loading: boolean;
  propertyList: IProperty[];
  category?: ICategory | null;
  position?: IWorkspacePosition;
  readOnly?: boolean;
  cancelBtnTitle: string;
  submitBtnTitle: string;
  setFormIsChanged: (formIsChanged: boolean) => void;
  onCancel?: (values: IFormValues) => void;
  onSubmit?: (values: IFormValues) => void;
  onComplete?: () => void;
  onAddGoods?: () => void;
}

export const PositionForm: React.FC<IComponentProps> = (props) => {
  const {
    loading,
    propertyList,
    category,
    position,
    readOnly,
    cancelBtnTitle,
    submitBtnTitle,
    setFormIsChanged,
    onCancel,
    onSubmit,
    onComplete,
    onAddGoods,
  } = props;

  const { propertyListForView, form, tagList, changeFormValues, closeTag, closeAllTags } = usePropertyForm(
    propertyList,
    category,
    position,
  );

  const nonCheckboxProperties = propertyListForView.filter((property) => property.type !== EPropertyType.Label);
  const checkboxProperties = propertyListForView.filter((property) => property.type === EPropertyType.Label);

  const handleCancelClick = () => {
    const formValues = form.getFieldsValue();
    onCancel?.(formValues);
  };

  const handleFormValuesChange = (value: IFormValues) => {
    setFormIsChanged(true);
    changeFormValues(value);
  };

  const handleCloseTagClick = (id: number) => {
    setFormIsChanged(true);
    closeTag(id);
  };

  const handleClearAllTagsClick = () => {
    setFormIsChanged(true);
    closeAllTags();
  };

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <Form className="position-form" form={form} layout="vertical" onValuesChange={handleFormValuesChange} onFinish={onSubmit}>
      {!!tagList.length && (
        <div className="position-form__presets">
          {tagList.map(({ id, title, unitOfMeasurement, disabled }) => {
            return (
              <Tag
                key={id}
                title={`${title} ${unitOfMeasurement ? unitOfMeasurement : ''}`}
                closable={!disabled && !readOnly}
                onClose={() => handleCloseTagClick(id)}
              >
                {`${title} ${unitOfMeasurement ? unitOfMeasurement : ''}`}
              </Tag>
            );
          })}

          <Button type="link" className="btn btn-tag" onClick={handleClearAllTagsClick} disabled={readOnly}>
            | Очистить все
          </Button>
        </div>
      )}

      <div className="position-form__actions">
        {onCancel && (
          <Button className="btn btn-default" onClick={handleCancelClick}>
            {cancelBtnTitle}
          </Button>
        )}

        {onComplete && (
          <Button className="btn btn-default" onClick={onComplete}>
            Завершить
          </Button>
        )}

        {onAddGoods && (
          <Button className="btn btn-primary" onClick={onAddGoods}>
            Добавить товар
          </Button>
        )}

        {onSubmit && (
          <Button className="btn btn-primary" htmlType="submit">
            {submitBtnTitle}
          </Button>
        )}
      </div>

      <div className="position-form__title">Параметры новой позиции:</div>

      <div className="position-form__wrapper">
        <div className="position-form__container">
          {nonCheckboxProperties.map((property) => renderPositionFormField(property, readOnly))}
        </div>

        <div>{checkboxProperties.map((property) => renderPositionFormField(property, readOnly))}</div>
      </div>
    </Form>
  );
};
