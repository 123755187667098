import axios from 'axios';
import {
  INeed,
  INeedChangeStatusPayload,
  INeedCollectionDto,
  INeedHistoryCollectionDto,
  INeedHistoryListPayload,
  INeedListPayload,
  INeedSubdivisionListParams,
  INeedSubdivisionsCollectionDto,
  INeedUpdatePayload,
  INeedUserCollectionDto,
  INeedUserListParams,
} from 'entities/Need/Need.models';

const basePath = 'supply-need';

export const needTransport = {
  getNeedList: (payload: INeedListPayload): Promise<INeedCollectionDto> => axios.post(`${basePath}/list`, payload),
  getNeedUserList: (params: INeedUserListParams): Promise<INeedUserCollectionDto> => axios.get(`${basePath}/users`, { params }),
  getNeedSubdivisionListForManager: (params: INeedSubdivisionListParams): Promise<INeedSubdivisionsCollectionDto> =>
    axios.get(`${basePath}/subdivisions`, { params }),
  getNeedSubdivisionListForSeller: (buyerId?: number): Promise<INeedSubdivisionsCollectionDto> =>
    axios.get(`${basePath}/subdivisions/seller`, { params: { buyerId } }),
  changeNeedStatus: (payload: INeedChangeStatusPayload): Promise<INeedCollectionDto> =>
    axios.post(`${basePath}/change-status`, payload),
  getNeedById: (id: number): Promise<INeed> => axios.get(`${basePath}/${id}`),
  updateNeed: (payload: INeedUpdatePayload): Promise<INeedCollectionDto> => axios.patch(`${basePath}`, payload),
  uploadNeedExcel: (payload: INeedListPayload): Promise<ArrayBuffer> =>
    axios.post(`${basePath}/file`, payload, { responseType: 'arraybuffer' }),
  getNeedHistoryList: (payload: INeedHistoryListPayload): Promise<INeedHistoryCollectionDto> =>
    axios.post(`${basePath}/history`, payload),
};
