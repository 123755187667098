import React, { FC } from 'react';
import { AutoComplete, Button, Input } from 'antd';
import { IOption } from 'common/models';
import { filterAutocompleteWithCustomOptionsByLabel } from 'common/helpers/filterOption.helper';
import { EFormFieldMessage } from 'common/const/enum';

interface IComponentProps {
  open: boolean;
  options: IOption[];
  searchValue: string;
  disabled?: boolean;
  error?: string | null;
  popupClassName?: string;
  limit: number;
  onSearch: (value: string) => void;
  onSelect: (value: string) => void;
  onCreate: () => void;
  onBlur: () => void;
  onFocus: () => void;
}

export const PropertyCascaderItemSearch: FC<IComponentProps> = (props) => {
  const { open, options, searchValue, disabled, error, popupClassName, limit, onSearch, onSelect, onCreate, onBlur, onFocus } =
    props;

  const showCreateBtn = searchValue ? searchValue.length >= limit : false;
  const showMessage = searchValue ? searchValue.length < limit : false;

  return (
    <div>
      <AutoComplete
        open={open}
        className="autocomplete-with-action"
        popupClassName={popupClassName}
        options={options}
        value={searchValue}
        onSearch={onSearch}
        onSelect={onSelect}
        onBlur={onBlur}
        onFocus={onFocus}
        filterOption={filterAutocompleteWithCustomOptionsByLabel}
        disabled={disabled}
      >
        <Input
          placeholder="Введите текст"
          suffix={
            showCreateBtn ? (
              <Button className="autocomplete-with-action__btn" onClick={onCreate}>
                создать
              </Button>
            ) : (
              <span />
            )
          }
        />
      </AutoComplete>

      {showMessage && <div className="property-cascader-item__message">{EFormFieldMessage.CharacterLimitMin}</div>}

      {error && <div className="property-cascader-item__message">{error}</div>}
    </div>
  );
};
