import React, { FC } from 'react';
import { Checkbox, Form } from 'antd';
import { IOption } from 'common/models';

interface IComponentProps {
  formItemClassname?: string;
  className?: string;
  name?: string | number;
  options?: IOption[];
  disabled?: boolean;
}

export const FormCheckbox: FC<IComponentProps> = ({ formItemClassname, className, name, options, disabled }) => {
  return (
    <Form.Item className={formItemClassname} name={name}>
      <Checkbox.Group className={className} options={options} disabled={disabled} />
    </Form.Item>
  );
};
