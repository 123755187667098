import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { destroyMessage, showLoadingMessage, showSuccessMessage } from 'common/helpers/message.helper';
import { IRootModel } from 'app/store';
import { catalogTransport } from 'entities/Catalog/Catalog.transport';
import {
  ICatalog,
  ICatalogCreatePayload,
  ICatalogDeletePayload,
  ICatalogListParams,
  ICatalogListState,
  ICatalogState,
  ICatalogUpdatePayload,
} from 'entities/Catalog/Catalog.models';

export const catalogListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: 0,
    loading: false,
  } as ICatalogListState,
  reducers: {
    setCatalogList: (state, payload: ICatalog[]) => ({ ...state, data: payload }),
    setCatalogListCount: (state, payload: number) => ({ ...state, count: payload }),
    setCatalogListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    addCatalog: (state, payload: ICatalog) => ({
      ...state,
      data: [...state.data, payload],
    }),
    updateCatalog: (state, payload: ICatalog) => ({
      ...state,
      data: state.data.map((catalog) => (catalog.id === payload.id ? payload : catalog)),
    }),
    deleteCatalog: (state, payload: number) => ({
      ...state,
      data: state.data.filter((catalog) => catalog.id !== payload),
    }),
  },
  effects: (dispatch) => ({
    async getCatalogList(params: ICatalogListParams) {
      dispatch.catalogListState.setCatalogListLoading(true);

      try {
        const response = await catalogTransport.getCatalogList(params);
        dispatch.catalogListState.setCatalogList(response.data);
        dispatch.catalogListState.setCatalogListCount(response.count);
        return response.data;
      } catch (error) {
        axiosErrorHandler(error);
        return null;
      } finally {
        dispatch.catalogListState.setCatalogListLoading(false);
      }
    },
  }),
});

export const catalogState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as ICatalogState,
  reducers: {
    setCatalog: (state, payload: ICatalog | null) => ({ ...state, data: payload }),
    setCatalogLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getCatalogById(id: number) {
      dispatch.catalogState.setCatalogLoading(true);

      try {
        const response = await catalogTransport.getCatalogById(id);
        dispatch.catalogState.setCatalog(response);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.catalogState.setCatalogLoading(false);
      }
    },
    async createCatalog(payload: ICatalogCreatePayload) {
      dispatch.catalogState.setCatalogLoading(true);

      try {
        const response = await catalogTransport.createCatalog(payload);
        dispatch.catalogListState.addCatalog(response);
        dispatch.catalogState.setCatalog(response);
        dispatch.catalogState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.catalogState.setCatalogLoading(false);
      }
    },
    async updateCatalog(payload: ICatalogUpdatePayload) {
      dispatch.catalogState.setCatalogLoading(true);

      showLoadingMessage(0);

      try {
        const response = await catalogTransport.updateCatalog(payload);
        dispatch.catalogListState.updateCatalog(response);
        dispatch.catalogState.setCatalog(response);
        dispatch.catalogState.onSuccess(payload);
        showSuccessMessage();
      } catch (error) {
        destroyMessage();
        axiosErrorHandler(error);
      } finally {
        dispatch.catalogState.setCatalogLoading(false);
      }
    },
    async deleteCatalog(payload: ICatalogDeletePayload) {
      dispatch.catalogState.setCatalogLoading(true);

      try {
        const response = await catalogTransport.deleteCatalog(payload);
        dispatch.catalogListState.deleteCatalog(response.id);
        dispatch.catalogState.setCatalog(response);
        dispatch.catalogState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.catalogState.setCatalogLoading(false);
      }
    },
  }),
});
