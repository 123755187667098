import React from 'react';
import { Cascader, Modal } from 'antd';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { ICategory } from 'entities/Categories/Categories.models';
import { categoryListToCascaderOptions } from 'entities/Categories/Categories.helper';

interface IComponentProps {
  open: boolean;
  categoryList: ICategory[];
  onCancel: () => void;
  onSelect: (value: (string | number)[]) => void;
}

export const CategoryCascaderModal: React.FC<IComponentProps> = ({ open, categoryList, onCancel, onSelect }) => {
  const options = categoryListToCascaderOptions(categoryList);

  return (
    <Modal className="cascader-modal" open={open} footer={false} closeIcon={false} onCancel={onCancel}>
      <Cascader.Panel
        options={options}
        onChange={onSelect}
        expandIcon={<ChevronIcon />}
        notFoundContent="Список категорий пуст"
      />
    </Modal>
  );
};
