import React, { useMemo } from 'react';
import { DataNode } from 'antd/es/tree';

export const useTreeSearch = (data: DataNode[], search: string) => {
  const highlightTreeNodeTitle = (title: string, search: string) => {
    const regex = new RegExp(`(.*?)(${search})(.*)`, 'i');
    const matches = title.match(regex);

    if (!matches) {
      return <span>{title}</span>;
    }

    const [, before, middle, after] = matches;

    return (
      <span>
        {before}

        <span className="site-tree-search-value" style={{ color: 'red' }}>
          {middle}
        </span>

        {after}
      </span>
    );
  };

  const mapTreeData = (data: DataNode[], search: string): DataNode[] => {
    return data.map((item) => {
      const title = highlightTreeNodeTitle(item.title as string, search);

      if (item.children) {
        return { ...item, title, children: mapTreeData(item.children, search) };
      }

      return { ...item, title };
    });
  };

  const treeData = useMemo(() => mapTreeData(data, search), [data, search]);

  return { treeData };
};
