import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ERequestsNavigationTab } from 'common/const/enum';
import { NeedListNavigation } from 'entities/Need/components/NeedListNavigation';
import { requestsForUserNavigationTabs } from 'entities/Requests/Requests.const';
import { CreatedNeedListForUser } from 'entities/Need/components/CreatedNeedListForUser';
import { CancelledNeedListForUser } from 'entities/Need/components/CancelledNeedListForUser';
import { SupplyListForUser } from 'entities/Supply/components/SupplyListForUser';

export const RequestsForUser: FC = () => {
  const { tab } = useParams();

  const header = <div className="text-body color-dark-grey need-list__subtitle">Мои заявки</div>;

  const navigation = <NeedListNavigation items={requestsForUserNavigationTabs} />;

  if (tab === ERequestsNavigationTab.Cancelled) {
    return <CancelledNeedListForUser header={header} navigation={navigation} />;
  }

  if (tab === ERequestsNavigationTab.SupplyList) {
    return <SupplyListForUser header={header} navigation={navigation} />;
  }

  return <CreatedNeedListForUser header={header} navigation={navigation} />;
};
