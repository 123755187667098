import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ERequestsNavigationTab } from 'common/const/enum';
import { NeedListNavigation } from 'entities/Need/components/NeedListNavigation';
import { requestsForSellerNavigationTabs } from 'entities/Requests/Requests.const';
import { IncomingRequestsForSeller } from 'entities/Requests/components/IncomingRequestsForSeller';
import { SupplyListForSeller } from 'entities/Supply/components/SupplyListForSeller';

export const RequestsForSeller: FC = () => {
  const { tab } = useParams();

  const header = <div className="text-body color-dark-grey need-list__subtitle">Заявки на закупку</div>;

  const navigation = <NeedListNavigation items={requestsForSellerNavigationTabs} />;

  if (tab === ERequestsNavigationTab.SupplyList) {
    return <SupplyListForSeller header={header} navigation={navigation} />;
  }

  return <IncomingRequestsForSeller header={header} navigation={navigation} />;
};
