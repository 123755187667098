import React, { FC } from 'react';
import { Button, Modal, Select, Spin } from 'antd';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';

interface IComponentProps {
  open: boolean;
  loading: boolean;
  sellerOptions?: any[];
  buyerOptions?: any[];
  sellerId: number | null;
  buyerId: number | null;
  changeSellerId: (value: number) => void;
  changeBuyerId: (value: number) => void;
  onCancel: () => void;
  onAdd: () => void;
}

export const AddContractModal: FC<IComponentProps> = ({
  open,
  loading,
  sellerOptions,
  buyerOptions,
  sellerId,
  buyerId,
  changeSellerId,
  changeBuyerId,
  onCancel,
  onAdd,
}) => {
  return (
    <Modal className="modal-gray" open={open} footer={false} closeIcon={false} width={360}>
      <Spin spinning={loading}>
        <div className="modal-gray__body">
          <div className="modal-gray__title">Продавец</div>

          <Select
            style={{ marginBottom: 16 }}
            options={sellerOptions}
            value={sellerId}
            onChange={changeSellerId}
            suffixIcon={<ChevronIcon />}
          />

          <div className="modal-gray__title">Покупатель</div>

          <Select options={buyerOptions} value={buyerId} onChange={changeBuyerId} suffixIcon={<ChevronIcon />} />
        </div>

        <div className="modal-gray__footer">
          <Button className="btn btn-default" onClick={onCancel}>
            Отмена
          </Button>

          <Button className="btn btn-primary" onClick={onAdd} disabled={!sellerId || !buyerId}>
            Добавить
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};
