import React, { FC } from 'react';
import { Button, Image } from 'antd';
import Placeholder from 'app/assets/images/placeholder.png';
import { ICategory } from 'entities/Categories/Categories.models';

interface IComponentProps {
  categoryList: ICategory[];
  onCategoryClick: (id: number) => void;
}

export const ListingCategoryList: FC<IComponentProps> = ({ categoryList, onCategoryClick }) => {
  if (!categoryList.length) {
    return null;
  }

  return (
    <div className="listing-category-list">
      {categoryList.map(({ id, image, displayName }) => {
        return (
          <Button key={id} className="listing-category-list__item" onClick={() => onCategoryClick(id)}>
            {image ? (
              <Image preview={false} width={50} height={50} src={image} />
            ) : (
              <Image preview={false} width={50} height={50} src={Placeholder} />
            )}

            {displayName}
          </Button>
        );
      })}
    </div>
  );
};
