import React, { FC, useEffect, useState } from 'react';
import { Checkbox, Form, Input, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { connect } from 'react-redux';
import { EFormFieldMessage, EPropertyType } from 'common/const/enum';
import { IFormValues } from 'common/models';
import { CHARACTER_LIMIT_MIN } from 'common/config';
import { debounce } from 'common/helpers/data.helper';
import { rules } from 'common/helpers/form.helper';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as EditIcon } from 'app/assets/images/edit.svg';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { propertyFormPresetTypeOptions, propertyFormTypeOptions } from 'entities/Property/Property.const';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export const PropertyFormComponent: FC<AllType> = (props) => {
  const {
    propertyListState,
    propertyCascaderState,
    updateCascaderItemProperty,
    updateCascaderItemPropertyDisplayName,
    setCascaderError,
  } = props;

  const { data: propertyList } = propertyListState;
  const { selectedPropertyId, error } = propertyCascaderState;

  const [type, setType] = useState<string>('');
  const [form] = useForm();

  const debouncedUpdateCascaderItemProperty = debounce(updateCascaderItemProperty);
  const debouncedUpdateCascaderItemPropertyDisplayName = debounce(updateCascaderItemPropertyDisplayName);

  const onFormValuesChange = (value: IFormValues) => {
    if (selectedPropertyId) {
      if (error) {
        setCascaderError(null);
      }

      if (Object.hasOwn(value, 'name')) {
        if (value.name.length >= CHARACTER_LIMIT_MIN) {
          debouncedUpdateCascaderItemProperty({
            id: selectedPropertyId,
            name: value.name,
          });
        }
        return;
      }

      if (Object.hasOwn(value, 'displayName')) {
        if (value.displayName.length >= CHARACTER_LIMIT_MIN) {
          debouncedUpdateCascaderItemPropertyDisplayName({ id: selectedPropertyId, displayName: value.displayName });
        }
        return;
      }

      if (Object.hasOwn(value, 'type')) {
        updateCascaderItemProperty({
          id: selectedPropertyId,
          type: value.type,
          values: type === EPropertyType.Label && value.type !== EPropertyType.Label ? [] : undefined,
          onSuccess: () => {
            setType(value.type);
          },
        });
        return;
      }

      updateCascaderItemProperty({ id: selectedPropertyId, ...value });
    }
  };

  useEffect(() => {
    if (selectedPropertyId) {
      const property = propertyList.find((propertyItem) => propertyItem.id === selectedPropertyId);

      if (property) {
        const propertyType = property.isCategory ? 'category' : property.type;

        form.setFieldsValue({ ...property, type: propertyType });
        setType(propertyType);
      }
    } else {
      form.resetFields();
      setType('');
    }
  }, [selectedPropertyId]);

  useEffect(() => {
    form.setFields([{ name: 'name', errors: error ? [error] : undefined }]);
  }, [error]);

  return (
    <div className="property-form">
      {selectedPropertyId && (
        <Form className="property-form__form" form={form} onValuesChange={onFormValuesChange}>
          <Form.Item name="name" rules={[rules.required(), rules.min(CHARACTER_LIMIT_MIN, EFormFieldMessage.CharacterLimitMin)]}>
            <Input placeholder="Полное название" suffix={<EditIcon />} />
          </Form.Item>

          <Form.Item
            name="displayName"
            rules={[rules.required(), rules.min(CHARACTER_LIMIT_MIN, EFormFieldMessage.CharacterLimitMin)]}
          >
            <Input placeholder="Название для отображения" suffix={<EditIcon />} />
          </Form.Item>

          <div className="property-form__form-item">
            <div>Тип параметра</div>

            <Form.Item name="type">
              <Select
                options={propertyFormTypeOptions}
                popupMatchSelectWidth={false}
                placement="bottomRight"
                suffixIcon={<ChevronIcon />}
                disabled={type === 'category'}
              />
            </Form.Item>
          </div>

          <Form.Item noStyle shouldUpdate={(prevValues, currVelues) => prevValues.type !== currVelues.type}>
            {({ getFieldValue }) => {
              const type = getFieldValue('type');

              return type === 'category' ? (
                <div className="property-form__form-item">
                  <Form.Item name="useInGoods" valuePropName="checked">
                    <Checkbox>Использовать, как свойство товара</Checkbox>
                  </Form.Item>
                </div>
              ) : (
                <div>
                  {type === EPropertyType.Number && (
                    <div className="property-form__form-item">
                      <div>Единицы измерений</div>

                      <Form.Item name="unitOfMeasurement">
                        <Input suffix={<EditIcon />} />
                      </Form.Item>
                    </div>
                  )}

                  <div className="property-form__form-item">
                    <Form.Item name="isRequiredForGoods" valuePropName="checked">
                      <Checkbox>Обязательный параметр для товаров</Checkbox>
                    </Form.Item>
                  </div>

                  <div className="property-form__form-item">
                    <Form.Item name="isRequiredForPresets" valuePropName="checked">
                      <Checkbox>Обязательный параметр для пресета</Checkbox>
                    </Form.Item>
                  </div>

                  {type !== EPropertyType.Label && (
                    <div className="property-form__form-item">
                      <Form.Item name="goodCanHaveMultipleValues" valuePropName="checked">
                        <Checkbox>Допустимо несколько значений у товара</Checkbox>
                      </Form.Item>
                    </div>
                  )}

                  <div className="property-form__form-item">
                    <div>Отображение в пресете</div>

                    <Form.Item name="presetType">
                      <Select
                        options={propertyFormPresetTypeOptions}
                        suffixIcon={<ChevronIcon />}
                        popupMatchSelectWidth={false}
                        placement="bottomRight"
                      />
                    </Form.Item>
                  </div>
                </div>
              );
            }}
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

const mapState = (state: RootState) => ({
  propertyListState: state.propertyListState,
  propertyCascaderState: state.propertyCascaderState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  updateCascaderItemProperty: dispatch.propertyCascaderState.updateCascaderItemProperty,
  updateCascaderItemPropertyDisplayName: dispatch.propertyCascaderState.updateCascaderItemPropertyDisplayName,
  setCascaderError: dispatch.propertyCascaderState.setCascaderError,
});

export const PropertyForm = connect(mapState, mapDispatch)(PropertyFormComponent);
