import { Location } from 'react-router-dom';
import { EPositionFormMode, ERoute, ESettingsMenuTab } from 'common/const/enum';

export const getSettingsPath = (tab: ESettingsMenuTab, id?: number, restParams?: string) => {
  let path = `/settings/${tab}`;

  if (id) {
    path += `/${id}`;
  }

  if (restParams) {
    path += `/${restParams}`;
  }

  return path;
};

export const getShopPath = (location: Location, userCatalogId: number | null) => {
  const { pathname, search } = location;

  return pathname === ERoute.Shop
    ? `${pathname}${search}`
    : userCatalogId
    ? `${ERoute.Shop}?catalogId=${userCatalogId}`
    : `${ERoute.Shop}`;
};

export const getWorkspacePositionPath = (
  mode: EPositionFormMode,
  workspaceId: number,
  categoryId: number,
  positionId?: number,
) => {
  let path = `/workspace-position/${mode}?workspaceId=${workspaceId}&categoryId=${categoryId}`;

  if (positionId) {
    path += `&positionId=${positionId}`;
  }

  return path;
};
