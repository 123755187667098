import axios from 'axios';
import {
  IQrfpCollectionDto,
  IQrfpListParams,
  IQrfp,
  IQrfpGoodsCreatePayload,
  IQrfpCompletePayload,
  IQrfpCreatePayload,
} from 'entities/Qrfp/Qrfp.models';

const basePath = 'qrfp';

export const qrfpTransport = {
  getQrfpList: (params: IQrfpListParams): Promise<IQrfpCollectionDto> => axios.get(`${basePath}`, { params }),
  getQrfp: (id: number): Promise<IQrfp> => axios.get(`${basePath}/${id}`),
  createQrfp: (payload: IQrfpCreatePayload): Promise<IQrfp> => axios.post(`${basePath}`, payload),
  completeQrfp: (payload: IQrfpCompletePayload): Promise<IQrfp> => axios.patch(`${basePath}/complete`, payload),
  createQrfpGoods: (payload: IQrfpGoodsCreatePayload): Promise<IQrfp> => axios.post(`${basePath}/good`, payload),
};
