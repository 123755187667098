import { createModel } from '@rematch/core';
import { IRequestSuccess } from 'common/models';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store';
import {
  IAddressSuggestionListState,
  IAddressSuggestionListParams,
  IAddress,
  IAddressState,
  IAddressCreatePayload,
  IAddressListState,
  IAddressListParams,
  IAddressUpdatePayload,
  IAddressDeletePayload,
  IAddressSuggestion,
} from 'entities/Addresses/Addresses.models';
import { addressesTransport } from 'entities/Addresses/Addresses.transport';

export const addressSuggestionListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IAddressSuggestionListState,
  reducers: {
    setAddressSuggestionList: (state, payload: IAddressSuggestion[]) => ({ ...state, data: payload }),
    setAddressSuggestionListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getAddressSuggestionList(params: IAddressSuggestionListParams) {
      dispatch.addressSuggestionListState.setAddressSuggestionListLoading(true);

      try {
        const response = await addressesTransport.getAddressSuggestionList(params);
        dispatch.addressSuggestionListState.setAddressSuggestionList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.addressSuggestionListState.setAddressSuggestionListLoading(false);
      }
    },
  }),
});

export const addressListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IAddressListState,
  reducers: {
    setAddressList: (state, payload: IAddress[]) => ({ ...state, data: payload }),
    setAddressListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    addAddress: (state, payload: IAddress) => ({ ...state, data: [...state.data, payload] }),
    updateAddress: (state, payload: IAddress) => ({
      ...state,
      data: state.data.map((address) => {
        if (address.id === payload.id) {
          return { ...address, isMain: payload.isMain };
        }

        return { ...address, isMain: false };
      }),
    }),
    deleteAddress: (state, payload: number) => ({
      ...state,
      data: state.data.filter((address) => address.id !== payload),
    }),
  },
  effects: (dispatch) => ({
    async getAddressList(params: IAddressListParams) {
      dispatch.addressListState.setAddressListLoading(true);

      try {
        const response = await addressesTransport.getAddressList(params);
        dispatch.addressListState.setAddressList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.addressListState.setAddressListLoading(false);
      }
    },
  }),
});

export const addressState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IAddressState,
  reducers: {
    setAddress: (state, payload: IAddress) => ({ ...state, data: payload }),
    setAddressLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async createAddress(payload: IAddressCreatePayload) {
      dispatch.addressState.setAddressLoading(true);

      try {
        const response = await addressesTransport.createAddress(payload);
        dispatch.addressListState.addAddress(response);
        dispatch.addressState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.addressState.setAddressLoading(false);
      }
    },
    async updateAddress(payload: IAddressUpdatePayload) {
      dispatch.addressState.setAddressLoading(true);

      try {
        const response = await addressesTransport.updateAddress(payload);
        dispatch.addressListState.updateAddress(response);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.addressState.setAddressLoading(false);
      }
    },
    async deleteAddress(payload: IAddressDeletePayload) {
      dispatch.addressState.setAddressLoading(true);

      try {
        const response = await addressesTransport.deleteAddress(payload);
        dispatch.addressListState.deleteAddress(response.id);
        dispatch.addressState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.addressState.setAddressLoading(false);
      }
    },
  }),
});
