export const catalogGoodsAvailabilityFilterOptions = [
  {
    label: 'Все товары',
    value: false,
  },
  {
    type: 'divider',
  },
  {
    label: 'Товары в наличии',
    value: true,
  },
];
