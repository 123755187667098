import React, { ChangeEvent, RefObject, useEffect, useState } from 'react';
import { Input, Tree } from 'antd';
import type { DataNode } from 'antd/es/tree';
import { ReactComponent as SearchIcon } from 'app/assets/images/search.svg';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { IWorkspaceCatalogCategory } from 'entities/Workspaces/Workspaces.models';
import { workspaceCatalogToList, workspaceCatalogToTree } from 'entities/Workspaces/Workspace.helper';

const { DirectoryTree } = Tree;

interface IComponentProps {
  subdivisionName?: string;
  catalog?: IWorkspaceCatalogCategory[];
  categoryId?: number;
  scrollRef: RefObject<HTMLDivElement>;
  onSelect: (categoryId: number) => void;
}

export const WorkspaceNavigationPanel: React.FC<IComponentProps> = (props) => {
  const { subdivisionName, catalog, categoryId, scrollRef, onSelect } = props;

  const [searchValue, setSearchValue] = useState<string>('');
  const [treeData, setTreeData] = useState<DataNode[]>([]);

  const onCategorySearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setSearchValue(e.target.value);

    if (value.length) {
      const list = workspaceCatalogToList(catalog);
      const searchValue = list.filter((item) => (item.title as string).toLowerCase().includes(value.toLowerCase()));
      setTreeData(searchValue);
    } else {
      const treeData = workspaceCatalogToTree(catalog);
      setTreeData(treeData);
    }
  };

  const onCategorySelect = (keys: (number | string)[]) => {
    onSelect(Number(keys[0]));

    if (scrollRef.current) {
      const element = scrollRef.current.querySelector(`[id='${keys[0]}']`);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        // TODO
        scrollRef.current.scrollTo({ top: 0 });
      }
    }
  };

  useEffect(() => {
    const treeData = workspaceCatalogToTree(catalog);
    setTreeData(treeData);
  }, [catalog]);

  return (
    <div className="workspace-navigation-panel">
      <Input value={subdivisionName} disabled />

      <Input value={searchValue} onChange={onCategorySearch} placeholder="Поиск" prefix={<SearchIcon />} />

      {!!treeData.length && (
        <DirectoryTree
          className="tree tree__with-count"
          treeData={treeData}
          onSelect={onCategorySelect}
          defaultExpandAll
          switcherIcon={<ChevronIcon />}
          selectedKeys={categoryId ? [categoryId] : undefined}
        />
      )}
    </div>
  );
};
