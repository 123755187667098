import { useEffect, useState } from 'react';

export const useContentLoader = (callback: () => Promise<void>, deps: any[] = []) => {
  const [contentLoading, setContentLoading] = useState<boolean>(true);

  useEffect(() => {
    callback().then(() => {
      setContentLoading(false);
    });
  }, [...deps]);

  return { contentLoading };
};
