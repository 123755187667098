import React, { FC } from 'react';
import { Button } from 'antd';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';

interface IComponentProps {
  name: string;
  value: string;
  unitOfMeasurement?: string | null;
  onClear: () => void;
}

export const CatalogFilterChipsItem: FC<IComponentProps> = ({ name, value, unitOfMeasurement, onClear }) => {
  return (
    <div className="catalog-filter-chips__item">
      <span className="text-controls color-white">{name}:</span>

      <span className="text-controls-accent color-white">{value}</span>

      {unitOfMeasurement && <span className="text-controls-accent color-white">{unitOfMeasurement}</span>}

      <Button className="button-icon catalog-filter-chips__item-close" icon={<CloseIcon />} onClick={onClear} />
    </div>
  );
};
