import React, { ChangeEvent, FC, useEffect, useState, MouseEvent } from 'react';
import { Button, Drawer, Input, Image } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { priceFormatter } from 'common/helpers/formatter.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { useDebounce } from 'common/hooks/useDebounce';
import { useCatalogContext } from 'common/hooks/useCatalogContext';
import { Counter } from 'common/components/Counter-r';
import { GoodsDeliveryBadge } from 'common/components/GoodsDeliveryBadge';
import { EMessage, EPlaceholder, ERoute, EUserRole } from 'common/const/enum';
import { COUNT_CHANGE_DELAY } from 'common/config';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { ReactComponent as CopyIcon } from 'app/assets/images/redesign/copy.svg';
import placeholder from 'app/assets/images/redesign/placeholder.svg';
import { RootDispatch, RootState } from 'app/store';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const GoodsCardComponent: FC<AllType> = (props) => {
  const {
    // state
    auth,
    xBasketId,
    goods,
    basket,
    basketLoading,
    // dispatch
    updateGoodsListGoodsInBasket,
    setGoods,
    updateGoods,
    updateBasket,
    updateStatisticsBasketGoodsCount,
  } = props;

  const [vendorCodeIsFocused, setVendorCodeIsFocused] = useState<boolean>(false);
  const [vendorCodeIsChanged, setVendorCodeIsChanged] = useState<boolean>(false);
  const [countIsChanged, setCountIsChanged] = useState<boolean>(false);
  const navigate = useNavigate();
  const { count, openGoodsCard, setCount, setOpenGoodsCard, onCategoryChange } = useCatalogContext();

  const isSellerManager = auth?.access.roles.includes(EUserRole.SellerManager);
  const isSeller = auth?.access.isSeller;
  const goodsId = goods?.id;
  const categoryId = goods?.categoryId;
  const vendorCode = goods?.vendorCode;
  const showAddGoodsToBasketBtn = !isSellerManager || (isSellerManager && xBasketId);

  const onVendorCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (goods) {
      setVendorCodeIsChanged(true);
      setGoods({ ...goods, vendorCode: e.target.value });
    }
  };

  const onVendorCodeCopy = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    if (vendorCode) {
      navigator.clipboard.writeText(vendorCode);
    }
  };

  const handleCategoryClick = () => {
    setOpenGoodsCard(false);

    if (categoryId) {
      onCategoryChange(categoryId);
    }
  };

  const addGoodsToBasket = (id: number) => {
    if (basket) {
      const newBasketGoods = [
        ...basket.goods.map((basketGoods) => ({ goodId: basketGoods.goodId, count: basketGoods.count })),
        { goodId: id, count },
      ];

      updateBasket({
        id: basket.id,
        goods: newBasketGoods,
        onSuccess: () => {
          if (goods) {
            setGoods({ ...goods, goodInBasket: true });
          }

          updateGoodsListGoodsInBasket({ id, goodInBasket: true });
          updateStatisticsBasketGoodsCount(newBasketGoods.length);
          showSuccessMessage(EMessage.GoodsAddedToCart);
        },
      });
    }
  };

  const goToBasket = () => {
    if (xBasketId) {
      navigate(`/active-basket-list/${xBasketId}`);
    } else {
      navigate(ERoute.Basket);
    }
  };

  const onCountChange = (value: number, goodInBasket: boolean) => {
    if (goodInBasket) {
      setCountIsChanged(true);
    }

    setCount(value);
  };

  useEffect(() => {
    if (!openGoodsCard) {
      setVendorCodeIsChanged(false);
    }
  }, [openGoodsCard]);

  useDebounce(() => {
    if (goodsId && vendorCodeIsChanged) {
      updateGoods({ id: goodsId, vendorCode: vendorCode ? vendorCode : null });
    }
  }, [vendorCodeIsChanged, vendorCode]);

  useDebounce(
    () => {
      if (goodsId && basket && countIsChanged) {
        updateBasket({
          id: basket.id,
          goods: basket.goods.map((basketGoods) => {
            if (basketGoods.goodId === goodsId) {
              return { ...basketGoods, count };
            }

            return basketGoods;
          }),
          onSuccess: () => setCountIsChanged(false),
        });
      }
    },
    [goodsId, basket, countIsChanged, count],
    COUNT_CHANGE_DELAY,
  );

  if (!goods) {
    return null;
  }

  return (
    <Drawer rootClassName="redesign goods-card-r" open={openGoodsCard} onClose={() => setOpenGoodsCard(false)} width={800}>
      <div className="goods-card-r__header">
        <span className="text-body color-dark-grey">{goods.sellerCode}</span>

        <span className="text-h4 color-bright-green">{goods.brand}</span>
      </div>

      <span className="text-h1 goods-card-r__name">{goods.name}</span>

      <div className="goods-card-r__labels-container">
        <GoodsDeliveryBadge remains={goods.remains} count={count} />
      </div>

      <div className="goods-card-r__price">
        <span className="text-h2">{priceFormatter(goods.priceWithTaxes)}</span>

        <span className="text-controls color-light-grey"> / </span>

        <span className="text-controls color-dark-grey">шт, включая НДС</span>
      </div>

      <div className="goods-card-r__actions-container">
        {!isSeller && (
          <div className="input-with-label" style={{ flex: 1 }}>
            <span className="text-tag input-with-label__label">Артикул клиента</span>

            <Input
              className="input-with-value"
              value={goods.vendorCode}
              onChange={onVendorCodeChange}
              placeholder={EPlaceholder.AddVendorCode}
              onFocus={() => setVendorCodeIsFocused(true)}
              onBlur={() => setVendorCodeIsFocused(false)}
              allowClear={vendorCodeIsFocused && { clearIcon: <CloseIcon /> }}
              suffix={
                <span style={{ lineHeight: 0 }}>
                  {!!vendorCode?.length && !vendorCodeIsFocused && (
                    <Button className="button-icon" icon={<CopyIcon />} onClick={onVendorCodeCopy} />
                  )}
                </span>
              }
            />
          </div>
        )}

        {showAddGoodsToBasketBtn && <Counter count={count} onChange={(value) => onCountChange(value, goods.goodInBasket)} />}
      </div>

      {showAddGoodsToBasketBtn && (
        <div className="goods-card-r__btn">
          {goods.goodInBasket ? (
            <Button className="button-l success w-100" onClick={goToBasket}>
              Перейти в корзину
            </Button>
          ) : (
            <Button className="button-l primary w-100" onClick={() => addGoodsToBasket(goods.id)} loading={basketLoading}>
              Добавить в корзину
            </Button>
          )}
        </div>
      )}

      <Image
        wrapperClassName="image-640"
        wrapperStyle={{ marginBottom: 72 }}
        src={goods.image ? goods.image : placeholder}
        preview={false}
      />

      <div className="goods-card-r__block">
        <div className="text-h4 goods-card-r__block-title">Характеристики</div>

        <div className="goods-card-r__block-list">
          {goods.properties.map((property) => {
            return (
              <div key={property.propertyId} className="goods-card-r__block-list-row">
                <span className="text-body color-dark-grey">{`${property.propertyName}:`}</span>

                <span className="text-body">{`${property.value} ${property.unitOfMeasurement || ''}`}</span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="goods-card-r__block">
        <div className="text-h4 goods-card-r__block-title">Категории с товаром</div>

        <Button className="button-chip" onClick={handleCategoryClick}>
          {goods.categoryName}
        </Button>
      </div>
    </Drawer>
  );
};

const mapState = (state: RootState) => ({
  auth: state.authState.data,
  xBasketId: state.authState.xBasketId,
  goods: state.goodsState.data,
  basket: state.basketState.data,
  basketLoading: state.basketState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  updateGoodsListGoodsInBasket: dispatch.goodsListState.updateGoodsListGoodsInBasket,
  setGoods: dispatch.goodsState.setGoods,
  updateGoods: dispatch.goodsState.updateGoods,
  updateBasket: dispatch.basketState.updateBasket,
  updateStatisticsBasketGoodsCount: dispatch.statisticsState.updateStatisticsBasketGoodsCount,
});

export const GoodsCard = connect(mapState, mapDispatch)(GoodsCardComponent);
