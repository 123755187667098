import axios from 'axios';
import {
  IStock,
  IStockCollectionDto,
  IStockListParams,
  IStockCreatePayload,
  IStockDeletePayload,
  IStockUpdatePayload,
} from 'entities/Stock/Stock.models';

const basePath = 'stock';

export const stockTransport = {
  getStockList: (params: IStockListParams): Promise<IStockCollectionDto> => axios.get(`${basePath}`, { params }),
  createStock: (payload: IStockCreatePayload): Promise<IStock> => axios.post(`${basePath}`, payload),
  updateStock: (payload: IStockUpdatePayload): Promise<IStock> => axios.patch(`${basePath}`, payload),
  deleteStock: (payload: IStockDeletePayload): Promise<IStock> => axios.delete(`${basePath}`, { data: payload }),
};
