/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { ERoute, EUserRole } from 'common/const/enum';
import { STATISTICS_OVERFLOW_COUNT } from 'common/config';
import { ReactComponent as CatalogIcon } from 'app/assets/images/redesign/catalog.svg';
import { ReactComponent as TreeIcon } from 'app/assets/images/redesign/tree-alt.svg';
import { ReactComponent as AccountIcon } from 'app/assets/images/redesign/account.svg';
import { ReactComponent as ContractIcon } from 'app/assets/images/redesign/contract.svg';
import { ReactComponent as DrawerIcon } from 'app/assets/images/redesign/drawer.svg';
import { ReactComponent as CartIcon } from 'app/assets/images/redesign/cart.svg';
import { ReactComponent as DrawerFullIcon } from 'app/assets/images/redesign/drawer-full.svg';
import { ReactComponent as ExitIcon } from 'app/assets/images/redesign/exit.svg';
import { IToken } from 'entities/Auth/Auth.models';
import { getUserInitials, getUserName } from 'entities/Users/Users.helper';
import { IUserStatistics } from 'entities/Users/Users.models';

export const getStatisticsItemCount = (count?: number) => {
  if (!count) {
    return null;
  }

  return count > STATISTICS_OVERFLOW_COUNT ? `${count}+` : count;
};

export const getSidebarItemClassName = ({ isActive }: { isActive: boolean }) =>
  `sidebar-r__item ${isActive ? 'sidebar-r__item-active' : ''}`;

export const getSidebarItems = (
  auth: IToken | null,
  xBasketId: number | null,
  superAdminAsUser: boolean,
  statistics: IUserStatistics,
  onClick: () => void,
  logout: () => void,
) => {
  const isSuperAdmin = auth?.access.roles.includes(EUserRole.SuperAdmin);
  const isAccountAdmin = auth?.access.roles.includes(EUserRole.AccountAdmin);
  const isSellerManager = auth?.access.roles.includes(EUserRole.SellerManager);
  const isManager = auth?.access.roles.includes(EUserRole.Manager);
  const isSeller = auth?.access.isSeller;
  const firstName = auth?.access.firstName;
  const lastName = auth?.access.lastName;
  const initials = getUserInitials(firstName, lastName);
  const userName = getUserName(firstName, lastName);

  return {
    body: {
      orangeGroup: [
        {
          key: 'catalog-list',
          show: isSuperAdmin,
          to: ERoute.CatalogList,
          icon: <CatalogIcon />,
          label: 'Системные каталоги',
          onClick,
        },
        {
          key: 'catalog',
          show: !isSuperAdmin,
          to: window.location.pathname.includes(ERoute.Catalog) ? `${window.location.pathname}` : ERoute.Catalog,
          icon: <CatalogIcon />,
          label: 'Каталог',
          onClick,
        },
      ],
      darkGreenGroup: [
        {
          key: 'properties-settings',
          show: isSuperAdmin,
          to: ERoute.PropertiesSettings,
          icon: <TreeIcon />,
          label: 'Дерево характеристик',
          onClick,
        },
        {
          key: 'seller-need-list',
          show: !isSuperAdmin && isSellerManager,
          to: ERoute.RequestsForSeller,
          icon: <DrawerFullIcon />,
          label: 'Заявки на поставку',
          badge: getStatisticsItemCount(statistics.sellerRequestsCount),
          onClick,
        },
        {
          key: 'manager-need-list',
          show: !isSuperAdmin && isManager,
          to: ERoute.RequestsForManager,
          icon: <DrawerFullIcon />,
          label: 'Заявки на закупку',
          badge: getStatisticsItemCount(statistics.requestsCount),
          onClick,
        },
        {
          key: 'need-list',
          show: !isSuperAdmin && !isSellerManager,
          to: ERoute.RequestsForUser,
          icon: <DrawerIcon />,
          label: 'Мои заявки',
          onClick,
        },
      ],
      brightGreenGroup: [
        {
          key: 'account-list',
          show: isSuperAdmin,
          to: ERoute.AccountList,
          icon: <AccountIcon />,
          label: 'Аккаунты',
          onClick,
        },
        {
          key: 'contract-list',
          show: isSuperAdmin,
          to: ERoute.ContractList,
          icon: <ContractIcon />,
          label: 'Контракты',
          onClick,
        },
        {
          key: 'admin-contract-list',
          show: !isSuperAdmin && isAccountAdmin && isSeller,
          to: ERoute.AdminContractList,
          icon: <ContractIcon />,
          label: 'Контракты',
          onClick,
        },
        {
          key: 'active-basket-list',
          show: !isSuperAdmin && isSellerManager,
          to: ERoute.ActiveBasketList,
          icon: <CartIcon />,
          label: 'Корзины клиентов',
          end: xBasketId ? window.location.pathname.includes(`${ERoute.ActiveBasketList}/${xBasketId}`) : false,
          onClick,
        },
        {
          key: 'active-basket',
          show: !isSuperAdmin && isSellerManager && !!xBasketId,
          to: `${ERoute.ActiveBasketList}/${xBasketId}`,
          icon: <CartIcon />,
          label: 'Привязанная корзина',
          end: true,
          onClick,
        },
        {
          key: 'basket',
          show: !isSuperAdmin && !isSellerManager,
          to: ERoute.Basket,
          icon: <CartIcon />,
          label: 'Корзина',
          badge: getStatisticsItemCount(statistics.basketGoodsCount),
          onClick,
        },
      ],
    },
    footer: [
      {
        key: 'profile',
        show: !isSuperAdmin,
        to: ERoute.Profile,
        icon: (
          <span className="text-profile-icon color-dark-grey" style={{ zIndex: 1 }}>
            {initials}
          </span>
        ),
        label: userName,
        iconClassName: 'sidebar-r__footer-item-profile-icon',
        onClick,
      },
      {
        key: 'logout',
        show: true,
        to: '',
        icon: <ExitIcon />,
        label: superAdminAsUser ? 'Вернуться в аккаунт суперадминистратора' : 'Выйти',
        onClick: () => {
          onClick();
          logout();
        },
      },
    ],
  };
};

export const getSidebarHeaderSubtitle = (roles?: string[], subdivisionName?: string) => {
  const isSuperAdmin = roles?.includes(EUserRole.SuperAdmin);
  const isAccountAdmin = roles?.includes(EUserRole.AccountAdmin);
  const isSellerManager = roles?.includes(EUserRole.SellerManager);
  const isManager = roles?.includes(EUserRole.Manager);

  switch (true) {
    case !!subdivisionName: {
      return subdivisionName;
    }
    case isSuperAdmin: {
      return 'Суперадмин';
    }
    case isAccountAdmin: {
      return 'Администратор';
    }
    case isSellerManager: {
      return 'Менеджер';
    }
    case isManager: {
      return 'Менеджер по закупкам';
    }
    default: {
      return null;
    }
  }
};
