import React from 'react';
import { Breadcrumb } from 'antd';
import { ICategoryDetailed } from 'entities/Categories/Categories.models';
import { categoryToBreadcrumbItems } from 'entities/Shop/Shop.helper';

interface IComponentProps {
  category: ICategoryDetailed | null;
  onClick: (id: number) => void;
}

export const ShopNavigation: React.FC<IComponentProps> = ({ category, onClick }) => {
  const breadcrumbItems = categoryToBreadcrumbItems(category, onClick);

  return <Breadcrumb separator="|" items={breadcrumbItems} />;
};
