import React, { useMemo, useState } from 'react';
import { Button, Spin, Table } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { ENotificationType, EPositionFormMode } from 'common/const/enum';
import { getPresetName, renderPresetList } from 'common/helpers/preset.helper';
import { ICategoryDetailed } from 'entities/Categories/Categories.models';
import { findGroupingProperty, goodsDataToDataSource, renderGoodsRecords } from 'entities/Goods/Goods.helper';
import { IGoods } from 'entities/Goods/Goods.models';
import { Notification } from 'entities/Modal/components/Notification';
import { IPresetItem } from 'entities/Presets/Preset.models';

interface IComponentProps {
  title: string | null;
  loading: boolean;
  category: ICategoryDetailed | null;
  goodsList: IGoods[];
  presetList: IPresetItem[];
  onGoodsClick: (id: number) => void;
}

export const GoodsList: React.FC<IComponentProps> = (props) => {
  const { title, loading, category, goodsList, presetList, onGoodsClick } = props;
  // // TODO remove temp
  const [openNotification, setOpenNotification] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const groupingProperty = findGroupingProperty(goodsList, category?.groupingProperty?.propertyId);
  const groupingPropertyTitle = `${groupingProperty?.value} ${groupingProperty?.unitOfMeasurement || ''} (${
    groupingProperty?.propertyName || ''
  })`;
  const { goodsWithGroupingPropertyDataSource, goodsWithoutGroupingPropertyDataSource } = useMemo(() => {
    return goodsDataToDataSource(goodsList, category);
  }, [goodsList, category]);
  const columns = renderGoodsRecords(category);

  // eslint-disable-next-line
  const onSendRequestClick = () => {
    navigate(`/qrfp-position/${EPositionFormMode.Create}${location.search}&catalogId=${category?.catalogId}`);
  };

  // TODO remove
  const tempOnSendRequestClick = () => {
    setOpenNotification(true);
  };

  return (
    <div className="goods-list">
      <div className="goods-list__title">{title}</div>

      <Spin spinning={loading}>
        <div className="goods-list__list">
          {!!goodsWithGroupingPropertyDataSource?.length && (
            <div className="goods-list__table-wrapper">
              <div className="goods-list__table-title">{groupingPropertyTitle}</div>

              <Table
                className="goods-list__table"
                columns={columns}
                dataSource={goodsWithGroupingPropertyDataSource}
                pagination={false}
                onRow={(record) => ({ onClick: () => onGoodsClick(Number(record.key)) })}
              />
            </div>
          )}

          {!!goodsWithoutGroupingPropertyDataSource?.length && (
            <div className="goods-list__table-wrapper">
              <Table
                className="goods-list__table"
                columns={columns}
                dataSource={goodsWithoutGroupingPropertyDataSource}
                pagination={false}
                onRow={(record) => ({ onClick: () => onGoodsClick(Number(record.key)) })}
              />
            </div>
          )}

          {!goodsList.length && (
            <div className="goods-list__table-wrapper">
              <div className="goods-list__empty-message">
                По вашему запросу ничего не найдено. Вы можете отправить запрос на подбор поставщику. Все ваши запросы доступны во
                вкладке Корзина.
              </div>

              {!!presetList.length && (
                <div className="preset">
                  <div className="preset__container">{renderPresetList(presetList)}</div>

                  {/* TODO replace with onSendRequestClick */}
                  <Button type="link" onClick={tempOnSendRequestClick}>
                    Отправить запрос поставщику
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </Spin>

      {/* TODO Remove */}
      <Notification
        open={openNotification}
        type={ENotificationType.Notification}
        description={
          <div>
            <span>
              В данный момент прямой запрос на поставку недоступен – пожалуйста, напишите нам на почту
              <span style={{ fontFamily: 'Roboto-Bold, sans-serif' }}> shugay.a@garagetools.ru</span>, в тело письма скопируйте
              необходимые вам параметры товара.
            </span>

            <div style={{ marginTop: 10 }}>{getPresetName(presetList)}</div>
          </div>
        }
        width={400}
        onCancelClick={() => setOpenNotification(false)}
        onConfirmClick={() => setOpenNotification(false)}
      />
    </div>
  );
};
