import axios from 'axios';
import {
  IBasketCollectionDto,
  IBasketListParams,
  IBasket,
  IBasketBasedOnRequestCreatePayload,
  IBasketRequestCreatePayload,
  IBasketUpdatePayload,
} from 'entities/Basket/Basket.models';

const basePath = 'basket';

export const basketTransport = {
  getBasket: (): Promise<IBasket> => axios.get(`${basePath}/current`),
  getBasketById: (id: number): Promise<IBasket> => axios.get(`${basePath}/${id}`),
  updateBasket: (payload: IBasketUpdatePayload): Promise<IBasket> => axios.patch(`${basePath}`, payload),
  createBasketBasedOnRequest: (payload: IBasketBasedOnRequestCreatePayload): Promise<IBasket> =>
    axios.post(`${basePath}/based-on-request`, payload),
  createBasketRequest: (payload: IBasketRequestCreatePayload): Promise<IBasket> => axios.post(`${basePath}/request`, payload),
  getBasketList: (params: IBasketListParams): Promise<IBasketCollectionDto> => axios.get(`${basePath}`, { params }),
  uploadBasketExcel: (id: number): Promise<ArrayBuffer> => axios.get(`${basePath}/file/${id}`, { responseType: 'arraybuffer' }),
};
