import axios from 'axios';
import {
  ICategoryCollectionDto,
  ICategoryListParams,
  ICategoryCreatePayload,
  ICategoryDeletePayload,
  ICategoryDetailed,
  ICategoryUpdatePayload,
} from 'entities/Categories/Categories.models';

const basePath = 'category';

export const categoriesTransport = {
  getCategoryList: (params: ICategoryListParams): Promise<ICategoryCollectionDto> => axios.get(`${basePath}`, { params }),
  getCategoryById: (id: number): Promise<ICategoryDetailed> => axios.get(`${basePath}/${id}`),
  createCategory: (payload: ICategoryCreatePayload): Promise<ICategoryDetailed> => axios.post(`${basePath}`, payload),
  updateCategory: (payload: ICategoryUpdatePayload): Promise<ICategoryDetailed> => axios.patch(`${basePath}`, payload),
  deleteCategory: (payload: ICategoryDeletePayload): Promise<ICategoryDetailed> => axios.delete(`${basePath}`, { data: payload }),
};
