import React, { FC, useEffect, useState } from 'react';
import { Dropdown, Input, Table, Button } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Image } from 'common/components/Image';
import { useDebounce } from 'common/hooks/useDebounce';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { useCatalogContext } from 'common/hooks/useCatalogContext';
import { EPlaceholder, ERoute, ESearchParams } from 'common/const/enum';
import { COLLECTION_LIMIT_5, SEARCH_LIMIT_MIN } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { ReactComponent as CatalogIcon } from 'app/assets/images/redesign/catalog.svg';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import {
  generateCatalogSearchValueHighlightedText,
  renderCatalogSearchDropdownProductsRecords,
} from 'entities/Catalog/Catalog.helper';

interface IComponentProps {
  catalogId?: number;
  catalogName?: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const CatalogSearchComponent: FC<AllType> = (props) => {
  const {
    // props
    catalogId,
    catalogName,
    // state
    searchSuggestionsCategories,
    searchSuggestionsGoods,
    // dispatch
    resetSearchSuggestions,
    getSearchSuggestions,
  } = props;

  const [search, setSearch] = useState<string>('');
  const [focused, setFocused] = useState<boolean>(false);
  const navigate = useNavigate();
  const { getSearchParam } = useSearchParamsHook();
  const { onCategoryChange, onOpenGoodsCard } = useCatalogContext();

  const defaultValue = getSearchParam(ESearchParams.Search);

  const toggleFocused = () => setFocused((prev) => !prev);

  const onDropdownOpenChange = (open: boolean) => {
    if (!open) {
      resetSearchSuggestions();
    }
  };

  const handleCategoryClick = (id: number) => {
    resetSearchSuggestions();
    onCategoryChange(id);
  };

  const handleGoodsClick = (id: number) => {
    resetSearchSuggestions();
    onOpenGoodsCard(id, false);
  };

  const onSearchClick = () => {
    resetSearchSuggestions();

    if (catalogId) {
      navigate(`${ERoute.Catalog}/${catalogId}?${ESearchParams.Search}=${search}`);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setSearch(defaultValue);
    } else {
      setSearch('');
    }
  }, [catalogId, defaultValue]);

  useDebounce(() => {
    if (catalogId && focused && search.length >= SEARCH_LIMIT_MIN) {
      getSearchSuggestions({ catalogId: catalogId, search });
    } else {
      resetSearchSuggestions();
    }
  }, [catalogId, search, focused]);

  return (
    <>
      <Dropdown
        overlayClassName="redesign"
        overlayStyle={{ width: 'min-content' }}
        open={!!searchSuggestionsCategories.length || !!searchSuggestionsGoods.length}
        onOpenChange={onDropdownOpenChange}
        trigger={['click']}
        disabled={!catalogId}
        dropdownRender={() => {
          return (
            <div className="custom-dropdown catalog-search__dropdown">
              <div className="catalog-search__dropdown-categories">
                <span className="text-tag-accent color-bright-green custom-dropdown__title">Категории</span>

                <div
                  className={`${
                    searchSuggestionsCategories.length > COLLECTION_LIMIT_5
                      ? 'catalog-search__dropdown-categories-scroll scrollbar'
                      : ''
                  }`}
                >
                  {searchSuggestionsCategories.map(({ id, image, displayName }) => {
                    return (
                      <div key={id} className="catalog-search__dropdown-categories-item" onClick={() => handleCategoryClick(id)}>
                        <div className="catalog-search__dropdown-categories-item-container">
                          <Image size={40} src={image} />

                          <span style={{ flex: 1 }}>{generateCatalogSearchValueHighlightedText(displayName, search)}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="catalog-search__dropdown-products">
                <span className="text-tag-accent color-bright-green custom-dropdown__title">Товары</span>

                <Table
                  showHeader={false}
                  pagination={false}
                  size="small"
                  className="table-hover-bg catalog-search__dropdown-products-table"
                  dataSource={searchSuggestionsGoods.map((goods) => ({ ...goods, key: goods.id }))}
                  columns={renderCatalogSearchDropdownProductsRecords(search)}
                  onRow={({ id }) => {
                    return {
                      onClick: () => handleGoodsClick(id),
                    };
                  }}
                />
              </div>
            </div>
          );
        }}
      >
        <div className="catalog-search__container">
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={EPlaceholder.SearchGoodsByNameOrVendorCode}
            disabled={!catalogId}
            onFocus={toggleFocused}
            onBlur={toggleFocused}
          />

          {!!search.length && <Button className="catalog-search__clear-btn" icon={<CloseIcon />} onClick={() => setSearch('')} />}

          {catalogName && (
            <div className="catalog-search__catalog">
              <CatalogIcon />

              <span className="text-controls-accent color-bright-green">{`Каталог поставщика ${catalogName}`}</span>
            </div>
          )}
        </div>
      </Dropdown>

      <Button className="button-circle primary" icon={<SearchIcon />} onClick={onSearchClick} disabled={!catalogId} />
    </>
  );
};

const mapState = (state: RootState) => ({
  searchSuggestionsCategories: state.searchSuggestionsState.data.categories,
  searchSuggestionsGoods: state.searchSuggestionsState.data.goods,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  resetSearchSuggestions: dispatch.searchSuggestionsState.resetSearchSuggestions,
  getSearchSuggestions: dispatch.searchSuggestionsState.getSearchSuggestions,
});

export const CatalogSearch = connect(mapState, mapDispatch)(CatalogSearchComponent);
