import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { destroyMessage, showLoadingMessage, showSuccessMessage } from 'common/helpers/message.helper';
import { IRootModel } from 'app/store';
import {
  IContract,
  IContractListParams,
  IContractListState,
  IContractCreatePayload,
  IContractDeletePayload,
  IContractState,
  IContractUpdatePayload,
} from 'entities/Contract/Contract.models';
import { contractTransport } from 'entities/Contract/Contract.transport';
import { updateContractListState } from 'entities/Contract/Contract.helper';

export const contractListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IContractListState,
  reducers: {
    setContractList: (state, payload: IContract[]) => ({ ...state, data: payload }),
    setContractListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    addContract: updateContractListState.addContract,
    updateContract: updateContractListState.updateContract,
    deleteContract: updateContractListState.deleteContract,
  },
  effects: (dispatch) => ({
    async getContractList(params: IContractListParams) {
      dispatch.contractListState.setContractListLoading(true);

      try {
        const response = await contractTransport.getContractList(params);
        dispatch.contractListState.setContractList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.contractListState.setContractListLoading(false);
      }
    },
  }),
});

export const contractState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IContractState,
  reducers: {
    setContract: (state, payload: IContract) => ({ ...state, data: payload }),
    setContractLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getContractById(id: number) {
      dispatch.contractState.setContractLoading(true);

      try {
        const response = await contractTransport.getContractById(id);
        dispatch.contractState.setContract(response);
        return response;
      } catch (error) {
        axiosErrorHandler(error);
        return null;
      } finally {
        dispatch.contractState.setContractLoading(false);
      }
    },
    async createContract(payload: IContractCreatePayload) {
      dispatch.contractState.setContractLoading(true);

      try {
        const response = await contractTransport.createContract(payload);
        dispatch.contractListState.addContract(response);
        dispatch.contractState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.contractState.setContractLoading(false);
      }
    },
    async updateContract(payload: IContractUpdatePayload) {
      dispatch.contractState.setContractLoading(true);
      showLoadingMessage(0);

      try {
        const response = await contractTransport.updateContract(payload);
        dispatch.contractListState.updateContract(response);
        dispatch.contractState.setContract(response);
        dispatch.contractState.onSuccess(payload);
        showSuccessMessage();
      } catch (error) {
        destroyMessage();
        axiosErrorHandler(error);
      } finally {
        dispatch.contractState.setContractLoading(false);
      }
    },
    async deleteContract(payload: IContractDeletePayload) {
      dispatch.contractState.setContractLoading(true);

      try {
        const response = await contractTransport.deleteContract(payload);
        dispatch.contractListState.deleteContract(response.id);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.contractState.setContractLoading(false);
      }
    },
  }),
});
