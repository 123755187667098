import React from 'react';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ThreeDotMenu } from 'common/components/ThreeDotMenu';
import { dataToTree } from 'common/helpers/tree.helper';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { IContract, IContractListState } from 'entities/Contract/Contract.models';
import { ISeller } from 'entities/Sellers/Sellers.models';
import { IBuyer } from 'entities/Buyers/Buyers.models';
import { IDiscount } from 'entities/Discount/Discount.models';
import { ICategory } from 'entities/Categories/Categories.models';

export const renderContractListForSuperAdminRecords = (onDeleteClick: (id: number) => void) => {
  return [
    {
      key: 'sellerName',
      render: (_, record) => getContractName(record),
    },
    {
      key: 'menu',
      render: (_, record) => {
        return (
          <ThreeDotMenu
            items={[
              {
                key: 'delete',
                label: <Button onClick={() => onDeleteClick(record.id)}>Удалить</Button>,
                className: 'delete',
              },
            ]}
          />
        );
      },
      width: 64,
    },
  ] as ColumnsType<IContract>;
};

export const listDataToAddContractSelectOptions = (data: (ISeller | IBuyer)[]) =>
  data.map((item) => ({ label: item.name, value: item.id }));

export const renderContractListForAccountAdminRecords = () => {
  return [
    {
      key: 'sellerName',
      render: (_, record) => `${record.sellerName} - ${record.buyerName}`,
    },
    {
      key: 'icon',
      render: () => {
        return (
          <div className="contract-list__table-item-icon">
            <ChevronIcon />
          </div>
        );
      },
      width: 48,
    },
  ] as ColumnsType<IContract>;
};

export const discountsToContractDiscountSelectOptions = (discounts: IDiscount[]) => {
  const options = discounts.map((discount) => ({ label: discount.name, value: discount.id }));

  return [{ label: 'Без скидок', value: null }, ...options];
};

export const getContractName = (contract: IContract | null) => {
  return contract ? `${contract.sellerName} - ${contract.buyerName}` : '';
};

export const getCategorySettingsTreeData = (categoryList: ICategory[]) => {
  const mappedCategoryList = categoryList.map((category) => ({ ...category, name: category.displayName }));
  return dataToTree(mappedCategoryList);
};

const getContractCategoryModalTreeNodeParentIds = (categoryList: ICategory[], id: number, result: number[] = []): number[] => {
  const category = categoryList.find((categoryItem) => categoryItem.id === id);

  if (category) {
    result.push(category.id);

    if (category.parentId) {
      getContractCategoryModalTreeNodeParentIds(categoryList, category.parentId, result);
    }
  }
  return result;
};

export const findCategoryTreeExpandedKeysBySearch = (categoryList: ICategory[], search: string): number[] => {
  const allParentIds: number[] = [];

  categoryList.forEach(({ id, displayName }) => {
    if (displayName.toLowerCase().includes(search.toLowerCase())) {
      const parentIds = getContractCategoryModalTreeNodeParentIds(categoryList, id);
      allParentIds.push(...parentIds);
    }
  });

  return [...new Set(allParentIds)];
};

export const findInitialCategoryTreeExpandedKeys = (categoryList: ICategory[], ids: number[]): number[] => {
  const allParentIds: number[] = [];

  ids.forEach((id) => {
    const parentIds = getContractCategoryModalTreeNodeParentIds(categoryList, id);
    allParentIds.push(...parentIds);
  });

  return [...new Set(allParentIds)];
};

export const updateContractListState = {
  addContract: (state: IContractListState, payload: IContract) => ({ ...state, data: [...state.data, payload] }),
  updateContract: (state: IContractListState, payload: IContract) => ({
    ...state,
    data: state.data.map((contract) => (contract.id === payload.id ? payload : contract)),
  }),
  deleteContract: (state: IContractListState, payload: number) => ({
    ...state,
    data: state.data.filter((contract) => contract.id !== payload),
  }),
};
