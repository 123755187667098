import React from 'react';
import { Modal, FormInstance, Button } from 'antd';
import { FormInputPassword } from 'common/components/Form/FormInputPassword';
import { EFormFieldMessage } from 'common/const/enum';
import { rules } from 'common/helpers/form.helper';
import { PASSWORD_PATTERN } from 'common/config';
import { AuthCard } from 'entities/Auth/components/AuthCard';
import { IUserChangePasswordPayload } from 'entities/Users/Users.models';

interface IComponentProps {
  open: boolean;
  form: FormInstance;
  loading: boolean;
  error: string | null;
  onSubmit: (values: IUserChangePasswordPayload) => void;
  onCancelClick: () => void;
}

export const ChangePasswordModal: React.FC<IComponentProps> = ({ open, form, loading, error, onSubmit, onCancelClick }) => {
  return (
    <Modal className="change-password-modal" open={open} footer={false} closeIcon={false}>
      <AuthCard
        form={form}
        loading={loading}
        body={
          <>
            <FormInputPassword
              label="Текущий пароль"
              name="currentPassword"
              placeholder="Введите текущий пароль"
              rules={[rules.required()]}
            />

            <FormInputPassword
              label="Новый пароль"
              name="newPassword"
              placeholder="Придумайте пароль"
              dependencies={['currentPassword']}
              rules={[
                rules.required(),
                rules.pattern(PASSWORD_PATTERN, EFormFieldMessage.InvalidPasswordFormat),
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('currentPassword') !== value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error(EFormFieldMessage.PasswordsMatch));
                  },
                }),
              ]}
            />

            <FormInputPassword
              name="passwordConfirm"
              placeholder="Введите пароль еще раз"
              dependencies={['newPassword']}
              rules={[
                rules.required(),
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error(EFormFieldMessage.PasswordMismatch));
                  },
                }),
              ]}
            />

            {!!error && (
              <div className="auth-card__error-block">
                <span className="auth-card__error-block-item">{error}</span>
              </div>
            )}
          </>
        }
        footer={
          <>
            <Button className="btn btn-default" onClick={onCancelClick}>
              Отмена
            </Button>

            <Button className="btn btn-primary" htmlType="submit">
              Изменить пароль
            </Button>
          </>
        }
        onSubmit={onSubmit}
      />
    </Modal>
  );
};
