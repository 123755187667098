import React, { FC } from 'react';
import { Button, Input, Modal, Spin } from 'antd';

interface IComponentProps {
  open: boolean;
  loading: boolean;
  vendorCode: string;
  onVendorCodeChange: (vendorCode: string) => void;
  onCancelClick: () => void;
  onAddClick: () => void;
}

export const AddVendorCodeModal: FC<IComponentProps> = (props) => {
  const { open, loading, vendorCode, onVendorCodeChange, onCancelClick, onAddClick } = props;

  return (
    <Modal className="modal add-vendor-code-modal" open={open} footer={false} closeIcon={false}>
      <Spin spinning={loading}>
        <div className="add-vendor-code-modal__body">
          <div className="modal add-vendor-code-modal__title">Внутренний артикул клиента</div>

          <Input.TextArea rows={4} value={vendorCode} onChange={(e) => onVendorCodeChange(e.target.value)} />
        </div>

        <div className="add-vendor-code-modal__footer">
          <Button className="btn btn-default" onClick={onCancelClick}>
            Отмена
          </Button>

          <Button className="btn btn-primary" onClick={onAddClick} disabled={!vendorCode.trim().length}>
            Добавить
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};
