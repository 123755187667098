import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Popover, Switch } from 'antd';
import { connect } from 'react-redux';
import { ERequestsNavigationTab } from 'common/const/enum';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as SettingsIcon } from 'app/assets/images/redesign/settings.svg';
import { NeedListNavigation } from 'entities/Need/components/NeedListNavigation';
import { IncomingRequestsForManager } from 'entities/Requests/components/IncomingRequestsForManager';
import { requestsForManagerNavigationTabs } from 'entities/Requests/Requests.const';
import { SupplyListForManager } from 'entities/Supply/components/SupplyListForManager';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = ({ user, setUserAutoSupplyApprove, updateUserAutoSupplyApprove }) => {
  const { tab } = useParams();

  const header = (
    <>
      <div className="need-list__header">
        <Popover
          overlayStyle={{ width: 360 }}
          overlayClassName="redesign"
          placement="bottomRight"
          content={
            <div className="need-list__settings-popover">
              <span className="text-h4" style={{ marginBottom: 6 }}>
                Автоматическое подтверждение поставок
              </span>

              <span className="text-tag color-dark-grey">
                Когда включена эта функция, все поставки, кроме тех, где поставщик изменил цену, подтверждаются автоматически.
              </span>

              <div className="divider" style={{ margin: '12px 0' }} />

              <div className="need-list__settings-popover-footer">
                <span className="text-controls">Подтверждать автоматически</span>

                <Switch
                  checked={user?.autoSupplyApprove}
                  onChange={(checked) => {
                    setUserAutoSupplyApprove(checked);
                    updateUserAutoSupplyApprove({ autoSupplyApprove: checked });
                  }}
                />
              </div>
            </div>
          }
          trigger="click"
        >
          <Button className="button-circle secondary" icon={<SettingsIcon />} />
        </Popover>
      </div>

      <div className="text-body color-dark-grey need-list__subtitle">Заявки на закупку</div>
    </>
  );

  const navigation = <NeedListNavigation items={requestsForManagerNavigationTabs} />;

  if (tab === ERequestsNavigationTab.SupplyList) {
    return <SupplyListForManager header={header} navigation={navigation} />;
  }

  return <IncomingRequestsForManager header={header} navigation={navigation} />;
};

const mapState = (state: RootState) => ({
  user: state.userState.data,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setUserAutoSupplyApprove: dispatch.userState.setUserAutoSupplyApprove,
  updateUserAutoSupplyApprove: dispatch.userState.updateUserAutoSupplyApprove,
});

export const RequestsForManager = connect(mapState, mapDispatch)(Component);
