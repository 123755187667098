import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import {
  ILegal,
  ILegalListParams,
  ILegalListState,
  ILegalCreatePayload,
  ILegalDeletePayload,
  ILegalState,
  ILegalUpdatePayload,
} from 'entities/LegalEntity/LegalEntity.models';
import { legalTransport } from 'entities/LegalEntity/LegalEntity.transport';

export const legalListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as ILegalListState,
  reducers: {
    setLegalList: (state, payload: ILegal[]) => ({ ...state, data: payload }),
    setLegalListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    addLegal: (state, payload: ILegal) => ({ ...state, data: [...state.data, payload] }),
    updateLegal: (state, payload: ILegal) => ({
      ...state,
      data: state.data.map((legal) => (legal.id === payload.id ? payload : legal)),
    }),
    deleteLegal: (state, payload: number) => ({
      ...state,
      data: state.data.filter((legal) => legal.id !== payload),
    }),
  },
  effects: (dispatch) => ({
    async getLegalList(params: ILegalListParams) {
      dispatch.legalListState.setLegalListLoading(true);

      try {
        const response = await legalTransport.getLegalList(params);
        dispatch.legalListState.setLegalList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.legalListState.setLegalListLoading(false);
      }
    },
  }),
});

export const legalState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
    error: null,
  } as ILegalState,
  reducers: {
    setLegal: (state, payload: ILegal | null) => ({ ...state, data: payload }),
    setLegalLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    setLegalError: (state, payload: string | null) => ({ ...state, error: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getLegalById(id: number) {
      dispatch.legalState.setLegalLoading(true);

      try {
        const response = await legalTransport.getLegalById(id);
        dispatch.legalState.setLegal(response);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.legalState.setLegalLoading(false);
      }
    },
    async createLegal(payload: ILegalCreatePayload) {
      dispatch.legalState.setLegalLoading(true);

      try {
        const response = await legalTransport.createLegal(payload);
        dispatch.legalListState.addLegal(response);
        dispatch.legalState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error, dispatch.legalState.setLegalError);
      } finally {
        dispatch.legalState.setLegalLoading(false);
      }
    },
    async updateLegal(payload: ILegalUpdatePayload) {
      dispatch.legalState.setLegalLoading(true);

      try {
        const response = await legalTransport.updateLegal(payload);
        dispatch.legalListState.updateLegal(response);
        dispatch.legalState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error, dispatch.legalState.setLegalError);
      } finally {
        dispatch.legalState.setLegalLoading(false);
      }
    },
    async deleteLegal(payload: ILegalDeletePayload) {
      dispatch.legalState.setLegalLoading(true);

      try {
        const response = await legalTransport.deleteLegal(payload);
        dispatch.legalListState.deleteLegal(response.id);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.legalState.setLegalLoading(false);
      }
    },
  }),
});
