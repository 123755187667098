import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { EDateFormat, EQrfpStatus, EQrfpStatusLabel } from 'common/const/enum';
import { propertyListToPresetList, renderPresetList } from 'common/helpers/preset.helper';
import { IQrfp } from 'entities/Qrfp/Qrfp.models';

export const renderQrfpListRecords = () => {
  return [
    {
      dataIndex: 'createdAt',
      render: (text) => dayjs(text).format(EDateFormat.FullDateSlashSeparator),
      width: '110px',
    },
    {
      dataIndex: 'properties',
      render: (_, record) => {
        const presetList = propertyListToPresetList(record.properties);

        return <div className="preset__container">{renderPresetList(presetList)}</div>;
      },
    },
    {
      dataIndex: 'status',
      render: (text: EQrfpStatus) => {
        return (
          <div className="qrfp-list__table-item-status-wrapper">
            <div className={`qrfp-list__table-item-status ${text}`}>
              <span>{EQrfpStatusLabel[text]}</span>
            </div>
          </div>
        );
      },
    },
  ] as ColumnsType<IQrfp>;
};

export const grfpToPosition = (qrfp: IQrfp | null) => {
  if (!qrfp) {
    return undefined;
  }

  return {
    positionId: qrfp.id,
    categoryId: qrfp.categoryId,
    categoryName: qrfp.categoryName,
    properties: qrfp.properties,
  };
};
