import React, { ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Spin, Table } from 'antd';
import { useNeedContext } from 'common/hooks/useNeedContext';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { EDateFormat, ENeedCardMenuItem, ENeedStatus, EOrderField } from 'common/const/enum';
import { groupList } from 'common/helpers/data.helper';
import { DEFAULT_LIST_LIMIT, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { INeedListPayload } from 'entities/Need/Need.models';
import { getNeedFormattedDate, renderNeedListRecords } from 'entities/Need/Need.helper';
import { NeedCard } from 'entities/Need/components/NeedCard';
import { NeedListEmpty } from 'entities/Need/components/NeedListEmpty';
import { SupplyCard } from 'entities/Supply/components/SupplyCard';
import { SupplyCorrectionRequestDrawer } from 'entities/Supply/components/SupplyCorrectionRequestDrawer';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const CancelledNeedListForUserComponent: React.FC<AllType> = (props) => {
  const { header, navigation, needList, needListLoading, needListCount, auth, getNeedList, getNeedListPart, getNeedById } = props;

  const [payload, setPayload] = useState<INeedListPayload>({
    userId: auth?.access.userId,
    statuses: [ENeedStatus.Cancelled],
    orderField: EOrderField.UpdatedAt,
    limit: DEFAULT_LIST_LIMIT,
    offset: DEFAULT_LIST_OFFSET,
  });
  const { page, setPage, setNeedCardCurrentMenuTab, setOpenNeedCard, fetchLoading, setFetchLoading } = useNeedContext();

  const groupedNeedList = groupList.byUpdatedAt(needList);

  const onSearchClick = () => {
    setPage(DEFAULT_PAGINATION_PAGE);
    getNeedList(payload);
  };

  useEffect(() => {
    const fetch = async () => {
      setFetchLoading(true);
      setPage(DEFAULT_PAGINATION_PAGE);
      await getNeedList(payload);
      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <>
      <InfiniteScrollContainer
        canLoad={!needListLoading && needList.length < needListCount}
        page={page}
        scrollToTopTrigger={[payload]}
        onPageChange={setPage}
        callback={async (page) => {
          await getNeedListPart({ ...payload, offset: DEFAULT_LIST_LIMIT * page });
        }}
      >
        {header}

        {navigation}

        <div className="need-list__filters">
          <Input
            style={{ flex: 1 }}
            placeholder="Поиск"
            value={payload.search}
            onChange={(e) => setPayload((prev) => ({ ...prev, search: e.target.value }))}
          />

          <Button className="button-circle primary" icon={<SearchIcon />} onClick={onSearchClick} />
        </div>

        <Spin wrapperClassName="need-list__spin" spinning={fetchLoading || needListLoading} indicator={<SpinIndicator />}>
          {groupedNeedList.map((item) => {
            return (
              <div key={item.updatedAt} className="need-list__group">
                <span className="need-list__group-date">{getNeedFormattedDate(item.updatedAt, EDateFormat.Group)}</span>

                <Table
                  className="table-hover need-list__table"
                  dataSource={item.dataSource.map((need) => ({ ...need, key: need.id }))}
                  columns={renderNeedListRecords()}
                  onRow={({ id }) => ({
                    onClick: () => {
                      getNeedById({
                        id,
                        onSuccess: () => {
                          setNeedCardCurrentMenuTab(ENeedCardMenuItem.Info);
                          setOpenNeedCard(true);
                        },
                      });
                    },
                  })}
                  showHeader={false}
                  pagination={false}
                />
              </div>
            );
          })}

          <NeedListEmpty open={!fetchLoading && !groupedNeedList.length} />
        </Spin>
      </InfiniteScrollContainer>

      <NeedCard asUser />

      <SupplyCard nested asUser />

      <SupplyCorrectionRequestDrawer />
    </>
  );
};

const mapState = (state: RootState) => ({
  needList: state.needListState.data,
  needListLoading: state.needListState.loading,
  needListCount: state.needListState.count,
  auth: state.authState.data,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getNeedList: dispatch.needListState.getNeedList,
  getNeedListPart: dispatch.needListState.getNeedListPart,
  getNeedById: dispatch.needState.getNeedById,
});

export const CancelledNeedListForUser = connect(mapState, mapDispatch)(CancelledNeedListForUserComponent);
