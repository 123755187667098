import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Modal, Spin } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { ReactComponent as EditIcon } from 'app/assets/images/edit.svg';
import { RootDispatch, RootState } from 'app/store';

interface IComponentProps {
  goodsId: number;
  code?: string;
  updateCode?: (id: number, value: string) => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

export const GoodsCardVendorCodeComponent: FC<AllType> = (props) => {
  const { goodsId, code, authState, goodsState, updateGoods, updateCode } = props;

  const { data: auth } = authState;
  const { loading: goodsLoading } = goodsState;

  const [open, setOpen] = useState<boolean>(false);
  const [codeValue, setCodeValue] = useState<string>('');

  const isSeller = auth?.access.isSeller;

  const toggleOpenModal = () => {
    setOpen((prev) => {
      if (!prev && code) {
        setCodeValue(code);
      }

      return !prev;
    });
  };

  const onSaveVendorCodeClick = () => {
    updateGoods({
      id: goodsId,
      vendorCode: codeValue,
      onSuccess: () => {
        updateCode?.(goodsId, codeValue);
        setCodeValue('');
        toggleOpenModal();
      },
    });
  };

  return (
    <div className="goods-card__vendor-code">
      <div className="goods-card__vendor-code-title">Артикул клиента:</div>

      {isSeller ? (
        <span>{code ? code : <ExclamationCircleFilled />}</span>
      ) : (
        <Button className="btn btn-icon" onClick={toggleOpenModal}>
          {code ? code : <ExclamationCircleFilled />}

          <EditIcon className="goods-card__vendor-code-edit-icon" />
        </Button>
      )}

      <Modal open={open} className="modal-gray" footer={false} closeIcon={false} width={360}>
        <Spin spinning={goodsLoading}>
          <div className="modal-gray__body">
            <div className="goods-card__vendor-code-modal-title">Внутренний артикул клиента</div>

            <Input.TextArea
              placeholder="Введите внутренний артикул клиента"
              value={codeValue}
              onChange={(e) => setCodeValue(e.target.value)}
            />
          </div>

          <div className="modal-gray__footer">
            <Button className="btn btn-default" onClick={toggleOpenModal}>
              Отмена
            </Button>

            <Button className="btn btn-primary" onClick={onSaveVendorCodeClick}>
              Сохранить
            </Button>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

const mapState = (state: RootState) => ({
  authState: state.authState,
  goodsState: state.goodsState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  updateGoods: dispatch.goodsState.updateGoods,
});

export const GoodsCardVendorCode = connect(mapState, mapDispatch)(GoodsCardVendorCodeComponent);
