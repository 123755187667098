import React, { FC } from 'react';
import { Button, Input, Modal, Spin } from 'antd';

interface IComponentProps {
  open: boolean;
  loading: boolean;
  error?: string | null;
  feedUrl: string;
  changeFeedUrl: (value: string) => void;
  onCancelClick: () => void;
  onConfirmClick: () => void;
}

export const AddFeedModal: FC<IComponentProps> = (props) => {
  const { open, loading, error, feedUrl, changeFeedUrl, onCancelClick, onConfirmClick } = props;

  return (
    <Modal className="modal-gray" open={open} footer={false} closeIcon={false} width={360}>
      <Spin spinning={loading}>
        <div className="modal-gray__body">
          <div className="modal-gray__title">Добавить фид продавцу?</div>

          <Input.TextArea rows={4} placeholder="Ссылка на фид" value={feedUrl} onChange={(e) => changeFeedUrl(e.target.value)} />

          {!!error && <div className="modal-gray__error">{error}</div>}
        </div>

        <div className="modal-gray__footer">
          <Button className="btn btn-default" onClick={onCancelClick}>
            Позже
          </Button>

          <Button className="btn btn-primary" onClick={onConfirmClick} disabled={!feedUrl.length}>
            Добавить
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};
