import React, { FC } from 'react';
import { Button } from 'antd';
import { ThreeDotMenu } from 'common/components/ThreeDotMenu';
import { ReactComponent as SuccessIcon } from 'app/assets/images/success.svg';
import { ReactComponent as ErrorIcon } from 'app/assets/images/error.svg';
import { IDeliveryCity } from 'entities/Delivery/Delivery.models';

interface IComponentProps {
  deliveryCity: IDeliveryCity;
  onAddShippingStockClick: (city: string) => void;
}

export const DeliveryCity: FC<IComponentProps> = ({ deliveryCity, onAddShippingStockClick }) => {
  return (
    <div className="delivery-city">
      <div className="delivery-city__status">{!deliveryCity.stocks.length ? <ErrorIcon /> : <SuccessIcon />}</div>

      <div className="delivery-city__name">
        <span className="delivery-city__name-prefix">в </span>

        <span>{deliveryCity.city}</span>
      </div>

      <ThreeDotMenu
        items={[
          {
            key: 'edit',
            label: <Button onClick={() => onAddShippingStockClick(deliveryCity.city)}>Добавить склад отгрузки</Button>,
            className: 'edit',
          },
        ]}
      />
    </div>
  );
};
