import React, { FC } from 'react';
import dayjs from 'dayjs';
import { ENeedHistoryItemType } from 'common/const/enum';
import { ReactComponent as DeliveryIcon } from 'app/assets/images/redesign/delivery.svg';
import { ReactComponent as AffiliateIcon } from 'app/assets/images/redesign/affiliate.svg';
import { ReactComponent as UserIcon } from 'app/assets/images/redesign/user.svg';
import { INeedHistoryItem } from 'entities/Need/Need.models';
import { getNeedCardHistoryItemDescription } from 'entities/Need/Need.helper';

interface IComponentProps {
  historyList: INeedHistoryItem[];
}

export const NeedCardHistory: FC<IComponentProps> = ({ historyList }) => {
  return (
    <>
      {historyList.map((historyItem) => {
        return (
          <div key={historyItem.id} className="need-card-history-item">
            <div className="need-card-history-item__icon-container">
              {historyItem.type === ENeedHistoryItemType.CreateSupplyNeed && (
                <div className="need-card-history-item__icon user">
                  <UserIcon />
                </div>
              )}

              {(historyItem.type === ENeedHistoryItemType.ChangeSupplyNeedStatus ||
                historyItem.type === ENeedHistoryItemType.CreateSupplyCorrection) && (
                <div className="need-card-history-item__icon affiliate">
                  <AffiliateIcon />
                </div>
              )}

              {(historyItem.type === ENeedHistoryItemType.CreateSupply ||
                historyItem.type === ENeedHistoryItemType.ChangeSupplyStatus ||
                historyItem.type === ENeedHistoryItemType.ChangeSupplyPrice) && (
                <div className="need-card-history-item__icon delivery">
                  <DeliveryIcon />
                </div>
              )}

              <div className="need-card-history-item__line" />
            </div>

            <div className="need-card-history-item__container">
              <div className="text-body" style={{ marginBottom: 8 }}>
                {getNeedCardHistoryItemDescription(historyItem)}
              </div>

              <span className="text-tag color-dark-grey">
                {dayjs(historyItem.createdAt).locale('ru').format('DD MMMM YYYY · HH:mm:ss')}
              </span>
            </div>
          </div>
        );
      })}
    </>
  );
};
