import React, { FC } from 'react';
import { Button, Drawer } from 'antd';

interface IComponentProps {
  open: boolean;
  subtitle: string;
  content: string;
  btnTitle?: string;
  onClose: () => void;
  onConfirm?: () => void;
}

export const SuccessDrawer: FC<IComponentProps> = ({ open, subtitle, content, btnTitle, onClose, onConfirm }) => {
  return (
    <Drawer rootClassName="redesign success-drawer" open={open} onClose={onClose} width={720} destroyOnClose>
      <div className="success-drawer__container">
        <span className="text-h3 color-white-40 success-drawer__subtitle">{subtitle}</span>

        <span className="text-h1 color-white">{content}</span>
      </div>

      {onConfirm && (
        <div className="success-drawer__footer">
          <Button className="button-l green" onClick={onConfirm}>
            {btnTitle}
          </Button>
        </div>
      )}
    </Drawer>
  );
};
