import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ERoute, EStorageKey } from 'common/const/enum';
import { getStorageItem } from 'common/helpers/axios.helper';
import { useContentLoader } from 'common/hooks/useContentLoader';

interface IComponentProps {
  count: number;
}

export const BasketLink: React.FC<IComponentProps> = ({ count }) => {
  const [xBasketId, setXBasketId] = useState<string | null>(null);

  const link = xBasketId ? `/active-basket-list/${xBasketId}` : ERoute.Basket;

  useContentLoader(async () => {
    const xBasketId = await getStorageItem<string>(EStorageKey.XBasketId);
    setXBasketId(xBasketId);
  });

  return (
    <Link className="basket-link" to={link}>
      <div className="basket-link__container">
        <div className="basket-icon" />

        <span>
          Товаров в корзине: <span className="basket-link_count">{count}</span>
        </span>
      </div>

      {xBasketId && <span className="basket-link__number">№ {xBasketId}</span>}
    </Link>
  );
};
