import React, { FC } from 'react';
import { Button, Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { ReactComponent as CopyIcon } from 'app/assets/images/redesign/copy.svg';
import { ReactComponent as OptionsIcon } from 'app/assets/images/redesign/options.svg';

interface IComponentProps {
  id?: number;
  isSeller?: boolean;
  isCurrentBasket: boolean;
  clearDisabled?: boolean;
  onDelete?: () => void;
  onLink?: () => void;
  onUnlink?: () => void;
}

export const BasketMoreButton: FC<IComponentProps> = (props) => {
  const { id, isSeller, isCurrentBasket, clearDisabled, onDelete, onLink, onUnlink } = props;

  const getDropdownItems = () => {
    const items: MenuProps['items'] = [
      {
        key: 'copy',
        label: (
          <div className="basket-more-button__menu-item">
            <span className="text-tag color-dark-grey">Код корзины</span>

            <div className="basket-more-button__menu-item-container">
              <span className="text-h4-item-name">{id}</span>

              <CopyIcon />
            </div>
          </div>
        ),
        onClick: onCopyClick,
      },
      {
        type: 'divider',
      },
    ];

    if (isSeller) {
      if (isCurrentBasket) {
        items.push({
          key: 'unlink',
          label: <span className="text-lists color-red">Отвязать корзину</span>,
          onClick: onUnlink,
        });
      } else {
        items.push({
          key: 'link',
          label: <span className="text-lists">Привязать корзину</span>,
          onClick: onLink,
        });
      }
    } else {
      items.push({
        key: 'clear',
        label: <span className="text-lists color-red">Очистить корзину</span>,
        onClick: onDelete,
        disabled: clearDisabled,
      });
    }

    return items;
  };

  const onCopyClick = () => {
    if (id) {
      navigator.clipboard.writeText(id.toString());
    }
  };

  return (
    <Dropdown
      overlayClassName="redesign basket-more-button"
      menu={{ items: getDropdownItems() }}
      trigger={['click']}
      placement="bottomRight"
      dropdownRender={(menus) => {
        return (
          <div className="custom-dropdown" style={{ width: 280 }}>
            {menus}
          </div>
        );
      }}
    >
      <Button className="button-circle secondary" icon={<OptionsIcon />} />
    </Dropdown>
  );
};
