import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { DEFAULT_FULL_LIST_LIMIT } from 'common/config';
import { useCatalogContext } from 'common/hooks/useCatalogContext';
import { RootDispatch, RootState } from 'app/store';
import { CatalogCategoryList } from 'entities/Catalog/components/CatalogCategoryList';
import { CatalogEmpty } from 'entities/Catalog/components/CatalogEmpty';

interface IComponentProps {
  catalogId: number;
  catalogName?: number;
  catalogListLoading: boolean;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const CatalogMainPageComponent: FC<AllType> = (props) => {
  const { catalogId, catalogName, catalogListLoading, categoryList, setCategoryList, getCategoryList } = props;

  const { fetchLoading, setFetchLoading } = useCatalogContext();

  const categoryListLoading = catalogListLoading || fetchLoading;
  const showEmptyState = !catalogListLoading && !fetchLoading && !categoryList.length;

  useEffect(() => {
    const fetch = async () => {
      setFetchLoading(true);
      await getCategoryList({ catalogId, withoutParents: true, limit: DEFAULT_FULL_LIST_LIMIT });
      setFetchLoading(false);
    };

    fetch();
  }, [catalogId]);

  useEffect(() => {
    return () => {
      setFetchLoading(true);
      setCategoryList([]);
    };
  }, []);

  return (
    <div className="infinite-scroll-container">
      <div className="infinite-scroll-container__scroll-container catalog__scroll-container">
        {catalogName && <span className="text-h1 catalog__title">{catalogName}</span>}

        <CatalogCategoryList loading={categoryListLoading} collapsible={false} categoryList={categoryList} />

        <CatalogEmpty
          open={showEmptyState}
          title="В этом каталоге товары отсутствуют."
          description="Они появятся здесь после добавления поставщиком."
        />
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  categoryList: state.categoryListState.data,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setCategoryList: dispatch.categoryListState.setCategoryList,
  getCategoryList: dispatch.categoryListState.getCategoryList,
});

export const CatalogMainPage = connect(mapState, mapDispatch)(CatalogMainPageComponent);
