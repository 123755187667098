import { useLocation, useNavigate } from 'react-router-dom';
import { ERoute, ESearchParams } from 'common/const/enum';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { ICategoryListParams, ICategory, ICategoryDetailed } from 'entities/Categories/Categories.models';
import { IGoodsListPayload, IGoods } from 'entities/Goods/Goods.models';

export const useCategoryClick = (
  getGoodsForNavigation: (payload: IGoodsListPayload) => Promise<IGoods[] | null>,
  getCategoryList: (payload: ICategoryListParams) => Promise<ICategory[] | null>,
  getCategoryById?: (id: number) => Promise<ICategoryDetailed | null>,
  catalogId?: number,
  initialLoadForCategory?: (id: number, searchParams: URLSearchParams) => Promise<void>,
) => {
  const { setSearchParam, setParams } = useSearchParamsHook();
  const navigate = useNavigate();
  const location = useLocation();

  const isListingPage = location.pathname === ERoute.Listing;
  const isBasketPage = location.pathname === ERoute.Basket;

  const changeCategory = async (id: number) => {
    if (isListingPage) {
      const searchParams = new URLSearchParams(`${ESearchParams.CategoryId}=${id}`);
      setParams(searchParams, { replace: true });
      await initialLoadForCategory?.(id, searchParams);
      return;
    }

    navigate(`${ERoute.Listing}?categoryId=${id}&isAvailableForPurchase=true`);
  };

  const onCategoryClick = async (id: number) => {
    const goods = await getGoodsForNavigation({ categoryId: id });

    if (goods) {
      if (goods.length) {
        await changeCategory(id);
        return;
      }

      const categoryList = await getCategoryList({ parentId: id, limit: 0 });

      if (categoryList) {
        if (!categoryList.length) {
          await changeCategory(id);
          return;
        }

        if (isListingPage) {
          navigate(`${ERoute.Shop}?catalogId=${catalogId}&categoryId=${id}`);
          return;
        }

        if (isBasketPage) {
          navigate(`${ERoute.Shop}?catalogId=${catalogId}`);
          return;
        }

        await getCategoryById?.(id);
        setSearchParam(ESearchParams.CategoryId, id);
      }
    }
  };

  return { onCategoryClick };
};
