import axios from 'axios';
import {
  IPropertyCollectionDto,
  IPropertyListParams,
  IPropertyCreatePayload,
  IPropertyDeletePayload,
  IProperty,
  IPropertyUpdatePayload,
} from 'entities/Property/Property.models';

const basePath = 'property';

export const propertyTransport = {
  getPropertyList: (params: IPropertyListParams): Promise<IPropertyCollectionDto> => axios.get(`${basePath}`, { params }),
  getPropertyListByCategoryId: (categoryId: number): Promise<IPropertyCollectionDto> =>
    axios.get(`${basePath}/by-category/${categoryId}`),
  createProperty: (payload: IPropertyCreatePayload): Promise<IProperty> => axios.post(`${basePath}`, payload),
  updateProperty: (payload: IPropertyUpdatePayload): Promise<IProperty> => axios.patch(`${basePath}`, payload),
  deleteProperty: (payload: IPropertyDeletePayload): Promise<IProperty> => axios.delete(`${basePath}`, { data: payload }),
};
