import React from 'react';
import { Form, InputNumber } from 'antd';
import { RuleObject } from 'antd/es/form';
import { IFormItemRule } from 'common/models';
import { rules as validationRules } from 'common/helpers/form.helper';

interface IComponentProps {
  name: string | number;
  label?: string;
  suffix?: string;
  disabled?: boolean;
  rules?: IFormItemRule[];
  className?: string;
}

export const FormInputRange: React.FC<IComponentProps> = ({ name, label, suffix, disabled, rules = [], className }) => {
  return (
    <Form.List name={name} initialValue={[{ from: undefined, to: undefined }]}>
      {(fields) => {
        return (
          <>
            {fields.map((field) => {
              return (
                <div key={field.key} className={`form-input-range ${className || ''}`}>
                  <div className="form-input-range__label">{label}</div>

                  <div className="form-input-range__container">
                    <Form.Item
                      name={[field.key, 'from']}
                      dependencies={[name, field.key, 'to']}
                      rules={[
                        ...rules,
                        ({ getFieldValue, setFields }): RuleObject => validationRules.rangeFrom(name, getFieldValue, setFields),
                      ]}
                    >
                      <InputNumber
                        className="form-input-range__from"
                        placeholder="От"
                        controls={false}
                        suffix="-"
                        disabled={disabled}
                      />
                    </Form.Item>

                    <Form.Item
                      name={[field.key, 'to']}
                      dependencies={[name, field.key, 'from']}
                      rules={[
                        ...rules,
                        ({ getFieldValue, setFields }) => validationRules.rangeTo(name, getFieldValue, setFields),
                      ]}
                    >
                      <InputNumber
                        className={suffix ? 'form-input-range__to-suffix' : 'form-input-range__to'}
                        placeholder="До"
                        controls={false}
                        suffix={suffix}
                        disabled={disabled}
                      />
                    </Form.Item>
                  </div>
                </div>
              );
            })}
          </>
        );
      }}
    </Form.List>
  );
};
