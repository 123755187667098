import axios from 'axios';
import {
  ILegal,
  ILegalCollectionDto,
  ILegalListParams,
  ILegalCreatePayload,
  ILegalDeletePayload,
  ILegalUpdatePayload,
} from 'entities/LegalEntity/LegalEntity.models';

const basePath = 'legal';

export const legalTransport = {
  getLegalList: (params: ILegalListParams): Promise<ILegalCollectionDto> => axios.get(`${basePath}`, { params }),
  getLegalById: (id: number): Promise<ILegal> => axios.get(`${basePath}/${id}`),
  createLegal: (payload: ILegalCreatePayload): Promise<ILegal> => axios.post(`${basePath}`, payload),
  updateLegal: (payload: ILegalUpdatePayload): Promise<ILegal> => axios.patch(`${basePath}`, payload),
  deleteLegal: (payload: ILegalDeletePayload): Promise<ILegal> => axios.delete(`${basePath}`, { data: payload }),
};
