import { ERequestsMenuTab, ERequestsNavigationTab, ERoute } from 'common/const/enum';

export const requestsForUserNavigationTabs = [
  {
    label: 'Все заявки',
    path: ERoute.RequestsForUser,
  },
  {
    label: 'Отмененные заявки',
    path: `${ERoute.RequestsForUser}/${ERequestsNavigationTab.Cancelled}`,
  },
  {
    label: 'Поставки',
    path: `${ERoute.RequestsForUser}/${ERequestsNavigationTab.SupplyList}`,
  },
];

export const requestsForManagerNavigationTabs = [
  {
    label: 'Входящие заявки',
    path: ERoute.RequestsForManager,
  },
  {
    label: 'Поставки',
    path: `${ERoute.RequestsForManager}/${ERequestsNavigationTab.SupplyList}`,
  },
];

export const incomingRequestsForManagerMenuTabs = [
  {
    label: 'Новые заявки',
    key: ERequestsMenuTab.Created,
  },
  {
    label: 'Заявки в работе',
    key: ERequestsMenuTab.Pending,
  },
];

export const requestsForSellerNavigationTabs = [
  {
    label: 'Входящие заказы',
    path: ERoute.RequestsForSeller,
  },
  {
    label: 'Поставки',
    path: `${ERoute.RequestsForSeller}/${ERequestsNavigationTab.SupplyList}`,
  },
];

export const incomingRequestsForSellerMenuTabs = [
  {
    label: 'Новые заказы',
    key: ERequestsMenuTab.Created,
  },
  {
    label: 'Переданы в учётную систему',
    key: ERequestsMenuTab.Processing,
  },
  {
    label: 'На рассмотрении у заявителя',
    key: ERequestsMenuTab.Pending,
  },
];
