import React, { FC } from 'react';
import { Button, Modal, Select, Spin } from 'antd';
import { IOption } from 'common/models';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { deliveryInDaysOptions } from 'entities/Delivery/Delivery.const';

interface IComponentProps {
  open: boolean;
  loading: boolean;
  stockOptions: IOption[];
  stockId: number | null;
  deliveryInDays: number | null;
  onStockIdChange: (value: number) => void;
  onDeliveryInDaysChange: (value: number) => void;
  onCancelClick: () => void;
  onAddClick: () => void;
}

export const AddShippingStockModal: FC<IComponentProps> = (props) => {
  const {
    open,
    loading,
    stockOptions,
    stockId,
    deliveryInDays,
    onStockIdChange,
    onDeliveryInDaysChange,
    onCancelClick,
    onAddClick,
  } = props;

  return (
    <Modal className="modal-gray" open={open} footer={false} closeIcon={false} width={360}>
      <Spin spinning={loading}>
        <div className="modal-gray__body">
          <div style={{ marginBottom: 10 }}>
            <div className="modal-gray__title">Склад отгрузки</div>

            <Select
              options={stockOptions}
              value={stockId}
              onChange={onStockIdChange}
              placeholder="Выберите склад отгрузки"
              suffixIcon={<ChevronIcon />}
            />
          </div>

          <div>
            <div className="modal-gray__title">Срок доставки</div>

            <Select
              options={deliveryInDaysOptions}
              value={deliveryInDays}
              onChange={onDeliveryInDaysChange}
              placeholder="Выберите срок доставки"
              suffixIcon={<ChevronIcon />}
            />
          </div>
        </div>

        <div className="modal-gray__footer">
          <Button className="btn btn-default" onClick={onCancelClick}>
            Отмена
          </Button>

          <Button className="btn btn-primary" onClick={onAddClick} disabled={!stockId || !deliveryInDays}>
            Добавить
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};
