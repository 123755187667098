import { useEffect } from 'react';
import { appendJivoScriptToDocument, createJivoScriptElement, destroyJivoScriptFromDocument } from 'common/helpers/jivo.helper';
import { IToken } from 'entities/Auth/Auth.models';

export const useJivo = (auth: IToken | null) => {
  useEffect(() => {
    if (auth) {
      const jivoScriptElement = createJivoScriptElement();

      appendJivoScriptToDocument(jivoScriptElement);
    }

    return () => {
      destroyJivoScriptFromDocument();
    };
  }, [auth]);
};
