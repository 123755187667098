import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Input, Menu, Select } from 'antd';
import { connect } from 'react-redux';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { useSupplyContext } from 'common/hooks/useSupplyContext';
import { EOrderDirection, EOrderField, ESearchParams, ESupplyListMenuTab } from 'common/const/enum';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { DEFAULT_EMPTY_VALUE, DEFAULT_LIST_LIMIT, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { getNeedListFilterOptions } from 'entities/Need/Need.helper';
import { ActiveSupplyListForSeller } from 'entities/Supply/components/ActiveSupplyListForSeller';
import { CompletedSupplyListForSeller } from 'entities/Supply/components/CompletedSupplyListForSeller';
import { ISupplyListPayload } from 'entities/Supply/Supply.models';
import { getSupplyListForSellerStatuses } from 'entities/Supply/Supply.helper';
import { supplyListForSellerMenuTabs } from 'entities/Supply/Supply.const';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const SupplyListForSellerComponent: FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    // state
    buyerList,
    subdivisionList,
    addressList,
    userList,
    supplyList,
    supplyListLoading,
    supplyListCount,
    // dispatch
    getBuyerList,
    getSupplySubdivisionListForSeller,
    getSupplyAddressListForSeller,
    getSupplyUserListForSeller,
    setSupplyList,
    getSupplyList,
    getSupplyListPart,
  } = props;

  const { page, setPage, setFetchLoading, setSelectedSupplyList } = useSupplyContext();
  const { getSearchParam, setSearchParam } = useSearchParamsHook();

  const buyerOptions = getNeedListFilterOptions.buyer(buyerList);
  const subdivisionOptions = getNeedListFilterOptions.subdivision(subdivisionList);
  const addressOptions = getNeedListFilterOptions.address(addressList);
  const userOptions = getNeedListFilterOptions.user(userList);
  const tab = getSearchParam(ESearchParams.Tab);

  const [payload, setPayload] = useState<ISupplyListPayload>({
    statuses: getSupplyListForSellerStatuses(tab),
    orderField: tab !== ESupplyListMenuTab.Completed ? EOrderField.DeliveryDate : EOrderField.UpdatedAt,
    orderDirection: tab !== ESupplyListMenuTab.Completed ? EOrderDirection.ASC : EOrderDirection.DESC,
    limit: DEFAULT_LIST_LIMIT,
    offset: DEFAULT_LIST_OFFSET,
  });

  const onCurrentMenuTabChange = (key: ESupplyListMenuTab) => {
    const newPayload = {
      ...payload,
      accountId: undefined,
      subdivisionIds: undefined,
      addressId: undefined,
      userId: undefined,
      isCancelledBySeller: key === ESupplyListMenuTab.Completed ? false : undefined,
      statuses: getSupplyListForSellerStatuses(key),
      orderField: key !== ESupplyListMenuTab.Completed ? EOrderField.DeliveryDate : EOrderField.UpdatedAt,
      orderDirection: key !== ESupplyListMenuTab.Completed ? EOrderDirection.ASC : EOrderDirection.DESC,
      limit: DEFAULT_LIST_LIMIT,
      offset: DEFAULT_LIST_OFFSET,
    };

    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedSupplyList([]);
    setSupplyList([]);
    setSearchParam(ESearchParams.Tab, key);
    setPayload(newPayload);
    getSupplyList(newPayload);
  };

  const renderContent = () => {
    if (tab === ESupplyListMenuTab.Completed) {
      return (
        <CompletedSupplyListForSeller
          list={supplyList}
          loading={supplyListLoading}
          count={supplyListCount}
          payload={payload}
          onPayloadChange={setPayload}
        />
      );
    }

    return (
      <ActiveSupplyListForSeller
        list={supplyList}
        loading={supplyListLoading}
        count={supplyListCount}
        payload={payload}
        onPayloadChange={setPayload}
      />
    );
  };

  const onBuyerChange = async (value: number) => {
    const newPayload = {
      ...payload,
      accountId: value === DEFAULT_EMPTY_VALUE ? undefined : value,
      subdivisionIds: undefined,
      addressId: undefined,
      userId: undefined,
    };

    setPayload(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedSupplyList([]);

    if (value !== DEFAULT_EMPTY_VALUE) {
      await getSupplySubdivisionListForSeller({ buyerId: value });
      await getSupplyAddressListForSeller({ buyerId: value });
      await getSupplyUserListForSeller({ buyerId: value });
    }

    await getSupplyList(newPayload);
  };

  const onSubdivisionChange = async (value: number) => {
    const newPayload = { ...payload, subdivisionIds: value === DEFAULT_EMPTY_VALUE ? undefined : [value] };

    setPayload(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedSupplyList([]);
    await getSupplyList(newPayload);
  };

  const onAddressChange = (value: number) => {
    const newPayload = { ...payload, addressId: value === DEFAULT_EMPTY_VALUE ? undefined : value };

    setPayload(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedSupplyList([]);
    getSupplyList(newPayload);
  };

  const onUserChange = (value: number) => {
    const newPayload = { ...payload, userId: value === DEFAULT_EMPTY_VALUE ? undefined : value };

    setPayload(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedSupplyList([]);
    getSupplyList(newPayload);
  };

  const onSearchClick = () => {
    if (payload) {
      setPage(DEFAULT_PAGINATION_PAGE);
      setSelectedSupplyList([]);
      getSupplyList(payload);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      setFetchLoading(true);
      setPage(DEFAULT_PAGINATION_PAGE);
      setSelectedSupplyList([]);
      setSupplyList([]);
      await getBuyerList();
      await getSupplyList(payload);
      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <InfiniteScrollContainer
      canLoad={!supplyListLoading && supplyList.length < supplyListCount}
      page={page}
      scrollToTopTrigger={[payload]}
      onPageChange={setPage}
      callback={async (page) => {
        await getSupplyListPart({ ...payload, offset: DEFAULT_LIST_LIMIT * page });
      }}
    >
      {header}

      {navigation}

      <Menu
        items={supplyListForSellerMenuTabs}
        selectedKeys={[tab === null ? ESupplyListMenuTab.Active : tab]}
        onClick={({ key }) => onCurrentMenuTabChange(key as ESupplyListMenuTab)}
        mode="horizontal"
      />

      <div className="need-list__filters">
        <Select
          rootClassName="redesign"
          popupClassName="need-list__filters-select-popup"
          className="need-list__filters-select"
          options={buyerOptions}
          value={payload?.accountId ? payload.accountId : DEFAULT_EMPTY_VALUE}
          onChange={onBuyerChange}
          placeholder="Выберите компанию"
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownShortIcon />}
        />

        {payload.accountId && (
          <>
            <Select
              rootClassName="redesign"
              popupClassName="need-list__filters-select-popup"
              className="need-list__filters-select"
              options={subdivisionOptions}
              value={payload.subdivisionIds ? payload.subdivisionIds[0] : DEFAULT_EMPTY_VALUE}
              onChange={onSubdivisionChange}
              placeholder="Выберите подразделение"
              popupMatchSelectWidth={false}
              suffixIcon={<ArrowDownShortIcon />}
            />

            <Select
              rootClassName="redesign"
              popupClassName="need-list__filters-select-popup"
              className="need-list__filters-select"
              options={addressOptions}
              value={payload.addressId ? payload.addressId : DEFAULT_EMPTY_VALUE}
              onChange={onAddressChange}
              placeholder="Выберите адрес отгрузки"
              popupMatchSelectWidth={false}
              suffixIcon={<ArrowDownShortIcon />}
            />

            <Select
              rootClassName="redesign"
              popupClassName="need-list__filters-select-popup"
              className="need-list__filters-select"
              options={userOptions}
              value={payload.userId ? payload.userId : DEFAULT_EMPTY_VALUE}
              onChange={onUserChange}
              placeholder="Выберите пользователя"
              popupMatchSelectWidth={false}
              suffixIcon={<ArrowDownShortIcon />}
            />
          </>
        )}

        <Input
          style={{ flex: 1 }}
          placeholder="Поиск"
          value={payload?.search}
          onChange={(e) => setPayload((prev) => ({ ...prev, search: e.target.value }))}
        />

        <Button className="button-circle primary" icon={<SearchIcon />} onClick={onSearchClick} />
      </div>

      {renderContent()}
    </InfiniteScrollContainer>
  );
};

const mapState = (state: RootState) => ({
  buyerList: state.buyerListState.data,
  subdivisionList: state.supplyListState.subdivisionList,
  addressList: state.supplyListState.addressList,
  userList: state.supplyListState.userList,
  supplyList: state.supplyListState.data,
  supplyListLoading: state.supplyListState.loading,
  supplyListCount: state.supplyListState.count,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getBuyerList: dispatch.buyerListState.getBuyerList,
  getSupplySubdivisionListForSeller: dispatch.supplyListState.getSupplySubdivisionListForSeller,
  getSupplyAddressListForSeller: dispatch.supplyListState.getSupplyAddressListForSeller,
  getSupplyUserListForSeller: dispatch.supplyListState.getSupplyUserListForSeller,
  setSupplyList: dispatch.supplyListState.setSupplyList,
  getSupplyList: dispatch.supplyListState.getSupplyList,
  getSupplyListPart: dispatch.supplyListState.getSupplyListPart,
});

export const SupplyListForSeller = connect(mapState, mapDispatch)(SupplyListForSellerComponent);
