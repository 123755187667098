import React, { FC } from 'react';
import { Spin } from 'antd';

export const ContentLoader: FC = () => {
  return (
    <div className="content-loader">
      <Spin />
    </div>
  );
};
