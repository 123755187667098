import React from 'react';
import type { DataNode } from 'antd/es/tree';
import { ReactComponent as SuccessIcon } from 'app/assets/images/success.svg';

export const dataToTree = <T extends { name: string; id: number; parentId?: number }>(data?: T[]) => {
  const itemMap = new Map<number, DataNode>();

  // Create a map of elements by element id
  data?.forEach((item) => {
    const transformedItem: DataNode = {
      title: item.name,
      key: item.id,
      children: [],
      icon: ({ selected }) => (selected ? <SuccessIcon /> : null),
    };

    itemMap.set(item.id, transformedItem);
  });

  const treeData: DataNode[] = [];

  // Building a tree using parentId
  data?.forEach((item) => {
    const transformedItem = itemMap.get(item.id);

    if (item.parentId === null && transformedItem) {
      // Top level element, add it to the result
      treeData.push({ ...transformedItem, className: 'root' });
    } else {
      // Find the parent and add the current element to its children
      const parentItem = itemMap.get(item.parentId as number);

      if (parentItem && transformedItem) {
        parentItem.children?.push(transformedItem);
      }
    }
  });

  return treeData;
};
