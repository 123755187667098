import React, { useMemo } from 'react';
import { Form, Switch, FormInstance, InputNumber, Input } from 'antd';
import { IFormValues } from 'common/models';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { mapPropertiesToFormBuilderProperties, renderPropertiesFilterForm } from 'common/helpers/properties.helper';
import { formValuesToSearchParams } from 'common/helpers/searchParams.helper';
import { debounce } from 'common/helpers/data.helper';
import { IProperty } from 'entities/Property/Property.models';
import { formValuesToGoodsListPayload } from 'entities/Listing/Listing.helper';
import { IGoodsListPayload, IGoodsPosition } from 'entities/Goods/Goods.models';

interface IComponentProps {
  form: FormInstance;
  defaultActiveKeys: number[];
  propertyList?: IProperty[];
  setPositions: (positions: IGoodsPosition[]) => void;
  getGoodsList: (payload: IGoodsListPayload) => Promise<void>;
}

export const ListingFilter: React.FC<IComponentProps> = (props) => {
  const { form, defaultActiveKeys, propertyList, setPositions, getGoodsList } = props;

  const { setParams } = useSearchParamsHook();
  const propertiesForm = useMemo(() => {
    if (propertyList) {
      const mappedProperties = mapPropertiesToFormBuilderProperties(propertyList);

      return renderPropertiesFilterForm(mappedProperties, defaultActiveKeys);
    }

    return null;
  }, [propertyList, defaultActiveKeys]);

  const onFormValuesChange = (values: IFormValues) => {
    const { params, error } = formValuesToSearchParams(values);
    const payload = formValuesToGoodsListPayload(values);

    if (!error) {
      setParams(params, { replace: true });
      setPositions(payload.positions);
      getGoodsList(payload);
    }
  };

  const debouncedFormValuesChange = debounce((values: IFormValues) => onFormValuesChange(values));

  return (
    <div className="listing__filter">
      <Form form={form} onValuesChange={(_, values) => debouncedFormValuesChange(values)}>
        <Form.Item noStyle name="catalogId">
          <InputNumber className="listing__filter_item-hidden" />
        </Form.Item>

        <Form.Item noStyle name="categoryId">
          <InputNumber className="listing__filter_item-hidden" />
        </Form.Item>

        <Form.Item noStyle name="search">
          <Input className="listing__filter_item-hidden" />
        </Form.Item>

        <div className="listing__filter_header">
          <div className="listing__filter_header-title">Есть в наличии</div>

          <Form.Item name="isAvailableForPurchase" valuePropName="checked">
            <Switch />
          </Form.Item>
        </div>

        {!!propertyList?.length && <div className="listing__filter_body">{propertiesForm}</div>}
      </Form>
    </div>
  );
};
