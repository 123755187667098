import React from 'react';
import { Button, Checkbox, Popover } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { EUserFrameRole, EUserRole, EUserRoleLabel } from 'common/const/enum';
import { getUsersFrameRoleOptions } from 'entities/Users/Users.helper';

interface IComponentProps {
  roles: EUserRole[];
  isAccountOwner?: boolean;
  isSeller?: boolean;
  updateUserRoles: (roles: EUserFrameRole[]) => void;
}

export const UserFrame: React.FC<IComponentProps> = ({ roles, isAccountOwner, isSeller, updateUserRoles }) => {
  const userFrameOptions = getUsersFrameRoleOptions(isAccountOwner, isSeller);
  const checkAll = Object.keys(EUserFrameRole).length - 1 === roles.length;

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const fullCheckedList = userFrameOptions
      .map((item) => item.value)
      .filter((item) => (isSeller ? item !== EUserFrameRole.Manager : item !== EUserFrameRole.SellerManager));

    if (e.target.checked) {
      updateUserRoles(fullCheckedList);
    } else {
      if (isAccountOwner) {
        updateUserRoles([EUserFrameRole.User, EUserFrameRole.AccountAdmin]);
      } else {
        updateUserRoles([EUserFrameRole.User]);
      }
    }
  };

  const onFilterChange = (list: CheckboxValueType[]) => {
    updateUserRoles(list as EUserFrameRole[]);
  };

  const content = (
    <div className="user-frame__dropdown">
      <Checkbox onChange={onCheckAllChange} checked={checkAll}>
        Все
      </Checkbox>

      <Checkbox.Group className="users__filter-dropdown" options={userFrameOptions} value={roles} onChange={onFilterChange} />
    </div>
  );

  return (
    <div className="user-frame">
      <div className="user-frame__body">
        <p className="user-frame__body-title">Роли пользователя</p>

        <div className="user-frame__body-description-container">
          {roles.map((item, index) => (
            <span key={index} className="frame__body-description-item">
              {EUserRoleLabel[item]}
            </span>
          ))}
        </div>
      </div>

      <div className="user-frame__footer">
        <Popover trigger="click" content={content} placement="bottomRight">
          <Button className="btn btn-primary">Изменить</Button>
        </Popover>
      </div>
    </div>
  );
};
