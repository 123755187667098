import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Image } from 'common/components/Image';
import { VendorCode } from 'common/components/VendorCode';
import { EPresetType } from 'common/const/enum';
import { priceFormatter } from 'common/helpers/formatter.helper';
import { CATALOG_FILTER_CHIPS_MIN, ESCAPE_REGEX_PATTERN } from 'common/config';
import { ReactComponent as SortIcon } from 'app/assets/images/sort.svg';
import { ReactComponent as BasketIcon } from 'app/assets/images/redesign/cart.svg';
import { ICatalog } from 'entities/Catalog/Catalog.models';
import { IGoods, IGoodsListPayload, IGoodsPosition } from 'entities/Goods/Goods.models';
import { ICategoryDetailed } from 'entities/Categories/Categories.models';
import { ICatalogGoodsListFilterProperty, IProperty } from 'entities/Property/Property.models';

export const getCatalogMenuItems = (list: ICatalog[]) => list.map(({ id, name }) => ({ key: id, label: name }));

export const getCurrentCatalog = (list: ICatalog[], id?: string) => list.find((catalog) => catalog.id === Number(id));

export const generateCatalogSearchValueHighlightedText = (name: string, search: string) => {
  if (!search.length) {
    return <span className="text-lists">{name}</span>;
  }

  const parts = name.split(new RegExp(`(${search.replace(ESCAPE_REGEX_PATTERN, '\\$&')})`, 'gi'));

  return (
    <span>
      {parts.map((part, index) => {
        return part.toLowerCase() === search.toLowerCase() ? (
          <span key={index} className="text-accent">
            {part}
          </span>
        ) : (
          <span key={index} className="text-lists">
            {part}
          </span>
        );
      })}
    </span>
  );
};

export const renderCatalogSearchDropdownProductsRecords = (search: string) => {
  return [
    {
      key: 'image',
      render: (_, { image }) => <Image size={40} src={image} />,
      width: 48,
    },
    {
      key: 'name',
      dataIndex: 'name',
      render: (_, { name }) => {
        return (
          <div className="catalog-search__dropdown-products-item-name">
            {generateCatalogSearchValueHighlightedText(name, search)}
          </div>
        );
      },
    },
    {
      key: 'vendorCode',
      render: (_, { vendorCode }) => <VendorCode code={vendorCode} />,
    },
    {
      key: 'sellerCode',
      dataIndex: 'sellerCode',
      render: (_, { sellerCode }) => (
        <span className="text-tag color-dark-grey catalog-search__dropdown-products-item-seller-code">{sellerCode}</span>
      ),
    },
  ] as ColumnsType<IGoods>;
};

export const getCatalogBreadcrumbItems = (
  catalogId: string | number,
  onRootItemClick: (id: number) => void,
  onItemClick: (id: number) => void,
  search: string | null,
  catalogName?: string,
  category?: ICategoryDetailed | null,
) => {
  const items: { title: string; onClick?: () => void }[] = [
    {
      title: catalogName as string,
      onClick: () => onRootItemClick(Number(catalogId)),
    },
  ];

  if (category) {
    if (category.parents) {
      category.parents.forEach((parent) => {
        items.push({
          title: parent.categoryName,
          onClick: () => onItemClick(parent.categoryId),
        });
      });
    }

    items.push({ title: category.name });
  }

  if (search !== null) {
    items.push({ title: `Результаты для “${search}” ` });
  }

  return items;
};

export const renderCatalogGoodsListRecords = ({
  searchNotEmpty,
  category,
}: {
  searchNotEmpty?: boolean;
  category?: ICategoryDetailed | null;
}) => {
  const records: ColumnsType<IGoods> = [
    {
      key: 'image',
      title: <SortIcon />,
      render: (_, { image }) => <Image size={104} src={image} />,
      width: 124,
    },
    {
      key: 'name',
      title: 'Название',
      render: (_, { name, sellerCode, vendorCode }) => {
        return (
          <>
            <span className="text-h4 catalog__goods-list-item-name">{name}</span>

            <div>
              <span className="text-tag color-dark-grey" style={{ marginRight: 20 }}>
                {sellerCode}
              </span>

              <VendorCode code={vendorCode} />
            </div>
          </>
        );
      },
      width: 540,
    },
  ];

  if (searchNotEmpty) {
    records.push({
      key: 'category',
      title: 'Категория',
      dataIndex: 'categoryName',
      ellipsis: true,
    });
  }

  if (category) {
    category.propertiesToDisplay?.forEach((propertyToDisplay) => {
      records.push({
        key: propertyToDisplay.propertyId,
        title: propertyToDisplay.propertyName,
        render: (_, { properties }) => {
          const propertyItem = properties.find((property) => property.propertyId === propertyToDisplay.propertyId);

          if (propertyItem) {
            return `${propertyItem.value} ${propertyItem.unitOfMeasurement || ''}`;
          }

          return '';
        },
        responsive: ['xxl'],
        ellipsis: true,
      });
    });
  }

  records.push(
    {
      key: 'brand',
      title: 'Бренд',
      dataIndex: 'brand',
      ellipsis: true,
    },
    {
      key: 'priceWithTaxes',
      title: 'Цена с НДС',
      render: (_, { priceWithTaxes, goodInBasket }) => {
        return (
          <>
            <span className="text-accent">{priceFormatter(priceWithTaxes)}</span>

            {goodInBasket && (
              <div className="catalog__goods-list-item-in-basket-label">
                <BasketIcon />

                <span className="text-tag-accent color-bright-green">В корзине</span>
              </div>
            )}
          </>
        );
      },
      width: 150,
    },
  );

  return records;
};

export const getCatalogEmptyStateContent = ({
  payload,
  categoryId,
  searchNotEmpty,
}: {
  payload: IGoodsListPayload | null;
  categoryId?: number;
  searchNotEmpty?: boolean;
}) => {
  let emptyTitle = 'В этом каталоге товары отсутствуют.';
  let emptyDescription = 'Они появятся здесь после добавления поставщиком.';
  let emptyBackBtnTitle = 'Вернуться назад';

  if (categoryId) {
    if (payload && (!!payload.isAvailableForPurchase || !!payload.positions?.length)) {
      emptyTitle = 'По этим параметрам товары не найдены.';
      emptyBackBtnTitle = '';
    } else {
      emptyTitle = 'В этой категории товары отсутствуют.';
    }
  }

  if (searchNotEmpty) {
    emptyTitle = 'По вашему запросу ничего не найдено.';
    emptyDescription = 'Попробуйте найти товар вручную.';
    emptyBackBtnTitle = 'Назад в каталог';
  }

  return { emptyTitle, emptyDescription, emptyBackBtnTitle };
};

export const setCatalogGoodsListFilterItemsHidden = (
  propertyList: ICatalogGoodsListFilterProperty[],
  index = 0,
): ICatalogGoodsListFilterProperty[] => {
  if (index === propertyList.length) {
    return propertyList;
  }

  const mappedPropertyList = propertyList.map((property, _, array) => {
    const hidden = !property.showCondition
      ? false
      : property.showCondition?.some((condition) => {
          const parent = array.find((item) => item.id === condition.propertyId);

          if (!parent) {
            return false;
          }

          return !parent.result.some((item) => condition.values.includes(item));
        });

    return { ...property, result: hidden ? [] : property.result, hidden };
  });

  return setCatalogGoodsListFilterItemsHidden(mappedPropertyList, index + 1);
};

export const getCatalogGoodsFilterRangePropertyResult = (min?: string | null, max?: string | null) => {
  const result = [];
  const minValue = min ? min.toString() : undefined;
  const maxValue = max ? max.toString() : undefined;

  if (minValue && maxValue) {
    result.push(minValue, maxValue);
  }

  if (!minValue && maxValue) {
    result.push('', maxValue);
  }

  if (minValue && !maxValue) {
    result.push(minValue);
  }

  return result;
};

export const getSearchParamsUrlFromPropertyList = (propertyList: ICatalogGoodsListFilterProperty[]) => {
  return propertyList
    .filter(({ result }) => !!result.length)
    .map(({ id, result }) => {
      const encodedResult = result.map((value) => `${encodeURIComponent(JSON.stringify(value))}`);

      return `${id}=[${encodedResult}]`;
    })
    .join('&');
};

export const propertyListToCatalogGoodsListPositions = (propertyList: ICatalogGoodsListFilterProperty[]) => {
  return propertyList
    .filter((property) => !!property.result.length)
    .map((property) => {
      const position: IGoodsPosition = { propertyId: property.id };

      if (property.presetType !== EPresetType.Range) {
        position.values = property.result;
      } else {
        position.range = {
          from: property.result[0] ? Number(property.result[0]) : undefined,
          to: property.result[1] ? Number(property.result[1]) : undefined,
        };
      }

      return position;
    });
};

export const searchParamsToCatalogGoodsListPositions = (propertyList: IProperty[] | null) => {
  if (!propertyList) {
    return [];
  }

  const searchParams = new URLSearchParams(window.location.search);
  const positions: IGoodsPosition[] = [];

  searchParams.forEach((value, key) => {
    const property = propertyList.find((propertyItem) => propertyItem.id === Number(key));

    if (property) {
      const parsedValue = JSON.parse(value);

      if (property.presetType === EPresetType.Range) {
        positions.push({
          propertyId: property.id,
          range: {
            from: parsedValue[0] ? Number(parsedValue[0]) : undefined,
            to: parsedValue[1] ? Number(parsedValue[1]) : undefined,
          },
        });
      } else {
        positions.push({ propertyId: property.id, values: parsedValue });
      }
    }
  });

  return positions;
};

export const showCatalogFilterChipsClearAllBtn = (propertyList: ICatalogGoodsListFilterProperty[]) => {
  return (
    propertyList.filter((property) => !!property.result.length).length > CATALOG_FILTER_CHIPS_MIN ||
    propertyList.some((property) => property.result.length > CATALOG_FILTER_CHIPS_MIN)
  );
};
