import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { IRootModel } from 'app/store';
import {
  IDeliveryCityListParams,
  IDeliveryCityListState,
  IStockCity,
  IStockCityListParams,
  IStockCityListState,
  IStockCityCreatePayload,
  IStockCityDeletePayload,
  IStockCityState,
  IStockCityUpdatePayload,
} from 'entities/Delivery/Delivery.models';
import { deliveryTransport } from 'entities/Delivery/Delivery.transport';
import { updateStockCityListState } from 'entities/Delivery/Delivery.helper';

export const deliveryCityListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IDeliveryCityListState,
  reducers: {
    setDeliveryCityList: (state, payload: string[]) => ({ ...state, data: payload }),
    setDeliveryCityListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getDeliveryCityList(params: IDeliveryCityListParams) {
      dispatch.deliveryCityListState.setDeliveryCityListLoading(true);

      try {
        const response = await deliveryTransport.getDeliveryCityList(params);
        dispatch.deliveryCityListState.setDeliveryCityList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.deliveryCityListState.setDeliveryCityListLoading(false);
      }
    },
  }),
});

export const stockCityListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IStockCityListState,
  reducers: {
    setStockCityList: (state, payload: IStockCity[]) => ({ ...state, data: payload }),
    setStockCityListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    addStockCity: updateStockCityListState.addStockCity,
    updateStockCity: updateStockCityListState.updateStockCity,
    deleteStockCity: updateStockCityListState.deleteStockCity,
  },
  effects: (dispatch) => ({
    async getStockCityList(params: IStockCityListParams) {
      dispatch.stockCityListState.setStockCityListLoading(true);

      try {
        const response = await deliveryTransport.getStockCityList(params);
        dispatch.stockCityListState.setStockCityList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.stockCityListState.setStockCityListLoading(false);
      }
    },
  }),
});

export const stockCityState = createModel<IRootModel>()({
  state: {
    loading: false,
  } as IStockCityState,
  reducers: {
    setStockCityLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async createStockCity(payload: IStockCityCreatePayload) {
      dispatch.stockCityState.setStockCityLoading(true);

      try {
        const response = await deliveryTransport.createStockCity(payload);
        dispatch.stockCityListState.addStockCity(response);
        dispatch.stockCityState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.stockCityState.setStockCityLoading(false);
      }
    },
    async updateStockCity(payload: IStockCityUpdatePayload) {
      dispatch.stockCityState.setStockCityLoading(true);

      try {
        const response = await deliveryTransport.updateStockCity(payload);
        dispatch.stockCityListState.updateStockCity(response);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.stockCityState.setStockCityLoading(false);
      }
    },
    async deleteStockCity(payload: IStockCityDeletePayload) {
      dispatch.stockCityState.setStockCityLoading(true);

      try {
        const response = await deliveryTransport.deleteStockCity(payload);
        dispatch.stockCityListState.deleteStockCity(response.id);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.stockCityState.setStockCityLoading(false);
      }
    },
  }),
});
