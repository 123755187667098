import axios from 'axios';
import {
  IContract,
  IContractCollectionDto,
  IContractListParams,
  IContractCreatePayload,
  IContractDeletePayload,
  IContractUpdatePayload,
} from 'entities/Contract/Contract.models';

const basePath = 'contract';

export const contractTransport = {
  getContractList: (params: IContractListParams): Promise<IContractCollectionDto> => axios.get(`${basePath}`, { params }),
  getContractById: (id: number): Promise<IContract> => axios.get(`${basePath}/${id}`),
  createContract: (payload: IContractCreatePayload): Promise<IContract> => axios.post(`${basePath}`, payload),
  updateContract: (payload: IContractUpdatePayload): Promise<IContract> => axios.patch(`${basePath}`, payload),
  deleteContract: (payload: IContractDeletePayload): Promise<IContract> => axios.delete(`${basePath}`, { data: payload }),
};
