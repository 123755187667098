import React, { FC, ReactNode, useState } from 'react';
import { Button, Popover, Radio } from 'antd';
import { ESupplyStatus } from 'common/const/enum';
import { SupplyStatus } from 'common/components/SupplyStatus';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';

interface IComponentProps {
  children: ReactNode;
  onApply: (value: ESupplyStatus) => void;
}

export const ChangeSupplyStatusPopover: FC<IComponentProps> = ({ children, onApply }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<ESupplyStatus>(ESupplyStatus.Delivery);

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      overlayClassName="redesign"
      overlayStyle={{ width: 400 }}
      placement="bottom"
      content={
        <div className="custom-popover__content">
          <div className="custom-popover__header">
            <Button className="button-icon" icon={<CloseIcon />} onClick={() => setOpen(false)} />
          </div>

          <span className="text-h4" style={{ marginBottom: 6 }}>
            Выберите новый статус
          </span>

          <span className="text-tag color-dark-grey">
            Новый статус будет применён ко всем выбранным партиям поставок и всем поставкам внутри.
          </span>

          <div className="divider custom-popover__divider" />

          <Radio.Group
            className="custom-popover__radio"
            options={[
              {
                label: <SupplyStatus status={ESupplyStatus.Delivery} />,
                value: ESupplyStatus.Delivery,
              },
              {
                label: <SupplyStatus status={ESupplyStatus.Delivered} />,
                value: ESupplyStatus.Delivered,
              },
            ]}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />

          <div className="divider custom-popover__divider" />

          <div className=" custom-popover__footer">
            <Button className="button-s secondary" onClick={() => setOpen(false)}>
              Отмена
            </Button>

            <Button
              className="button-s primary"
              onClick={() => {
                onApply(value);
                setOpen(false);
              }}
            >
              Применить
            </Button>
          </div>
        </div>
      }
      trigger="click"
    >
      {children}
    </Popover>
  );
};
