import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { EMessage, ENotificationType, EPositionFormMode, EQrfpStatus, ERoute, ESearchParams, EUserRole } from 'common/const/enum';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { ContentLoader } from 'common/components/ContentLoader';
import { PositionForm } from 'common/components/PositionForm';
import { IFormValues } from 'common/models';
import { formValuesToPropertiesPayload } from 'common/helpers/properties.helper';
import { PositionCascader } from 'common/components/PositionCascader';
import { findDefaultPositionCascaderPath } from 'common/helpers/position.helper';
import { RootDispatch, RootState } from 'app/store';
import { grfpToPosition } from 'entities/Qrfp/Qrfp.helper';
import { Notification } from 'entities/Modal/components/Notification';
import { CreateGoodsFormModal } from 'entities/Modal/components/CreateGoodsFormModal';
import { IGoodsCreatePayload } from 'entities/Goods/Goods.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const QrfpPositionComponent: FC<AllType> = (props) => {
  const {
    categoryListState,
    propertyListState,
    authState,
    qrfpState,
    getCategoryList,
    getPropertyListByCategoryId,
    createQrfp,
    getQrfp,
    completeQrfp,
    createQrfpGoods,
  } = props;

  const { data: categoryList } = categoryListState;
  const { data: propertyList, loading: propertyListLoading } = propertyListState;
  const { data: auth } = authState;
  const { data: qrfp, loading: qrfpLoading } = qrfpState;

  const [categoryCascaderPath, setCategoryCascaderPath] = useState<number[]>([]);
  const [formValues, setFormValues] = useState<IFormValues | null>(null);
  const [formIsChanged, setFormIsChanged] = useState<boolean>(false);
  const [openPositionUnsavedModal, setOpenPositionUnsavedModal] = useState<boolean>(false);
  const [openCreateGoodsModal, setOpenCreateGoodsModal] = useState<boolean>(false);
  const { searchParams } = useSearchParamsHook();
  const { mode } = useParams();
  const navigate = useNavigate();

  const catalogId = searchParams.get(ESearchParams.CatalogId);
  const categoryId = searchParams.get(ESearchParams.CategoryId);
  const positionId = searchParams.get(ESearchParams.PositionId);
  const position = grfpToPosition(qrfp);
  const readOnly = mode === EPositionFormMode.View;
  const qrfpStatus = qrfp?.status;
  const isSellerManager = auth?.access.roles.includes(EUserRole.SellerManager);

  const onPositionFormBackClick = () => {
    navigate(-1);
  };

  const onPositionFormCancelClick = (values: IFormValues) => {
    if (formIsChanged) {
      setFormValues(values);
      setOpenPositionUnsavedModal(true);
    } else {
      navigate(-1);
    }
  };

  const onPositionFormCompleteClick = () => {
    if (qrfp) {
      completeQrfp({ qrfpId: Number(qrfp.id), onSuccess: onPositionFormBackClick });
    }
  };

  const onPositionFormAddGoodsClick = () => {
    setOpenCreateGoodsModal(true);
  };

  const onSubmit = (values: IFormValues) => {
    if (categoryId) {
      createQrfp({
        categoryId: Number(categoryId),
        // @ts-ignore
        properties: formValuesToPropertiesPayload(values, propertyList),
        onSuccess: () => {
          navigate(ERoute.Shop);
        },
      });
    }
  };

  const onPositionUnsavedModalCancelClick = () => {
    navigate(-1);
  };

  const onPositionUnsavedModalConfirmClick = () => {
    if (formValues) {
      onSubmit(formValues);
    }
  };

  const onCreateGoodsFormModalSubmit = (payload: IGoodsCreatePayload) => {
    if (qrfp) {
      createQrfpGoods({ qrfpId: qrfp.id, ...payload });
    }
  };

  useEffect(() => {
    if (categoryId && !categoryCascaderPath.length) {
      const defaultCategoryCascaderPath = findDefaultPositionCascaderPath(categoryList, Number(categoryId));

      if (defaultCategoryCascaderPath.length) {
        setCategoryCascaderPath(defaultCategoryCascaderPath);
      }
    }
  }, [categoryId, categoryList]);

  const { contentLoading } = useContentLoader(async () => {
    await getCategoryList({ catalogId: Number(catalogId), limit: 0 });
    await getPropertyListByCategoryId(Number(categoryId));

    if (positionId) {
      getQrfp(Number(positionId));
    }
  });

  return (
    <Layout className="position">
      {contentLoading ? (
        <ContentLoader />
      ) : (
        <Spin wrapperClassName="position__spin" spinning={qrfpLoading}>
          <div className="position__title">Добавить позицию в запрос</div>

          <PositionCascader categoryList={categoryList} value={categoryCascaderPath} disabled={true} />

          <PositionForm
            loading={propertyListLoading}
            propertyList={propertyList}
            position={position}
            readOnly={readOnly}
            cancelBtnTitle={readOnly ? 'Назад' : 'Отменить'}
            submitBtnTitle="Добавить позицию"
            setFormIsChanged={setFormIsChanged}
            onCancel={readOnly ? onPositionFormBackClick : onPositionFormCancelClick}
            onSubmit={!readOnly ? onSubmit : undefined}
            onComplete={isSellerManager && qrfpStatus === EQrfpStatus.New ? onPositionFormCompleteClick : undefined}
            onAddGoods={isSellerManager && qrfpStatus === EQrfpStatus.New ? onPositionFormAddGoodsClick : undefined}
          />
        </Spin>
      )}

      <Notification
        open={openPositionUnsavedModal}
        type={ENotificationType.Notification}
        description={EMessage.ChangesWereNotSaved}
        loading={qrfpLoading}
        confirmTitle="Сохранить"
        cancelTitle="Отменить изменения"
        onConfirmClick={onPositionUnsavedModalConfirmClick}
        onCancelClick={onPositionUnsavedModalCancelClick}
      />

      <CreateGoodsFormModal
        open={openCreateGoodsModal}
        loading={qrfpLoading}
        categoryList={categoryList}
        defaultCategoryCascaderPath={categoryCascaderPath}
        defaultCategoryId={Number(categoryId)}
        position={position}
        onCancel={() => setOpenCreateGoodsModal(false)}
        onSubmit={onCreateGoodsFormModalSubmit}
      />
    </Layout>
  );
};

const mapState = (state: RootState) => ({
  categoryListState: state.categoryListState,
  propertyListState: state.propertyListState,
  authState: state.authState,
  qrfpState: state.qrfpState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getCategoryList: dispatch.categoryListState.getCategoryList,
  getPropertyListByCategoryId: dispatch.propertyListState.getPropertyListByCategoryId,
  createQrfp: dispatch.qrfpState.createQrfp,
  getQrfp: dispatch.qrfpState.getQrfp,
  completeQrfp: dispatch.qrfpState.completeQrfp,
  createQrfpGoods: dispatch.qrfpState.createQrfpGoods,
});

export const QrfpPosition = connect(mapState, mapDispatch)(QrfpPositionComponent);
