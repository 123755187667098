import axios from 'axios';
import {
  IWorkspaceCollectionDto,
  IWorkspaceListParams,
  IWorkspace,
  IWorkspaceGrouped,
  IWorkspaceCopyPayload,
  IWorkspaceCreatePayload,
  IWorkspaceDeletePayload,
  IWorkspaceUpdatePayload,
} from 'entities/Workspaces/Workspaces.models';

const basePath = 'workspace';

export const workspacesTransport = {
  getWorkspaceList: (params: IWorkspaceListParams): Promise<IWorkspaceCollectionDto> => axios.get(`${basePath}`, { params }),
  getWorkspaceById: (id: number): Promise<IWorkspace> => axios.get(`${basePath}/${id}`),
  createWorkspace: (payload: IWorkspaceCreatePayload): Promise<IWorkspace> => axios.post(`${basePath}`, payload),
  updateWorkspace: (payload: IWorkspaceUpdatePayload): Promise<IWorkspace> => axios.patch(`${basePath}`, payload),
  copyWorkspace: (payload: IWorkspaceCopyPayload): Promise<IWorkspace> => axios.post(`${basePath}/copy`, payload),
  deleteWorkspace: (payload: IWorkspaceDeletePayload): Promise<IWorkspace> => axios.delete(`${basePath}`, { data: payload }),
  getWorkspaceGrouped: (id: number): Promise<IWorkspaceGrouped> => axios.get(`${basePath}/grouped/${id}`),
};
