import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Spin, Upload } from 'antd';
import { connect } from 'react-redux';
import { EMessage, EPropertyType } from 'common/const/enum';
import { IFileImage, IFormValues } from 'common/models';
import { ALL_DOCS_EXTS, UPLOADING_DOCS_COUNT } from 'common/config';
import { rules } from 'common/helpers/form.helper';
import { usePropertyForm } from 'common/hooks/usePropertyForm';
import { propertyListToPresetList, renderPresetList } from 'common/helpers/preset.helper';
import { PositionCascader } from 'common/components/PositionCascader';
import { RootDispatch, RootState } from 'app/store';
import { createGoodsFormValuesToPayload, renderCreateGoodsFormField } from 'entities/Modal/Modal.helper';
import { ICategory } from 'entities/Categories/Categories.models';
import { IWorkspacePosition } from 'entities/Workspaces/Workspaces.models';
import { IGoodsCreatePayload } from 'entities/Goods/Goods.models';

interface IComponentProps {
  open: boolean;
  loading: boolean;
  categoryList: ICategory[];
  defaultCategoryCascaderPath?: number[];
  defaultCategoryId?: number;
  position?: IWorkspacePosition;
  onCancel: () => void;
  onSubmit: (payload: IGoodsCreatePayload) => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const CreateGoodsFormModalComponent: React.FC<AllType> = (props) => {
  const {
    open,
    loading,
    categoryList,
    defaultCategoryCascaderPath,
    defaultCategoryId,
    position,
    propertyListState,
    filesState,
    onCancel,
    onSubmit,
    getPropertyListByCategoryId,
    uploadFiles,
  } = props;

  const { data: propertyList, loading: propertyListLoading } = propertyListState;
  const { loading: uploadLoading } = filesState;

  const [categoryCascaderPath, setCategoryCascaderPath] = useState<number[]>([]);
  const [category, setCategory] = useState<ICategory | null>(null);
  const [image, setImage] = useState<IFileImage | null>(null);
  const { propertyListForView, form, changeFormValues } = usePropertyForm(propertyList, category, position);

  const nonCheckboxProperties = propertyListForView.filter((property) => property.type !== EPropertyType.Label);
  const checkboxProperties = propertyListForView.filter((property) => property.type === EPropertyType.Label);
  const presetList = propertyListToPresetList(position?.properties || []);

  const customRequest = async ({ file }: any) => {
    const response = await uploadFiles(file);

    if (response) {
      setImage({ url: response.url, name: response.name });
    } else {
      form.setFields([{ name: 'image', value: null, errors: [EMessage.ImageUploadError] }]);
    }
  };

  const onCategoryChange = async (value: (string | number)[] | undefined) => {
    if (value) {
      const selectedCategoryId = value[value.length - 1];

      if (selectedCategoryId !== category?.id) {
        setCategoryCascaderPath(value as number[]);
        await getPropertyListByCategoryId(Number(selectedCategoryId));
        const selectedCategory = categoryList.find((category) => category.id === Number(selectedCategoryId));

        if (selectedCategory) {
          setCategory(selectedCategory);
        }
      }
    } else {
      setCategory(null);
      setCategoryCascaderPath([]);
    }
  };

  const onCancelClick = () => {
    setCategoryCascaderPath([]);
    setCategory(null);
    setImage(null);
    form.resetFields();
    onCancel();
  };

  const handleSubmit = (values: IFormValues) => {
    const payload = createGoodsFormValuesToPayload(values, propertyList);

    onSubmit({ ...payload, image: image?.url, onSuccess: onCancelClick });
  };

  useEffect(() => {
    if (defaultCategoryCascaderPath) {
      setCategoryCascaderPath(defaultCategoryCascaderPath);
    }
  }, [defaultCategoryCascaderPath]);

  useEffect(() => {
    if (defaultCategoryId) {
      const selectedCategory = categoryList.find((category) => category.id === defaultCategoryId);

      if (selectedCategory) {
        setCategory(selectedCategory);
      }
    }
  }, [defaultCategoryId, categoryList]);

  return (
    <Modal
      wrapClassName="create-goods-form-modal"
      open={open}
      className="modal"
      footer={false}
      width={1256}
      onCancel={onCancelClick}
    >
      <Spin wrapperClassName="create-goods-form-modal__spin" spinning={loading}>
        <div className="create-goods-form-modal__container">
          <Form
            form={form}
            className="create-goods-form-modal__form"
            layout="vertical"
            onValuesChange={changeFormValues}
            onFinish={handleSubmit}
          >
            <Row>
              <Col xs={24}>
                <Form.Item label="Название товара" name="name" rules={[rules.required()]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[48, 16]} align="bottom">
              <Col xs={24}>
                <div className="create-goods-form-modal__title">Общие данные о товаре</div>
              </Col>

              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item label="Идентификатор товара" name="externalId" rules={[rules.required()]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item label="ТН ВЭД" name="tnvd" rules={[rules.required()]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item label="Код поставщика" name="sellerCode" rules={[rules.required()]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item label="Цена" name="price" rules={[rules.required()]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  label="Изображение"
                  name="image"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e.fileList}
                  rules={[rules.required()]}
                >
                  <Upload
                    accept={ALL_DOCS_EXTS}
                    maxCount={UPLOADING_DOCS_COUNT}
                    showUploadList={false}
                    customRequest={customRequest}
                  >
                    <Button loading={uploadLoading}>{image ? image.name : 'Загрузить'}</Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>

            {position && (
              <Row>
                <div className="create-goods-form-modal__title">Исходные данные товара</div>

                <Col xs={24}>
                  <div className="preset__container">{renderPresetList(presetList)}</div>
                </Col>
              </Row>
            )}

            <Row>
              <div className="create-goods-form-modal__form-item-label">Категория товара</div>

              <Col xs={24}>
                <PositionCascader
                  categoryList={categoryList}
                  value={categoryCascaderPath}
                  disabled={!!defaultCategoryId}
                  onChange={onCategoryChange}
                />
              </Col>
            </Row>

            {category ? (
              <>
                <Spin spinning={propertyListLoading}>
                  <Row gutter={[48, 16]} align="bottom">
                    <Col xs={24}>
                      <div className="create-goods-form-modal__title">Параметры категории</div>
                    </Col>

                    {nonCheckboxProperties.map(renderCreateGoodsFormField)}
                  </Row>

                  <div className="create-goods-form-modal__title" />

                  <Row gutter={[48, 0]} align="bottom">
                    <Col xs={24}>
                      <div className="create-goods-form-modal__title">Доступные опции</div>
                    </Col>

                    {checkboxProperties.map(renderCreateGoodsFormField)}
                  </Row>
                </Spin>

                <Form.Item className="create-goods-form-modal__form-item-submit">
                  <Button className="btn btn-primary" htmlType="submit" disabled={propertyListLoading}>
                    Добавить товар в базу
                  </Button>
                </Form.Item>
              </>
            ) : (
              <Spin spinning={propertyListLoading} />
            )}
          </Form>
        </div>
      </Spin>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  propertyListState: state.propertyListState,
  filesState: state.filesState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getPropertyListByCategoryId: dispatch.propertyListState.getPropertyListByCategoryId,
  uploadFiles: dispatch.filesState.uploadFiles,
});

export const CreateGoodsFormModal = connect(mapState, mapDispatch)(CreateGoodsFormModalComponent);
