import React, { FC } from 'react';
import { Button, Dropdown } from 'antd';

interface IComponentProps {
  items: any[];
  loading: boolean;
  disabled?: boolean;
}

export const SupplyCardOtherActionsBtn: FC<IComponentProps> = ({ items, loading, disabled }) => {
  return (
    <Dropdown
      overlayClassName="redesign"
      menu={{ items: items.flatMap((item) => [{ type: 'divider' }, item]).slice(1) }}
      trigger={['click']}
      placement="top"
      disabled={disabled}
    >
      <Button className="button-l secondary" loading={loading}>
        Другие действия
      </Button>
    </Dropdown>
  );
};
