import React from 'react';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/lib/table';
import type { MenuProps } from 'antd';
import { Image } from 'common/components/Image';
import { NameLabel } from 'common/components/NameLabel';
import { NeedStatus } from 'common/components/NeedStatus';
import { DEFAULT_EMPTY_VALUE } from 'common/config';
import {
  ENeedCardMenuItem,
  ENeedCardMenuItemLabel,
  ENeedHistoryItemType,
  ENeedStatusLabel,
  ERequestsMenuTab,
  ERequestsNavigationTab,
  ERoute,
  ESupplyCorrectionLabel,
  ESupplyStatusLabel,
} from 'common/const/enum';
import { mapToMenuWithDivider } from 'common/helpers/data.helper';
import { countFormatter, priceFormatter } from 'common/helpers/formatter.helper';
import { NeedListItemProgress } from 'entities/Need/components/NeedListItemProgress';
import {
  INeed,
  INeedHistoryItem,
  INeedHistoryNeedChanges,
  INeedHistorySupplyChanges,
  INeedHistorySupplyCorrectionChanges,
  INeedHistorySupplyPriceChanges,
  INeedListState,
  INeedSubdivision,
  INeedUserListItem,
} from 'entities/Need/Need.models';
import { getUserInitials, getUserName } from 'entities/Users/Users.helper';
import { IBuyer } from 'entities/Buyers/Buyers.models';
import { ILegal } from 'entities/LegalEntity/LegalEntity.models';
import { IAddress } from 'entities/Addresses/Addresses.models';

type MenuItem = Required<MenuProps>['items'][number];

export const getNeedListFilterOptions = {
  subdivision: (list: INeedSubdivision[]) =>
    mapToMenuWithDivider([
      { label: 'Все подразделения', value: DEFAULT_EMPTY_VALUE },
      ...list.map((subdivision) => ({ label: subdivision.name, value: subdivision.id })),
    ]),
  user: (list: INeedUserListItem[]) =>
    mapToMenuWithDivider([
      { label: 'Все пользователи', value: DEFAULT_EMPTY_VALUE },
      ...list.map((user) => ({ label: getUserName(user.firstName, user.lastName), value: user.id })),
    ]),
  buyer: (list: IBuyer[]) =>
    mapToMenuWithDivider([
      { label: 'Все компании', value: DEFAULT_EMPTY_VALUE },
      ...list.map((buyer) => ({ label: buyer.name, value: buyer.id })),
    ]),
  legal: (list: ILegal[]) =>
    mapToMenuWithDivider([
      { label: 'Все юридические лица', value: DEFAULT_EMPTY_VALUE },
      ...list.map((legal) => ({ label: legal.name, value: legal.id })),
    ]),
  address: (list: IAddress[]) =>
    mapToMenuWithDivider([
      { label: 'Все адреса отгрузки', value: DEFAULT_EMPTY_VALUE },
      ...list.map((address) => ({ label: address?.name, value: address?.id })),
    ]),
};

export const updateNeedListState = {
  setNeedList: (state: INeedListState, payload: INeed[]) => ({ ...state, data: payload }),
  setNeedListPart: (state: INeedListState, payload: INeed[]) => ({ ...state, data: [...state.data, ...payload] }),
  setNeedListCount: (state: INeedListState, payload: number) => ({ ...state, count: payload }),
  setNeedListLoading: (state: INeedListState, payload: boolean) => ({ ...state, loading: payload }),
  setNeedUserList: (state: INeedListState, payload: INeedUserListItem[]) => ({ ...state, userList: payload }),
  setNeedUserListLoading: (state: INeedListState, payload: boolean) => ({ ...state, userListLoading: payload }),
  setNeedSubdivisionList: (state: INeedListState, payload: INeedSubdivision[]) => ({
    ...state,
    subdivisionList: payload,
  }),
  setNeedSubdivisionListLoading: (state: INeedListState, payload: boolean) => ({ ...state, subdivisionListLoading: payload }),
  filterNeedList: (state: INeedListState, payload: number[]) => {
    return {
      ...state,
      data: state.data.filter((need) => !payload.includes(need.id)),
      count: state.count - payload.length,
    };
  },
  updateNeedList: (state: INeedListState, payload: INeed[]) => {
    return {
      ...state,
      data: state.data.map((need) => {
        const item = payload.find((needItem) => needItem.id === need.id);

        return item ? item : need;
      }),
    };
  },
};

export const getNeedFormattedDate = (createdAt: string, format: string) => {
  if (dayjs().isSame(createdAt, 'day')) {
    return 'Сегодня';
  }

  return dayjs(createdAt).locale('ru').format(format);
};

export const getSelectedNeedListTotalAmount = (list: INeed[]) => list.reduce((acc, need) => acc + need.count * need.price, 0);

export const renderNeedListRecords = (tab?: ERequestsMenuTab) => {
  const requestsForUser = window.location.pathname === ERoute.RequestsForUser;
  const cancelledNeedListForUser = window.location.pathname === `${ERoute.RequestsForUser}/${ERequestsNavigationTab.Cancelled}`;
  const isCreatedNeedList = tab === ERequestsMenuTab.Created;
  const isPendingNeedList = tab === ERequestsMenuTab.Pending;

  const records = [
    {
      key: 'image',
      render: (_, { good: { image } }) => <Image size={60} src={image} />,
      width: 100,
    },
    {
      key: 'name',
      render: (_, { good: { name } }) => {
        return (
          <span
            className="text-h4-item-name"
            style={{
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
            }}
            title={name}
          >
            {name}
          </span>
        );
      },
      width: 566,
    },
    {
      key: 'code',
      render: (_, { good: { sellerCode, vendorCode } }) => {
        return (
          <div>
            {sellerCode && (
              <div className="text-lists ellipsis" title={sellerCode}>
                {sellerCode}
              </div>
            )}

            {vendorCode && (
              <div className="text-tag-accent color-bright-green ellipsis" title={vendorCode}>
                {vendorCode}
              </div>
            )}
          </div>
        );
      },
      ellipsis: true,
      responsive: ['xxl'],
    },
  ] as ColumnsType<INeed>;

  if (requestsForUser || cancelledNeedListForUser) {
    records.push(
      {
        key: 'count',
        render: (_, { count }) => <span className="text-lists">{countFormatter(count)}</span>,
        ellipsis: true,
        responsive: ['xxl'],
      },
      {
        key: 'status',
        render: (_, { status }) => <NeedStatus status={status} />,
        width: 240,
      },
      {
        key: 'approvedSuppliesCount',
        render: (_, { count, approvedSuppliesCount }) => {
          return <NeedListItemProgress label="Заказано" count={approvedSuppliesCount} limit={count} />;
        },
        width: 320,
      },
    );
  }

  if (isCreatedNeedList) {
    records.push(
      {
        key: 'user',
        render: (_, { user: { firstName, lastName } }) => {
          return <NameLabel icon={getUserInitials(firstName, lastName)} name={getUserName(firstName, lastName)} />;
        },
        ellipsis: true,
        responsive: ['xxl'],
      },
      {
        key: 'count',
        render: (_, { count }) => <div className="text-lists">{countFormatter(count)}</div>,
        ellipsis: true,
      },
      {
        key: 'price',
        render: (_, { price }) => <div className="text-lists">{`${priceFormatter(price)}/ед.`}</div>,
        ellipsis: true,
      },
      {
        key: 'totalPrice',
        render: (_, { count, price, priceWithTaxes }) => {
          return (
            <div>
              <div className="text-lists ellipsis" title={priceFormatter(count * price)}>
                {priceFormatter(count * price)}
              </div>

              <div className="color-dark-grey" style={{ whiteSpace: 'normal' }}>
                <span className="text-tag">сумма с НДС </span>

                <span
                  className="text-tag ellipsis"
                  style={{ whiteSpace: 'nowrap' }}
                  title={priceFormatter(count * priceWithTaxes)}
                >
                  {priceFormatter(count * priceWithTaxes)}
                </span>
              </div>
            </div>
          );
        },
      },
    );
  }

  if (isPendingNeedList) {
    records.push(
      {
        key: 'user',
        render: (_, { user: { firstName, lastName } }) => {
          return <NameLabel icon={getUserInitials(firstName, lastName)} name={getUserName(firstName, lastName)} />;
        },
        ellipsis: true,
        responsive: ['xxl'],
      },
      {
        key: 'pendingSuppliesCount',
        render: (_, { count, pendingSuppliesCount, approvedSuppliesCount }) => {
          return <div className="text-lists">{countFormatter(count - pendingSuppliesCount - approvedSuppliesCount)}</div>;
        },
        ellipsis: true,
      },
      {
        key: 'status',
        render: (_, { status }) => {
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <NeedStatus status={status} />
            </div>
          );
        },
        width: 240,
        ellipsis: true,
        responsive: ['xxl'],
      },
    );
  }

  return records;
};

export const getNeedCardHistoryItemDescription = (item: INeedHistoryItem) => {
  switch (item.type) {
    case ENeedHistoryItemType.CreateSupply: {
      return 'Создана новая поставка.';
    }
    case ENeedHistoryItemType.CreateSupplyNeed: {
      return 'Создана новая заявка.';
    }
    case ENeedHistoryItemType.ChangeSupplyStatus: {
      const { oldStatus, newStatus } = item.changes as INeedHistorySupplyChanges;

      return `Изменен статус поставки c «${ESupplyStatusLabel[oldStatus]}» на «${ESupplyStatusLabel[newStatus]}»`;
    }
    case ENeedHistoryItemType.ChangeSupplyNeedStatus: {
      const { oldStatus, newStatus } = item.changes as INeedHistoryNeedChanges;

      return `Изменен статус заявки c «${ENeedStatusLabel[oldStatus]}» на «${ENeedStatusLabel[newStatus]}».`;
    }
    case ENeedHistoryItemType.CreateSupplyCorrection: {
      const { reason, comment } = item.changes as INeedHistorySupplyCorrectionChanges;
      const description = `Запрос корректировки. Причина: «${ESupplyCorrectionLabel[reason]}».`;

      return comment ? `${description} Комментарий: «${comment}»` : description;
    }
    case ENeedHistoryItemType.ChangeSupplyPrice: {
      const { oldPrice, newPrice } = item.changes as INeedHistorySupplyPriceChanges;

      return `Изменена цена поставки c ${priceFormatter(oldPrice)} на ${priceFormatter(newPrice)}`;
    }
    default: {
      return '';
    }
  }
};

export const getNeedCardMenuItems = (nested?: boolean) => {
  const items: MenuItem[] = [
    {
      label: ENeedCardMenuItemLabel.info,
      key: ENeedCardMenuItem.Info,
    },
  ];

  if (!nested) {
    items.push({
      label: ENeedCardMenuItemLabel.supplies,
      key: ENeedCardMenuItem.Supplies,
    });
  }

  items.push({
    label: ENeedCardMenuItemLabel.updates,
    key: ENeedCardMenuItem.History,
  });

  return items;
};
