import axios from 'axios';
import {
  ISubdivisionCollectionDto,
  ISubdivisionListParams,
  ISubdivision,
  ISubdivisionCreatePayload,
  ISubdivisionDeletePayload,
  ISubdivisionUpdatePayload,
} from 'entities/Subdivisions/Subdivisions.models';

const basePath = 'subdivision';

export const subdivisionsTransport = {
  getSubdivisionList: (params: ISubdivisionListParams): Promise<ISubdivisionCollectionDto> =>
    axios.get(`${basePath}`, { params }),
  createSubdivision: (payload: ISubdivisionCreatePayload): Promise<ISubdivision> => axios.post(`${basePath}`, payload),
  deleteSubdivision: (payload: ISubdivisionDeletePayload): Promise<ISubdivision> =>
    axios.delete(`${basePath}`, { data: payload }),
  updateSubdivision: (payload: ISubdivisionUpdatePayload): Promise<ISubdivision> => axios.patch(`${basePath}`, payload),
};
