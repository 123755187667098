/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, FC, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { DEFAULT_PAGINATION_PAGE } from 'common/config';
import { ENeedCardMenuItem } from 'common/const/enum';
import { RootState } from 'app/store';
import { INeed } from 'entities/Need/Need.models';

export interface INeedContext {
  page: number;
  setPage: (value: number) => void;
  needCardCurrentMenuTab: ENeedCardMenuItem;
  setNeedCardCurrentMenuTab: (value: ENeedCardMenuItem) => void;
  openNeedCard: boolean;
  setOpenNeedCard: (value: boolean) => void;
  checkAll: boolean;
  fetchLoading: boolean;
  setFetchLoading: (value: boolean) => void;
  indeterminate: boolean;
  selectedNeedListIds: number[];
  selectedNeedList: INeed[];
  setSelectedNeedList: (value: INeed[]) => void;
}

export const NeedContext = createContext<INeedContext>({
  page: DEFAULT_PAGINATION_PAGE,
  setPage: () => {},
  needCardCurrentMenuTab: ENeedCardMenuItem.Info,
  setNeedCardCurrentMenuTab: () => {},
  openNeedCard: false,
  setOpenNeedCard: () => {},
  checkAll: false,
  fetchLoading: false,
  setFetchLoading: () => {},
  indeterminate: false,
  selectedNeedListIds: [],
  selectedNeedList: [],
  setSelectedNeedList: () => {},
});

export const useNeedContext = () => useContext(NeedContext);

export interface IComponentProps {
  children: React.ReactNode;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

export const Component: FC<AllType> = ({ children, needList }) => {
  const [page, setPage] = useState<number>(DEFAULT_PAGINATION_PAGE);
  const [needCardCurrentMenuTab, setNeedCardCurrentMenuTab] = useState<ENeedCardMenuItem>(ENeedCardMenuItem.Info);
  const [openNeedCard, setOpenNeedCard] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [selectedNeedList, setSelectedNeedList] = useState<INeed[]>([]);

  const selectedNeedListIds = selectedNeedList.map((need) => need.id);
  const checkAll = needList.length === selectedNeedList.length;
  const indeterminate = selectedNeedList.length > 0 && selectedNeedList.length < needList.length;

  return (
    <NeedContext.Provider
      value={{
        page,
        setPage,
        needCardCurrentMenuTab,
        setNeedCardCurrentMenuTab,
        openNeedCard,
        setOpenNeedCard,
        checkAll,
        fetchLoading,
        setFetchLoading,
        indeterminate,
        selectedNeedListIds,
        selectedNeedList,
        setSelectedNeedList,
      }}
    >
      {children}
    </NeedContext.Provider>
  );
};

const mapState = (state: RootState) => ({
  needList: state.needListState.data,
});

export const NeedProvider = connect(mapState)(Component);
