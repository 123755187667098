import React from 'react';
import { Button, InputNumber } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { countFormatter } from 'common/helpers/formatter.helper';

interface IComponentProps {
  count: number;
  readOnly?: boolean;
  onChange: (count: number) => void;
}

export const GoodsCounter: React.FC<IComponentProps> = ({ count, readOnly, onChange }) => {
  const onDecrementClick = () => {
    if (count === 1) {
      return;
    }

    onChange(count - 1);
  };

  const onIncrementClick = () => {
    onChange(count + 1);
  };

  const handleCountChange = (value: number | null) => {
    onChange(value ? value : 1);
  };

  return (
    <div className="goods-counter">
      <Button className="goods-counter__decrement" icon={<MinusOutlined />} onClick={onDecrementClick} disabled={readOnly} />

      <InputNumber
        className="goods-counter__input"
        value={count}
        disabled={readOnly}
        controls={false}
        min={1}
        formatter={countFormatter}
        onChange={handleCountChange}
      />

      <Button className="goods-counter__increment" icon={<PlusOutlined />} onClick={onIncrementClick} disabled={readOnly} />
    </div>
  );
};
