import React from 'react';
import { Button, Form, FormInstance, Input, Modal } from 'antd';
import { rules } from 'common/helpers/form.helper';
import { AuthCard } from 'entities/Auth/components/AuthCard';
import { ISubdivisionCreatePayload } from 'entities/Subdivisions/Subdivisions.models';

interface IComponentProps {
  open: boolean;
  form: FormInstance;
  loading: boolean;
  onSubmit: (values: ISubdivisionCreatePayload) => void;
  onCancelClick: () => void;
}

export const CreateSubdivisionModal: React.FC<IComponentProps> = ({ open, form, loading, onSubmit, onCancelClick }) => {
  return (
    <Modal className="create-subdivision-modal" open={open} footer={false} closeIcon={false}>
      <AuthCard
        form={form}
        loading={loading}
        body={
          <Form.Item label="Название подразделения" name="name" rules={[rules.required()]}>
            <Input.TextArea rows={4} placeholder="Введите название" />
          </Form.Item>
        }
        footer={
          <>
            <Button className="btn btn-default" onClick={onCancelClick}>
              Отмена
            </Button>

            <Button className="btn btn-primary" htmlType="submit">
              Добавить
            </Button>
          </>
        }
        onSubmit={onSubmit}
      />
    </Modal>
  );
};
