import { TabsProps } from 'antd';
import { EAddressModalTabKey } from 'common/const/enum';

export const stockFormTabs: TabsProps['items'] = [
  {
    key: EAddressModalTabKey.AutomaticSearch,
    label: 'Автоматический поиск',
  },
  {
    key: EAddressModalTabKey.ManualInput,
    label: 'Ручной ввод',
  },
];
