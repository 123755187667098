import React, { FC } from 'react';
import { Form, Select } from 'antd';
import { IFormItemRule, IOption } from 'common/models';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';

interface IComponentProps {
  className?: string;
  label?: string;
  name?: string | number;
  rules?: IFormItemRule[];
  options?: IOption[];
  disabled?: boolean;
  mode?: 'multiple';
}

export const FormSelect: FC<IComponentProps> = ({ className, label, name, rules, options, disabled, mode }) => {
  return (
    <Form.Item className={className} label={label} name={name} rules={rules}>
      <Select mode={mode} options={options} showSearch={false} suffixIcon={<ChevronIcon />} disabled={disabled} />
    </Form.Item>
  );
};
