import React from 'react';
import { Button, Skeleton } from 'antd';
import { propertyListToPresetList, renderPresetList } from 'common/helpers/preset.helper';
import { ThreeDotMenu } from 'common/components/ThreeDotMenu';
import { IWorkspacePosition } from 'entities/Workspaces/Workspaces.models';

interface IComponentProps {
  loading: boolean;
  positionList: IWorkspacePosition[];
  editPosition: (categoryId: number, positionId: number) => void;
  copyPosition: (categoryId: number, positionId: number) => void;
  deletePosition: (positionId: number) => void;
}

export const SimilarPositions: React.FC<IComponentProps> = (props) => {
  const { loading, positionList, editPosition, copyPosition, deletePosition } = props;

  if (loading) {
    return <Skeleton active />;
  }

  if (!positionList.length) {
    return null;
  }

  return (
    <div className="similar-positions">
      <div className="similar-positions__title">Похожие позиции в списке:</div>

      <div>
        {positionList
          .sort((a, b) => b.properties.length - a.properties.length)
          .map(({ positionId, properties, categoryId }) => {
            const presetList = propertyListToPresetList(properties);

            return (
              <div key={positionId} className="preset">
                <div className="preset__container">{renderPresetList(presetList)}</div>

                <ThreeDotMenu
                  items={[
                    {
                      key: 'edit',
                      label: <Button onClick={() => editPosition(categoryId, positionId)}>Редактировать</Button>,
                    },
                    {
                      key: 'copy',
                      label: <Button onClick={() => copyPosition(categoryId, positionId)}>Дублировать</Button>,
                    },
                    {
                      key: 'delete',
                      label: <Button onClick={() => deletePosition(positionId)}>Удалить</Button>,
                      className: 'delete',
                    },
                  ]}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};
