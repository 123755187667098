import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store';
import { sellersTransport } from 'entities/Sellers/Sellers.transport';
import { ISellerListState, ISellerListParams, ISeller } from 'entities/Sellers/Sellers.models';

export const sellerListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as ISellerListState,
  reducers: {
    setSellerList: (state, payload: ISeller[]) => ({ ...state, data: payload }),
    setSellerListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getSellerList(params: ISellerListParams) {
      dispatch.sellerListState.setSellerListLoading(true);

      try {
        const response = await sellersTransport.getSellerList(params);
        dispatch.sellerListState.setSellerList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.sellerListState.setSellerListLoading(false);
      }
    },
  }),
});
