import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Input, Menu, Select } from 'antd';
import { connect } from 'react-redux';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { useSupplyContext } from 'common/hooks/useSupplyContext';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { EOrderDirection, EOrderField, ESearchParams, ESupplyListMenuTab } from 'common/const/enum';
import { DEFAULT_EMPTY_VALUE, DEFAULT_LIST_LIMIT, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as ArrowDownIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { getNeedListFilterOptions } from 'entities/Need/Need.helper';
import { PendingSupplyListForManager } from 'entities/Supply/components/PendingSupplyListForManager';
import { ProcessingSupplyListForManager } from 'entities/Supply/components/ProcessingSupplyListForManager';
import { CompletedSupplyListForManager } from 'entities/Supply/components/CompletedSupplyListForManager';
import { ISupplyListPayload } from 'entities/Supply/Supply.models';
import { getSupplyListForManagerStatuses } from 'entities/Supply/Supply.helper';
import { supplyListForManagerMenuTabs } from 'entities/Supply/Supply.const';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    // state
    subdivisionList,
    userList,
    supplyList,
    supplyListLoading,
    supplyListCount,
    auth,
    // dispatch
    setSupplyList,
    getSupplySubdivisionListForManager,
    getSupplyUserList,
    getSupplyList,
    getSupplyListPart,
    getUserById,
  } = props;

  const { page, setPage, setFetchLoading, setSelectedSupplyList } = useSupplyContext();
  const { getSearchParam, setSearchParam } = useSearchParamsHook();

  const userId = auth?.access.userId;
  const subdivisionOptions = getNeedListFilterOptions.subdivision(subdivisionList);
  const userOptions = getNeedListFilterOptions.user(userList);
  const tab = getSearchParam(ESearchParams.Tab);

  const [payload, setPayload] = useState<ISupplyListPayload>({
    statuses: getSupplyListForManagerStatuses(tab),
    orderField: tab === ESupplyListMenuTab.Completed ? EOrderField.UpdatedAt : EOrderField.DeliveryDate,
    orderDirection: tab === ESupplyListMenuTab.Completed ? EOrderDirection.DESC : EOrderDirection.ASC,
    isCancelledBySeller: tab === ESupplyListMenuTab.Completed ? true : undefined,
    limit: DEFAULT_LIST_LIMIT,
    offset: DEFAULT_LIST_OFFSET,
  });

  const onCurrentMenuTabChange = (key: ESupplyListMenuTab) => {
    const newPayload = {
      statuses: getSupplyListForManagerStatuses(key),
      orderField: key === ESupplyListMenuTab.Completed ? EOrderField.UpdatedAt : EOrderField.DeliveryDate,
      orderDirection: key === ESupplyListMenuTab.Completed ? EOrderDirection.DESC : EOrderDirection.ASC,
      isCancelledBySeller: key === ESupplyListMenuTab.Completed ? true : undefined,
      limit: DEFAULT_LIST_LIMIT,
      offset: DEFAULT_LIST_OFFSET,
    };

    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedSupplyList([]);
    setSupplyList([]);
    setSearchParam(ESearchParams.Tab, key);
    setPayload(newPayload);
    getSupplyList(newPayload);
  };

  const onSubdivisionChange = async (value: number) => {
    const newPayload = { ...payload, subdivisionIds: value === DEFAULT_EMPTY_VALUE ? undefined : [value], userId: undefined };

    setPayload(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
    await getSupplyList(newPayload);
    await getSupplyUserList({ statuses: payload.statuses, subdivisionId: value === DEFAULT_EMPTY_VALUE ? undefined : value });
  };

  const onUserChange = (value: number) => {
    const newPayload = { ...payload, userId: value === DEFAULT_EMPTY_VALUE ? undefined : value };

    setPayload(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
    getSupplyList(newPayload);
  };

  const onSearchClick = () => {
    setPage(DEFAULT_PAGINATION_PAGE);
    getSupplyList(payload);
  };

  const renderContent = () => {
    switch (tab) {
      case ESupplyListMenuTab.Processing: {
        return <ProcessingSupplyListForManager list={supplyList} loading={supplyListLoading} />;
      }
      case ESupplyListMenuTab.Completed: {
        return <CompletedSupplyListForManager list={supplyList} loading={supplyListLoading} />;
      }
      default: {
        return (
          <PendingSupplyListForManager
            list={supplyList}
            loading={supplyListLoading}
            count={supplyListCount}
            payload={payload}
            onPayloadChange={setPayload}
          />
        );
      }
    }
  };

  useEffect(() => {
    const fetch = async () => {
      setFetchLoading(true);
      setPage(DEFAULT_PAGINATION_PAGE);

      if (userId) {
        await getUserById(userId);
      }

      await getSupplySubdivisionListForManager({ statuses: payload.statuses });
      await getSupplyUserList({ statuses: payload.statuses });
      await getSupplyList(payload);
      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <InfiniteScrollContainer
      canLoad={!supplyListLoading && supplyList.length < supplyListCount}
      page={page}
      scrollToTopTrigger={[payload]}
      onPageChange={setPage}
      callback={async (page) => {
        await getSupplyListPart({ ...payload, offset: DEFAULT_LIST_LIMIT * page });
      }}
    >
      {header}

      {navigation}

      <Menu
        items={supplyListForManagerMenuTabs}
        selectedKeys={[tab === null ? ESupplyListMenuTab.Pending : tab]}
        onClick={({ key }) => onCurrentMenuTabChange(key as ESupplyListMenuTab)}
        mode="horizontal"
      />

      <div className="need-list__filters">
        <Select
          rootClassName="redesign"
          popupClassName="need-list__filters-select-popup"
          className="need-list__filters-select"
          options={subdivisionOptions}
          value={payload.subdivisionIds ? payload.subdivisionIds[0] : DEFAULT_EMPTY_VALUE}
          onChange={onSubdivisionChange}
          placeholder="Выберите подразделение"
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownIcon />}
        />

        <Select
          rootClassName="redesign"
          popupClassName="need-list__filters-select-popup"
          className="need-list__filters-select"
          options={userOptions}
          value={payload.userId ? payload.userId : DEFAULT_EMPTY_VALUE}
          onChange={onUserChange}
          placeholder="Выберите пользователя"
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownIcon />}
        />

        <Input
          style={{ flex: 1 }}
          placeholder="Поиск"
          value={payload.search}
          onChange={(e) => setPayload((prev) => ({ ...prev, search: e.target.value }))}
        />

        <Button className="button-circle primary" icon={<SearchIcon />} onClick={onSearchClick} />
      </div>

      {renderContent()}
    </InfiniteScrollContainer>
  );
};

const mapState = (state: RootState) => ({
  subdivisionList: state.supplyListState.subdivisionList,
  userList: state.supplyListState.userList,
  supplyList: state.supplyListState.data,
  supplyListLoading: state.supplyListState.loading,
  supplyListCount: state.supplyListState.count,
  auth: state.authState.data,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setSupplyList: dispatch.supplyListState.setSupplyList,
  getSupplySubdivisionListForManager: dispatch.supplyListState.getSupplySubdivisionListForManager,
  getSupplyUserList: dispatch.supplyListState.getSupplyUserList,
  getSupplyList: dispatch.supplyListState.getSupplyList,
  getSupplyListPart: dispatch.supplyListState.getSupplyListPart,
  getUserById: dispatch.userState.getUserById,
});

export const SupplyListForManager = connect(mapState, mapDispatch)(Component);
