import React from 'react';
import { Table, Tooltip } from 'antd';
import { ExclamationCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import {
  getGoodsDeliveryTime,
  renderDeliveryTimeTooltipRecords,
  sortRemainsByDeliveryInDays,
} from 'common/helpers/delivery.helper';
import { toDataSourceMapper } from 'common/helpers/data.helper';
import { ReactComponent as TruckIcon } from 'app/assets/images/truck-gray.svg';
import { IGoods } from 'entities/Goods/Goods.models';
import { IBasketGoods } from 'entities/Basket/Basket.models';
import { ISpecificationGoods } from 'entities/Specifications/Specifications.models';
import { IRequestGoods } from 'entities/Requests/Requests.models';

interface IComponentProps {
  goods: IGoods | IBasketGoods | ISpecificationGoods | IRequestGoods;
  count: number;
  isBasket?: boolean;
}

export const GoodsDeliveryTime: React.FC<IComponentProps> = ({ count, goods, isBasket }) => {
  const remains = sortRemainsByDeliveryInDays(goods.remains);

  const dataSource = toDataSourceMapper(remains);
  const columns = renderDeliveryTimeTooltipRecords();
  const title = (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      size="small"
      showHeader={false}
      locale={{ emptyText: 'Уточнить у поставщика' }}
    />
  );
  const { deliveryTime, totalCount } = getGoodsDeliveryTime(count, remains);

  if (isBasket) {
    return (
      <div className="goods-delivery-time">
        <Tooltip overlayClassName="goods-delivery-time__tooltip" title={title}>
          {totalCount && totalCount >= count ? <InfoCircleFilled /> : <ExclamationCircleFilled />}
        </Tooltip>

        <span className="goods-delivery-time__time">Сроки доставки: {deliveryTime}</span>
      </div>
    );
  }

  return (
    <div className="goods-delivery-time">
      <TruckIcon />

      <span className="goods-delivery-time__time">{deliveryTime}</span>

      <Tooltip overlayClassName="goods-delivery-time__tooltip" title={title}>
        {totalCount && totalCount >= count ? <InfoCircleFilled /> : <ExclamationCircleFilled />}
      </Tooltip>
    </div>
  );
};
