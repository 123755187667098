import React, { FC } from 'react';
import { Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { ReactComponent as MoreIcon } from 'app/assets/images/more.svg';

interface IComponentProps {
  items: MenuProps['items'];
}

export const ThreeDotMenu: FC<IComponentProps> = ({ items }) => {
  return (
    <Dropdown.Button
      className="three-dot-menu"
      overlayClassName="three-dot-menu__dropdown"
      menu={{ items }}
      icon={<MoreIcon />}
    />
  );
};
