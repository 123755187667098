import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Form, Input, InputNumber, Row, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Avatar } from 'common/components/Avatar';
import {
  EFormFieldMessage,
  EMessage,
  EModalCollectionKey,
  ENotificationType,
  ESearchParams,
  EUserRole,
  EUserStatusLabel,
} from 'common/const/enum';
import { CHARACTER_LIMIT_MIN, PHONE_PATTERN } from 'common/config';
import { IFormValues } from 'common/models';
import { useDebounce } from 'common/hooks/useDebounce';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { rules } from 'common/helpers/form.helper';
import { phoneFormatter } from 'common/helpers/formatter.helper';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as CloseIcon } from 'app/assets/images/close.svg';
import { UserFrame } from 'entities/Users/components/UserFrame';
import { getUserName, getUserStatusColor } from 'entities/Users/Users.helper';
import { UserSubdivision } from 'entities/Users/components/UserSubdivision';
import { UserWorkspace } from 'entities/Users/components/UserWorkspace';
import { Notification } from 'entities/Modal/components/Notification';

interface IComponentProps {
  userId: string | null;
  onCloseClick?: () => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const UserCardComponent: React.FC<AllType> = (props) => {
  const {
    userId,
    authState,
    userState,
    subdivisionListState,
    deleteUserModalParams,
    onCloseClick,
    getUserById,
    updateUserByAdmin,
    deleteUser,
    getSubdivisionList,
    showModal,
    hideModal,
  } = props;

  const { data: auth } = authState;
  const { data: user, loading: userLoading } = userState;
  const { data: subdivisionList, loading: subdivisionListLoading } = subdivisionListState;
  const { open } = deleteUserModalParams;

  const { removeSearchParam } = useSearchParamsHook();
  const [formIsChanged, setFormIsChanged] = useState<boolean>(false);
  const [firstNameValue, setFirstNameValue] = useState<string>('');
  const [lastNameValue, setLastNameValue] = useState<string>('');
  const [phoneValue, setPhoneValue] = useState<string>('');
  const [form] = useForm();
  const formRef = useRef(null);

  const userName = getUserName(user?.firstName, user?.lastName);
  const userRoles = user?.roles as EUserRole[];
  const isAccountOwner = user?.isAccountOwner;
  const isSeller = auth?.access.isSeller;

  const onDeleteClick = () => {
    showModal(EModalCollectionKey.DeleteUser);
  };

  const onDeleteUserModalCancelClick = () => {
    hideModal(EModalCollectionKey.DeleteUser);
  };

  const onDeleteUserModalConfirmClick = () => {
    if (userId) {
      deleteUser({
        id: Number(userId),
        onSuccess: () => {
          hideModal(EModalCollectionKey.DeleteUser);
          removeSearchParam(ESearchParams.UserId);
        },
      });
    }
  };

  const onFormValuesChange = (_: IFormValues, allValues: IFormValues) => {
    setFormIsChanged(true);

    Object.entries(allValues).forEach(([key, value]) => {
      switch (key) {
        case 'firstName': {
          setFirstNameValue(value);
          break;
        }
        case 'lastName': {
          setLastNameValue(value);
          break;
        }
        case 'phone': {
          setPhoneValue(value);
          break;
        }
        default: {
          break;
        }
      }
    });
  };

  useEffect(() => {
    getSubdivisionList({ limit: 0 });
  }, []);

  useEffect(() => {
    if (userId) {
      setFormIsChanged(false);
      getUserById(Number(userId));
    }
  }, [userId]);

  useEffect(() => {
    if (user && !formIsChanged) {
      form.setFieldsValue({
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
      });
    }
  }, [user, formIsChanged]);

  useDebounce(() => {
    if (formRef.current && user && formIsChanged) {
      const errors = form
        .getFieldsError()
        .map((item) => item.errors)
        .flat();

      if (!errors.length) {
        updateUserByAdmin({
          id: user.id,
          firstName: firstNameValue,
          lastName: lastNameValue,
          phone: phoneValue,
        });
      }
    }
  }, [form, firstNameValue, lastNameValue, phoneValue, formIsChanged]);

  if (!userId || !user) {
    return null;
  }

  return (
    <div className="user-card">
      <Spin spinning={userLoading || subdivisionListLoading}>
        <div className="user-card__wrapper">
          <div className="user-card__header">
            <Avatar size={64} firstName={user.firstName} lastName={user.lastName} />

            <div>
              <div className="user-card__header_user-name-container">
                <div className="user-card__header_user-name">{userName}</div>

                <div className={`status ${getUserStatusColor(user.status)}`}>{EUserStatusLabel[user.status]}</div>
              </div>

              <div className="user-card__header_user-email">{user.email}</div>
            </div>

            {onCloseClick && (
              <div className=" user-card__header-close">
                <Button className="btn btn-icon" icon={<CloseIcon />} onClick={onCloseClick} />
              </div>
            )}
          </div>

          {!!userRoles?.length && (
            <UserFrame
              roles={userRoles}
              isAccountOwner={isAccountOwner}
              isSeller={isSeller}
              updateUserRoles={(roles) => {
                updateUserByAdmin({ id: user.id, roles });
              }}
            />
          )}

          <Form
            className="user-card__form"
            form={form}
            ref={formRef}
            layout="vertical"
            requiredMark={false}
            onValuesChange={onFormValuesChange}
          >
            <Row gutter={40}>
              <Col xs={24} xxl={12}>
                <Form.Item
                  label="Имя"
                  name="firstName"
                  rules={[rules.required(), rules.min(CHARACTER_LIMIT_MIN, EFormFieldMessage.CharacterLimitMin)]}
                >
                  <Input placeholder="Введите имя" />
                </Form.Item>
              </Col>

              <Col xs={24} xxl={12}>
                <Form.Item
                  label="Фамилия"
                  name="lastName"
                  rules={[rules.required(), rules.min(CHARACTER_LIMIT_MIN, EFormFieldMessage.CharacterLimitMin)]}
                >
                  <Input placeholder="Введите фамилию" />
                </Form.Item>
              </Col>

              <Col xs={24} xxl={12}>
                <Form.Item
                  label="Контактный телефон"
                  name="phone"
                  rules={[rules.required(), rules.pattern(PHONE_PATTERN, EFormFieldMessage.InvalidPhoneFormat)]}
                >
                  <InputNumber placeholder="Введите номер телефона" formatter={phoneFormatter} prefix="+" controls={false} />
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Col xs={24}>
            <div className="user-card__form_item">
              <UserSubdivision subdivisions={subdivisionList} user={user} loading={userLoading} disabled={isSeller} />
            </div>
          </Col>

          <Col xs={24}>
            <div className="user-card__form_item">
              <UserWorkspace user={user} loading={userLoading} disabled={isSeller} />
            </div>
          </Col>

          <div className="user-card__footer">
            <Button
              className="btn btn-red"
              onClick={onDeleteClick}
              disabled={auth?.access.userId === user.id || user.isAccountOwner}
            >
              Удалить пользователя
            </Button>
          </div>
        </div>
      </Spin>

      <Notification
        open={open}
        type={ENotificationType.Warning}
        description={EMessage.UserWillBeRemovedFromAccount}
        loading={userLoading}
        onConfirmClick={onDeleteUserModalConfirmClick}
        onCancelClick={onDeleteUserModalCancelClick}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  authState: state.authState,
  userState: state.userState,
  subdivisionListState: state.subdivisionListState,
  deleteUserModalParams: state.modalCollection.deleteUserModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getUserById: dispatch.userState.getUserById,
  updateUserByAdmin: dispatch.userState.updateUserByAdmin,
  deleteUser: dispatch.userState.deleteUser,
  getSubdivisionList: dispatch.subdivisionListState.getSubdivisionList,
  showModal: dispatch.modalCollection.showModal,
  hideModal: dispatch.modalCollection.hideModal,
});

export const UserCard = connect(mapState, mapDispatch)(UserCardComponent);
