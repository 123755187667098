import React from 'react';
import { Button, Form, FormInstance, Input, Modal } from 'antd';
import { rules } from 'common/helpers/form.helper';
import { AuthCard } from 'entities/Auth/components/AuthCard';
import { IWorkspaceCreatePayload } from 'entities/Workspaces/Workspaces.models';

interface IComponentProps {
  open: boolean;
  form: FormInstance;
  loading: boolean;
  error: string | null;
  onSubmit: (values: IWorkspaceCreatePayload) => void;
  onCancelClick: () => void;
}

export const CreateWorkspaceModal: React.FC<IComponentProps> = ({ open, form, loading, error, onSubmit, onCancelClick }) => {
  return (
    <Modal className="modal create-workspace-modal" open={open} footer={false} closeIcon={false}>
      <AuthCard
        form={form}
        loading={loading}
        body={
          <>
            <Form.Item label="Название карты рабочего места" name="name" rules={[rules.required()]}>
              <Input.TextArea rows={4} placeholder="Введите название" />
            </Form.Item>

            {!!error && (
              <div className="auth-card__error-block">
                <span className="auth-card__error-block-item">{error}</span>
              </div>
            )}
          </>
        }
        footer={
          <>
            <Button className="btn btn-default" onClick={onCancelClick}>
              Отмена
            </Button>

            <Button className="btn btn-primary" htmlType="submit">
              Добавить
            </Button>
          </>
        }
        onSubmit={onSubmit}
      />
    </Modal>
  );
};
