import React, { FC } from 'react';
import { Button, Checkbox, Spin, Table } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { connect } from 'react-redux';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { groupList } from 'common/helpers/data.helper';
import { useSupplyContext } from 'common/hooks/useSupplyContext';
import { ESupplyStatus } from 'common/const/enum';
import { COLLECTION_LIMIT_5, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE } from 'common/config';
import { RootDispatch } from 'app/store';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as ArrowUpShortIcon } from 'app/assets/images/redesign/arrow-up-short.svg';
import { NeedCard } from 'entities/Need/components/NeedCard';
import { SupplyCard } from 'entities/Supply/components/SupplyCard';
import { SupplyListEmpty } from 'entities/Supply/components/SupplyListEmpty';
import { EditSupplyCard } from 'entities/Supply/components/EditSupplyCard';
import { ISupply, ISupplyListPayload } from 'entities/Supply/Supply.models';
import { renderGroupedSupplyListRecords, renderSupplyListRecords } from 'entities/Supply/Supply.helper';

interface IComponentProps {
  list: ISupply[];
  loading: boolean;
  count: number;
  payload: ISupplyListPayload;
  onPayloadChange: (value: ISupplyListPayload) => void;
}

type AllType = ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    list,
    loading,
    count,
    payload,
    onPayloadChange,
    // dispatch
    filterSupplyList,
    getSupplyList,
    changeSupplyListStatus,
    getSupplyById,
    getNeedById,
  } = props;

  const { setPage, setOpenSupplyCard, fetchLoading, selectedSupplyList, setSelectedSupplyList } = useSupplyContext();

  const selectedSupplyListIds = selectedSupplyList.map((need) => need.id);
  const checkAll = list.length === selectedSupplyList.length;
  const indeterminate = selectedSupplyList.length > 0 && selectedSupplyList.length < list.length;
  const groupedSupplyList = groupList.byUserId(list);

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setSelectedSupplyList(e.target.checked ? list.filter(({ address }) => !!address) : []);
  };

  const onSupplySelectionChange = (supply: ISupply, checked: boolean) => {
    const newSelectedSupplyList = checked
      ? [...selectedSupplyList, supply]
      : selectedSupplyList.filter((supplyItem) => supplyItem.id !== supply.id);

    setSelectedSupplyList(newSelectedSupplyList);
  };

  const onGroupSelectionChange = (checked: boolean, groupSupplyList: ISupply[]) => {
    const groupSupplyListIds = groupSupplyList.map((supply: ISupply) => supply.id);
    const selectedGroupSupplyList = groupSupplyList.filter((supply: ISupply) => selectedSupplyListIds.includes(supply.id));
    const indeterminate = selectedGroupSupplyList.length > 0 && selectedGroupSupplyList.length < groupSupplyList.length;

    if (checked) {
      setSelectedSupplyList([...selectedSupplyList, ...groupSupplyList]);
    }

    if (!checked) {
      setSelectedSupplyList(selectedSupplyList.filter((supply) => !groupSupplyListIds.includes(supply.id)));
    }

    if (indeterminate) {
      setSelectedSupplyList([
        ...selectedSupplyList,
        ...groupSupplyList.filter((supply: ISupply) => !selectedGroupSupplyList.includes(supply)),
      ]);
    }
  };

  const onChangeStatusSuccess = (ids: number[]) => {
    const filteredSupplyList = list.filter((supply) => !ids.includes(supply.id));
    const filteredSelectedSupplyList = selectedSupplyList.filter((supply) => !ids.includes(supply.id));

    filterSupplyList(ids);
    setSelectedSupplyList(filteredSelectedSupplyList);

    if (filteredSupplyList.length <= COLLECTION_LIMIT_5 && count > filteredSupplyList.length) {
      const newPayload = { ...payload, offset: DEFAULT_LIST_OFFSET };

      setPage(DEFAULT_PAGINATION_PAGE);
      onPayloadChange(newPayload);
      getSupplyList(newPayload);
    }
  };

  const onSupplyStatusChange = () => {
    changeSupplyListStatus({
      ids: selectedSupplyListIds,
      status: ESupplyStatus.Processing,
      onSuccess: () => onChangeStatusSuccess(selectedSupplyListIds),
    });
  };

  return (
    <>
      {!!list.length && (
        <div className="need-list__actions">
          <Checkbox checked={checkAll} indeterminate={indeterminate} onChange={onCheckAllChange}>
            Выбрать поставки
          </Checkbox>

          {!!selectedSupplyList.length && (
            <Button className="button-s primary" onClick={onSupplyStatusChange}>
              Передать в учётную систему
            </Button>
          )}
        </div>
      )}

      <Spin wrapperClassName="need-list__spin" spinning={fetchLoading || loading} indicator={<SpinIndicator />}>
        {groupedSupplyList.map((group, index) => {
          return (
            <Table
              key={index}
              className="need-list__table need-list__table-expandable"
              rowClassName="need-list__table-expandable-row"
              columns={renderGroupedSupplyListRecords(selectedSupplyListIds, onGroupSelectionChange)}
              dataSource={group.dataSource.map((item, index) => ({ ...item, key: index }))}
              expandable={{
                expandIconColumnIndex: 7,
                columnWidth: 40,
                expandIcon: ({ expanded, onExpand, record }) => {
                  return (
                    <Button
                      className="button-icon need-list__table-item-expand-btn"
                      icon={expanded ? <ArrowUpShortIcon /> : <ArrowDownShortIcon />}
                      onClick={(e) => onExpand(record, e)}
                    />
                  );
                },
                expandedRowRender: ({ dataSource }) => {
                  return (
                    <Table
                      className="table-hover need-list__table"
                      columns={renderSupplyListRecords()}
                      dataSource={dataSource.map((item) => ({ ...item, key: item.id }))}
                      pagination={false}
                      showHeader={false}
                      rowSelection={{
                        selectedRowKeys: selectedSupplyListIds,
                        onSelect: (record, checked) => onSupplySelectionChange(record, checked),
                        columnWidth: 40,
                        getCheckboxProps: ({ address }) => ({ disabled: !address }),
                      }}
                      onRow={({ id, supplyNeedId }) => ({
                        onClick: async () => {
                          await getSupplyById({ id, onSuccess: () => setOpenSupplyCard(true) });
                          await getNeedById({ id: supplyNeedId });
                        },
                      })}
                    />
                  );
                },
              }}
              pagination={false}
              showHeader={false}
            />
          );
        })}

        <SupplyListEmpty open={!loading && !fetchLoading && !groupedSupplyList.length} forSeller />
      </Spin>

      <SupplyCard onChangeStatusSuccess={onChangeStatusSuccess} />

      <NeedCard nested />

      <EditSupplyCard />
    </>
  );
};

const mapDispatch = (dispatch: RootDispatch) => ({
  filterSupplyList: dispatch.supplyListState.filterSupplyList,
  getSupplyList: dispatch.supplyListState.getSupplyList,
  changeSupplyListStatus: dispatch.supplyListState.changeSupplyListStatus,
  getSupplyById: dispatch.supplyState.getSupplyById,
  getNeedById: dispatch.needState.getNeedById,
});

export const CreatedSupplyListForSeller = connect(null, mapDispatch)(Component);
