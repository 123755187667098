import React from 'react';
import { Form, Input } from 'antd';
import { Rule } from 'antd/lib/form';
import { ReactComponent as ShowIcon } from 'app/assets/images/visibility-show.svg';
import { ReactComponent as HideIcon } from 'app/assets/images/visibility-hide.svg';

interface IComponentProps {
  label?: string;
  name: string;
  placeholder?: string;
  dependencies?: string[];
  rules?: Rule[];
}

export const FormInputPassword: React.FC<IComponentProps> = ({ label, name, placeholder, dependencies, rules }) => (
  <Form.Item label={label} name={name} dependencies={dependencies} rules={rules}>
    <Input.Password placeholder={placeholder} iconRender={(visible) => (visible ? <ShowIcon /> : <HideIcon />)} />
  </Form.Item>
);
