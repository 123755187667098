import React from 'react';
import { message } from 'antd';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close-green.svg';
import { ReactComponent as SuccessIcon } from 'app/assets/images/redesign/success.svg';

export const showLoadingMessage = (duration?: number, content = 'Сохранение...') => {
  message.open({
    key: 'updatable',
    type: 'loading',
    content,
    duration,
  });
};

export const showSuccessMessage = (content?: React.ReactNode) => {
  message.open({
    key: 'updatable',
    // TODO
    content: content ? content : 'Данные сохранены',
    icon: <SuccessIcon />,
  });
};

export const showErrorMessage = (error: string) => {
  message.open({
    key: 'updatable',
    type: 'error',
    content: error,
    icon: <CloseIcon />,
  });
};

export const destroyMessage = () => {
  message.destroy();
};

export const showMessage = ({ content, icon }: { content: React.ReactNode; icon: React.ReactNode }) => {
  message.open({
    key: 'updatable',
    content,
    icon,
  });
};
