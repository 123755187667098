import React from 'react';
import { Button, Modal, Spin, Tree } from 'antd';
import type { DataNode } from 'antd/es/tree';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';

const { DirectoryTree } = Tree;

interface IComponentProps {
  open: boolean;
  treeData: DataNode[];
  subdivisionId?: number;
  loading: boolean;
  onSelect: (subdivisionId: number) => void;
  onConfirmClick: () => void;
  onCancelClick: () => void;
}

export const SelectSubdivisionModal: React.FC<IComponentProps> = (props) => {
  const { open, treeData, subdivisionId, loading, onSelect, onConfirmClick, onCancelClick } = props;

  return (
    <Modal className="modal" open={open} footer={false} closeIcon={false} width={573}>
      <Spin spinning={loading}>
        <div className="modal-content">
          <DirectoryTree
            className="tree tree__bold-title tree__with-selected-icon"
            treeData={treeData}
            onSelect={(keys) => onSelect(Number(keys[0]))}
            defaultExpandAll
            switcherIcon={<ChevronIcon />}
            selectedKeys={subdivisionId ? [subdivisionId] : undefined}
          />

          <div className="modal-footer">
            <Button className="btn btn-default" onClick={onCancelClick}>
              Отменить
            </Button>

            <Button className="btn btn-default" onClick={onConfirmClick}>
              Подтвердить
            </Button>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};
