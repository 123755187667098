import axios from 'axios';
import {
  IDeliveryCityCollectionDto,
  IDeliveryCityListParams,
  IStockCity,
  IStockCityCollectionDto,
  IStockCityListParams,
  IStockCityCreatePayload,
  IStockCityDeletePayload,
  IStockCityUpdatePayload,
} from 'entities/Delivery/Delivery.models';

const basePath = 'delivery';

export const deliveryTransport = {
  getDeliveryCityList: (params: IDeliveryCityListParams): Promise<IDeliveryCityCollectionDto> =>
    axios.get(`${basePath}/buyer-cities`, { params }),
  getStockCityList: (params: IStockCityListParams): Promise<IStockCityCollectionDto> => axios.get(`${basePath}`, { params }),
  createStockCity: (payload: IStockCityCreatePayload): Promise<IStockCity> => axios.post(`${basePath}`, payload),
  updateStockCity: (payload: IStockCityUpdatePayload): Promise<IStockCity> => axios.patch(`${basePath}`, payload),
  deleteStockCity: (payload: IStockCityDeletePayload): Promise<IStockCity> => axios.delete(`${basePath}`, { data: payload }),
};
