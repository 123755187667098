import React from 'react';
import { AutoComplete, Button, Input, InputNumber, Modal, Spin, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { EAddressModalTabKey } from 'common/const/enum';
import { IAddressOption } from 'entities/Addresses/Addresses.models';

interface IComponentProps {
  open: boolean;
  options?: IAddressOption[];
  dataLoading: boolean;
  createLoading: boolean;
  value: string;
  disabled: boolean;
  tabKey: string;
  manualPostalCode: number | null;
  manualCity: string;
  onChange: (value: string) => void;
  onSelect: (value: string) => void;
  onAddClick: () => void;
  onCancelClick: () => void;
  onTabChange: (key: string) => void;
  onManualPostalCodeChange: (postalCode: number | null) => void;
  onManualCityChange: (value: string) => void;
}

export const AddAddressModal: React.FC<IComponentProps> = (props) => {
  const {
    open,
    options,
    dataLoading,
    createLoading,
    value,
    disabled,
    tabKey,
    manualPostalCode,
    manualCity,
    onChange,
    onSelect,
    onAddClick,
    onCancelClick,
    onTabChange,
    onManualPostalCodeChange,
    onManualCityChange,
  } = props;

  const items: TabsProps['items'] = [
    {
      key: EAddressModalTabKey.AutomaticSearch,
      label: 'Автоматический поиск',
      children: (
        <AutoComplete
          placeholder="Введите адрес"
          suffixIcon={dataLoading ? <Spin size="small" /> : null}
          options={options}
          value={value}
          onChange={onChange}
          onSelect={onSelect}
        />
      ),
    },
    {
      key: EAddressModalTabKey.ManualInput,
      label: 'Ручной ввод',
      children: (
        <div className="add-address-modal__container">
          <Input placeholder="Введите адрес" value={value} onChange={(e) => onChange(e.target.value)} />

          <Input placeholder="Введите город" value={manualCity} onChange={(e) => onManualCityChange(e.target.value)} />

          <InputNumber
            placeholder="Введите почтовый индекс"
            value={manualPostalCode}
            onChange={onManualPostalCodeChange}
            controls={false}
          />
        </div>
      ),
    },
  ];

  return (
    <Modal className="modal add-address-modal" open={open} footer={false} closeIcon={false} width={376}>
      <Spin spinning={createLoading}>
        <div className="modal-content">
          <div>
            <div className="add-address-modal__title">Добавить адрес</div>

            <Tabs activeKey={tabKey} items={items} onChange={onTabChange} />
          </div>

          <div className="modal-footer">
            <Button className="btn btn-default" onClick={onCancelClick}>
              Отмена
            </Button>

            <Button className="btn btn-default" onClick={onAddClick} disabled={disabled}>
              Добавить
            </Button>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};
