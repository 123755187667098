import { formValuesToPositions } from 'common/helpers/form.helper';
import { IFormValues } from 'common/models';

export const formValuesToGoodsListPayload = (formValues: IFormValues) => {
  const { isAvailableForPurchase, categoryId, search, ...rest } = formValues;

  return {
    limit: 0,
    categoryId: categoryId ? categoryId : undefined,
    isAvailableForPurchase: isAvailableForPurchase ? Boolean(isAvailableForPurchase) : undefined,
    search: search ? search : undefined,
    positions: formValuesToPositions(rest),
  };
};
