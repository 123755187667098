import React, { FC } from 'react';
import { Cascader } from 'antd';
import { cascaderOptionFilter } from 'common/helpers/cascader.helper';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { ReactComponent as SearchIcon } from 'app/assets/images/search.svg';
import { ICategory } from 'entities/Categories/Categories.models';
import { categoryListToCascaderOptions } from 'entities/Categories/Categories.helper';

interface IComponentProps {
  categoryList: ICategory[];
  value: number[];
  disabled: boolean;
  onChange?: (value: (string | number)[] | undefined) => void;
}

export const PositionCascader: FC<IComponentProps> = ({ categoryList, value, disabled, onChange }) => {
  const options = categoryListToCascaderOptions(categoryList);

  return (
    <Cascader
      options={options}
      value={value}
      onChange={onChange}
      className="position-cascader"
      placeholder="Выберите шаблон"
      showSearch={{ filter: cascaderOptionFilter }}
      suffixIcon={<SearchIcon />}
      expandIcon={<ChevronIcon />}
      disabled={disabled}
    />
  );
};
