import React, { FC } from 'react';
import { Dropdown } from 'antd';
import { NameLabel } from 'common/components/NameLabel';
import { Image } from 'common/components/Image';
import { Counter } from 'common/components/Counter-r';
import { countFormatter, priceFormatter } from 'common/helpers/formatter.helper';
import { ReactComponent as AffiliateIcon } from 'app/assets/images/redesign/affiliate.svg';
import { ReactComponent as UserIcon } from 'app/assets/images/redesign/user.svg';
import { ReactComponent as EditIcon } from 'app/assets/images/redesign/edit.svg';
import { ReactComponent as DeliveryIcon } from 'app/assets/images/redesign/delivery.svg';
import { getUserName } from 'entities/Users/Users.helper';
import { INeed } from 'entities/Need/Need.models';

interface IComponentProps {
  need: INeed;
  canEditCount: boolean;
  count: number;
  onCountChange: (value: number) => void;
}

export const NeedCardInfo: FC<IComponentProps> = ({ need, canEditCount, count, onCountChange }) => {
  const amount = need ? count * need.price : 0;
  const amountWithTaxes = need ? count * need.priceWithTaxes : 0;

  if (!need) {
    return null;
  }

  return (
    <div className="need-card-info">
      <div className="need-card-info__goods">
        <Image size={80} src={need.good.image} />

        <div style={{ flex: 1 }}>
          <div className="text-h4 need-card-info__goods-name" title={need.good.name}>
            {need.good.name}
          </div>

          <div className="need-card-info__goods-container">
            <span className="text-accent">{priceFormatter(need.price)}</span>

            {need.good.sellerCode && <span className="text-tag color-dark-grey">{need.good.sellerCode}</span>}
          </div>
        </div>
      </div>

      {canEditCount && (
        <div className="need-card-info__table-row-edit">
          <span className="text-body color-dark-grey need-card-info__table-row-edit-item-label">Количество:</span>

          <Dropdown
            overlayClassName="redesign"
            rootClassName="need-card-info__table-row-edit-item-dropdown"
            menu={{ items: [{ key: '' }] }}
            trigger={['click']}
            dropdownRender={() => {
              return (
                <div className="custom-dropdown" style={{ position: 'absolute' }}>
                  <span className="text-tag-accent color-bright-green custom-dropdown__title">Редактировать количество</span>

                  <Counter count={count} onChange={onCountChange} />
                </div>
              );
            }}
          >
            <div className="need-card-info__table-row-edit-item-container">
              <span className="text-body color-black">{countFormatter(count)}</span>

              <EditIcon />
            </div>
          </Dropdown>
        </div>
      )}

      <div className="need-card-info__table">
        {!canEditCount && (
          <div className="need-card-info__table-row">
            <span className="text-body color-dark-grey">Количество:</span>

            <span className="text-body color-black">{countFormatter(count)}</span>
          </div>
        )}

        <div className="need-card-info__table-row">
          <span className="text-body color-dark-grey">Стоимость товаров:</span>

          <span className="text-body">{priceFormatter(amount)}</span>
        </div>

        <div className="need-card-info__table-row">
          <span className="text-body color-dark-grey">Сумма НДС:</span>

          <span className="text-body">{priceFormatter(amountWithTaxes - amount)}</span>
        </div>

        <div className="need-card-info__table-row">
          <span className="text-body color-dark-grey">Стоимость с НДС:</span>

          <span className="text-body">{priceFormatter(amountWithTaxes)}</span>
        </div>

        <div className="need-card-info__table-row">
          <span className="text-body color-dark-grey">Подразделение:</span>

          <NameLabel icon={<AffiliateIcon />} name={need.subdivisionName} />
        </div>

        <div className="need-card-info__table-row">
          <span className="text-body color-dark-grey">Заявитель:</span>

          <NameLabel icon={<UserIcon />} name={getUserName(need.user.firstName, need.user.lastName)} iconClassName="user" />
        </div>
      </div>

      <div className="need-card-info__delivery">
        <span className="text-h4 need-card-info__delivery-title">Детали доставки</span>

        <div className="need-card-info__delivery-container">
          {need.address && (
            <div className="need-card-info__delivery-row">
              <span className="text-body color-dark-grey">Адрес:</span>

              <span className="text-body">{need.address.name}</span>
            </div>
          )}

          <div className="need-card-info__delivery-row">
            <span className="text-body color-dark-grey">Юридическое лицо:</span>

            <span className="text-body">{need.legalName}</span>
          </div>

          <div className="need-card-info__table-row">
            <span className="text-body color-dark-grey">Поставщик:</span>

            <NameLabel icon={<DeliveryIcon />} name={need.sellerName} />
          </div>
        </div>
      </div>
    </div>
  );
};
