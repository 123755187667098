import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { PrivateLayout } from 'common/components/PrivateLayout';
import { ERoute } from 'common/const/enum';
import { RootDispatch } from 'app/store';
import { AccountActivation } from 'entities/Invitation/components/AccountActivation';
import { Login } from 'entities/Auth/components/Login';
import { SendRestorePasswordEmail } from 'entities/Auth/components/SendRestorePasswordEmail';
import { RestorePasswordConfirmation } from 'entities/Auth/components/RestorePasswordConfirmation';
import { RestorePassword } from 'entities/Auth/components/RestorePassword';

type AllType = ReturnType<typeof mapDispatch>;

const AppComponent: React.FC<AllType> = ({ initAuth }) => {
  useEffect(() => {
    initAuth();
  }, []);

  return (
    <div className="app">
      <Routes>
        <Route path={ERoute.Activation} element={<AccountActivation />} />
        <Route path={ERoute.Login} element={<Login />} />
        <Route path={ERoute.SendRestorePasswordEmail} element={<SendRestorePasswordEmail />} />
        <Route path={ERoute.RestorePasswordConfirmation} element={<RestorePasswordConfirmation />} />
        <Route path={ERoute.RestorePassword} element={<RestorePassword />} />
        <Route path="*" element={<PrivateLayout />} />
      </Routes>
    </div>
  );
};

const mapDispatch = (dispatch: RootDispatch) => ({
  initAuth: dispatch.authState.initAuth,
});

export const App = connect(null, mapDispatch)(AppComponent);
