import React, { FC } from 'react';
import { Button, Input, Modal, Spin } from 'antd';

interface IComponentProps {
  open: boolean;
  loading: boolean;
  name: string;
  confirmBtnTitle: string;
  changeName: (value: string) => void;
  onCancelClick: () => void;
  onConfirmClick: () => void;
}

export const CatalogModal: FC<IComponentProps> = (props) => {
  const { open, loading, name, confirmBtnTitle, changeName, onCancelClick, onConfirmClick } = props;

  return (
    <Modal className="modal-gray" open={open} footer={false} closeIcon={false} width={360}>
      <Spin spinning={loading}>
        <div className="modal-gray__body">
          <div className="modal-gray__title">Название</div>

          <Input placeholder="Введите название" value={name} onChange={(e) => changeName(e.target.value)} />
        </div>

        <div className="modal-gray__footer">
          <Button className="btn btn-default" onClick={onCancelClick}>
            Отмена
          </Button>

          <Button className="btn btn-primary" onClick={onConfirmClick} disabled={!name.trim().length}>
            {confirmBtnTitle}
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};
