import { createModel } from '@rematch/core';
import { IRequestSuccess } from 'common/models';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store';
import { workspacesTransport } from 'entities/Workspaces/Workspaces.transport';
import {
  IWorkspaceListState,
  IWorkspaceListParams,
  IWorkspace,
  IWorkspaceGrouped,
  IWorkspaceGroupedState,
  IWorkspaceState,
  IWorkspaceCopyPayload,
  IWorkspaceCreatePayload,
  IWorkspaceDeletePayload,
  IWorkspaceUpdatePayload,
} from 'entities/Workspaces/Workspaces.models';

export const workspaceListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: 0,
    loading: false,
  } as IWorkspaceListState,
  reducers: {
    setWorkspaceList: (state, payload: IWorkspace[]) => ({ ...state, data: payload }),
    setWorkspaceListCount: (state, payload: number) => ({ ...state, count: payload }),
    setWorkspaceListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getWorkspaceList(params: IWorkspaceListParams) {
      dispatch.workspaceListState.setWorkspaceListLoading(true);

      try {
        const response = await workspacesTransport.getWorkspaceList(params);
        dispatch.workspaceListState.setWorkspaceList(response.data);
        dispatch.workspaceListState.setWorkspaceListCount(response.count);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.workspaceListState.setWorkspaceListLoading(false);
      }
    },
  }),
});

export const workspaceState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
    error: null,
  } as IWorkspaceState,
  reducers: {
    setWorkspace: (state, payload: IWorkspace | null) => ({ ...state, data: payload }),
    setWorkspaceLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    setWorkspaceError: (state, payload: string | null) => ({ ...state, error: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getWorkspaceById(id: number) {
      dispatch.workspaceState.setWorkspaceLoading(true);

      try {
        const response = await workspacesTransport.getWorkspaceById(id);
        dispatch.workspaceState.setWorkspace(response);
        return response;
      } catch (error) {
        axiosErrorHandler(error);
        return null;
      } finally {
        dispatch.workspaceState.setWorkspaceLoading(false);
      }
    },
    async createWorkspace(payload: IWorkspaceCreatePayload) {
      dispatch.workspaceState.setWorkspaceLoading(true);

      try {
        const response = await workspacesTransport.createWorkspace(payload);
        dispatch.workspaceState.setWorkspace(response);
        dispatch.workspaceState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error, dispatch.workspaceState.setWorkspaceError);
      } finally {
        dispatch.workspaceState.setWorkspaceLoading(false);
      }
    },
    async updateWorkspace(payload: IWorkspaceUpdatePayload) {
      dispatch.workspaceState.setWorkspaceLoading(true);

      try {
        const response = await workspacesTransport.updateWorkspace(payload);
        dispatch.workspaceState.setWorkspace(response);
        dispatch.workspaceState.onSuccess(payload);
        dispatch.workspaceGroupedState.updateWorkspaceGrouped(response);
      } catch (error) {
        axiosErrorHandler(error, dispatch.workspaceState.setWorkspaceError);
      } finally {
        dispatch.workspaceState.setWorkspaceLoading(false);
      }
    },
    async copyWorkspace(payload: IWorkspaceCopyPayload) {
      dispatch.workspaceState.setWorkspaceLoading(true);

      try {
        const response = await workspacesTransport.copyWorkspace(payload);
        dispatch.workspaceState.setWorkspace(response);
        dispatch.workspaceState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error, dispatch.workspaceState.setWorkspaceError);
      } finally {
        dispatch.workspaceState.setWorkspaceLoading(false);
      }
    },
    async deleteWorkspace(payload: IWorkspaceDeletePayload) {
      dispatch.workspaceState.setWorkspaceLoading(true);

      try {
        await workspacesTransport.deleteWorkspace(payload);
        dispatch.workspaceState.onSuccess(payload);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.workspaceState.setWorkspaceLoading(false);
      }
    },
  }),
});

export const workspaceGroupedState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IWorkspaceGroupedState,
  reducers: {
    setWorkspaceGrouped: (state, payload: IWorkspaceGrouped) => ({ ...state, data: payload }),
    setWorkspaceGroupedLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getWorkspaceGrouped(id: number) {
      dispatch.workspaceGroupedState.setWorkspaceGroupedLoading(true);

      try {
        const response = await workspacesTransport.getWorkspaceGrouped(id);
        dispatch.workspaceGroupedState.setWorkspaceGrouped(response);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.workspaceGroupedState.setWorkspaceGroupedLoading(false);
      }
    },
    updateWorkspaceGrouped(payload: IWorkspace, models) {
      const workspaceGroupedData = models.workspaceGroupedState.data;

      if (workspaceGroupedData) {
        workspaceGroupedData.workspace = payload;
        dispatch.workspaceGroupedState.setWorkspaceGrouped(workspaceGroupedData);
      }
    },
  }),
});
