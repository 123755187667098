import { JIVO_SRC } from 'common/config';

export const createJivoScriptElement = () => {
  const script = document.createElement('script');
  script.src = JIVO_SRC;
  script.async = true;
  return script;
};

export const appendJivoScriptToDocument = (script: HTMLScriptElement) => {
  const head = document.head || document.getElementsByTagName('head')[0];
  head.appendChild(script);
};

export const destroyJivoScriptFromDocument = () => {
  const jivoIframeContainer = document.getElementById('jivo-iframe-container');

  if (jivoIframeContainer) {
    // @ts-ignore
    jivo_destroy();
  }
};
