import React from 'react';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { EDateFormat } from 'common/const/enum';
import { positionFormatter, priceFormatter } from 'common/helpers/formatter.helper';
import { IRequestItem } from 'entities/Requests/Requests.models';
import { getUserName } from 'entities/Users/Users.helper';

export const renderPreviousRequestsRecords = (onRepeatClick: (requestId: number) => void) => {
  return [
    {
      dataIndex: 'createdAt',
      render: (text) => dayjs(text).format(EDateFormat.FullDateDotSeparator),
      width: '120px',
    },
    {
      dataIndex: 'positionsCount',
      render: (_, record) => `${positionFormatter(record.positionsCount)} на сумму ${record.totalPrice.toFixed(2)} рублей`,
    },
    {
      dataIndex: 'action',
      render: (_, record) => (
        <Button className="btn previous-requests__table-item-repeat" onClick={() => onRepeatClick(record.id)}>
          Повторить
        </Button>
      ),
      width: '120px',
    },
  ] as ColumnsType<IRequestItem>;
};

export const requestListToDataSource = (data?: IRequestItem[]) => data?.map((item) => ({ key: item.id, ...item }));

export const renderRequestListRecords = (onRequestClick: (id: number) => void) => {
  const onCell = (record: IRequestItem) => ({ onClick: () => onRequestClick(record.id) });

  return [
    {
      dataIndex: 'createdAt',
      render: (text) => dayjs(text).format(EDateFormat.FullDateDotSeparator),
      width: '120px',
      onCell,
    },
    {
      dataIndex: 'subdivisionName',
      render: (text) => text,
      ellipsis: true,
      onCell,
    },
    {
      dataIndex: 'userName',
      render: (_, record) => getUserName(record.firstName, record.lastName),
      ellipsis: true,
      onCell,
    },
    {
      dataIndex: 'email',
      onCell,
    },
    {
      dataIndex: 'positionsCount',
      render: (text) => positionFormatter(text),
      onCell,
    },
    {
      dataIndex: 'totalPrice',
      render: (text) => priceFormatter(text),
      onCell,
    },
  ] as ColumnsType<IRequestItem>;
};
