import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Button, Input, Modal, Spin } from 'antd';
import { connect } from 'react-redux';
import { EModalCollectionKey } from 'common/const/enum';
import { RootDispatch, RootState } from 'app/store';
import { ISubdivisionUpdatePayload } from 'entities/Subdivisions/Subdivisions.models';

interface IComponentProps {
  subdivisionId?: number;
  subdivisionCode?: string;
  loading: boolean;
  updateSubdivision: (payload: ISubdivisionUpdatePayload) => Promise<void>;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

export const SubdivisionCodeComponent: FC<AllType> = (props) => {
  const { subdivisionId, subdivisionCode, loading, changeSubdivisionCodeModalParams, updateSubdivision, showModal, hideModal } =
    props;

  const { open } = changeSubdivisionCodeModalParams;

  const [value, setValue] = useState<string>('');

  const onSubdivisionCodeChangeClick = () => {
    showModal(EModalCollectionKey.ChangeSubdivisionCode);
  };

  const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onSubdivisionCodeCancelClick = () => {
    hideModal(EModalCollectionKey.ChangeSubdivisionCode);
  };

  const onSubdivisionCodeSetClick = () => {
    if (subdivisionId) {
      updateSubdivision({
        id: subdivisionId,
        code: value ? value : undefined,
        onSuccess: () => {
          onSubdivisionCodeCancelClick();
        },
      });
    }
  };

  useEffect(() => {
    if (open && subdivisionCode) {
      setValue(subdivisionCode);
    }
  }, [subdivisionCode, open]);

  return (
    <div className="subdivision-info__field">
      <div className="subdivision-info__label">Код подразделения</div>

      <div className="subdivision-info__field-container">
        <Input value={subdivisionCode} placeholder="Введите код подразделения" disabled />

        <Button className="btn btn-primary" onClick={onSubdivisionCodeChangeClick}>
          Изменить
        </Button>
      </div>

      <Modal className="modal-gray" open={open} footer={false} closeIcon={false} width={360}>
        <Spin spinning={loading}>
          <div className="modal-gray__body">
            <div className="modal-gray__title">Код подразделения</div>

            <Input value={value} onChange={onCodeChange} />
          </div>

          <div className="modal-gray__footer">
            <Button className="btn btn-default" onClick={onSubdivisionCodeCancelClick}>
              Отмена
            </Button>

            <Button className="btn btn-primary" onClick={onSubdivisionCodeSetClick}>
              Установить
            </Button>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

const mapState = (state: RootState) => ({
  changeSubdivisionCodeModalParams: state.modalCollection.changeSubdivisionCodeModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  showModal: dispatch.modalCollection.showModal,
  hideModal: dispatch.modalCollection.hideModal,
});

export const SubdivisionCode = connect(mapState, mapDispatch)(SubdivisionCodeComponent);
