import { IStockCity, IStockCityListState } from 'entities/Delivery/Delivery.models';
import { IStock } from 'entities/Stock/Stock.models';

export const toDeliveryData = (deliveryCities: string[], stockCities: IStockCity[]) => {
  return deliveryCities.map((city) => ({ city, stocks: stockCities.filter((stock) => stock.city === city) }));
};

export const stockListToSelectOptions = (stockList: IStock[], stockCityList: IStockCity[]) => {
  const stockIds = stockCityList.map((stock) => stock.stockId);

  return stockList
    .filter((stock) => !stockIds.includes(stock.id))
    .map((stock) => ({ label: stock.displayName, value: stock.id }));
};

export const updateStockCityListState = {
  addStockCity: (state: IStockCityListState, payload: IStockCity) => ({ ...state, data: [...state.data, payload] }),
  updateStockCity: (state: IStockCityListState, payload: IStockCity) => ({
    ...state,
    data: state.data.map((stockCity) => (stockCity.id === payload.id ? payload : stockCity)),
  }),
  deleteStockCity: (state: IStockCityListState, payload: number) => ({
    ...state,
    data: state.data.filter((stockCity) => stockCity.id !== payload),
  }),
};
