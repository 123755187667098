import { useEffect } from 'react';
import { DEFAULT_DEBOUNCE_DELAY } from 'common/config';

export const useDebounce = (callback: () => void, depth: any[], delay = DEFAULT_DEBOUNCE_DELAY) => {
  useEffect(() => {
    const submitForm = setTimeout(callback, delay);

    return () => clearTimeout(submitForm);
  }, [...depth]);
};
