import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, InputNumber, Select, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { DEFAULT_BASIC_DISCOUNT } from 'common/config';
import { discountFormatter } from 'common/helpers/formatter.helper';
import { EMessage } from 'common/const/enum';
import { useDebounce } from 'common/hooks/useDebounce';
import { toDataSourceMapper } from 'common/helpers/data.helper';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { brandCollectionToSelectOptions, renderDiscountsOnBrandsRecords } from 'entities/Discount/Discount.helper';

interface IComponentProps {
  id: number;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

export const DiscountComponent: FC<AllType> = (props) => {
  const {
    id,
    brandsDiscountListState,
    brandListState,
    discountState,
    brandDiscountState,
    getBrandsDiscountList,
    getBrandList,
    getDiscountById,
    updateDiscount,
    createBrandDiscount,
    deleteBrandDiscount,
  } = props;

  const { data: brandsDiscountList } = brandsDiscountListState;
  const { data: brandList } = brandListState;
  const { data: discount } = discountState;
  const { loading: brandDiscountLoading } = brandDiscountState;

  const [formIsChanged, setFormIsChanged] = useState<boolean>(false);
  const [basicDiscountValue, setBasicDiscountValue] = useState<number>(DEFAULT_BASIC_DISCOUNT);
  const [brand, setBrand] = useState<string | undefined>();
  const [discountValue, setDiscountValue] = useState<number>(DEFAULT_BASIC_DISCOUNT);

  const brandOptions = brandCollectionToSelectOptions(brandList);
  const dataSource = toDataSourceMapper(brandsDiscountList);
  const columns = renderDiscountsOnBrandsRecords(onDeleteBrandDiscountClick);

  const onBasicDiscountValueChange = (value: number | null) => {
    setFormIsChanged(true);
    setBasicDiscountValue(Number(value));
    setDiscountValue(Number(value));
  };

  const onCreateBrandDiscountClick = () => {
    if (discount && brand) {
      createBrandDiscount({
        discountId: id,
        brand: brand,
        discountValue: discountValue,
        onSuccess: () => {
          setBrand(undefined);
          setDiscountValue(discount.discountValue || DEFAULT_BASIC_DISCOUNT);
        },
      });
    }
  };

  function onDeleteBrandDiscountClick(id: number) {
    deleteBrandDiscount({ id });
  }

  useEffect(() => {
    if (discount && !formIsChanged) {
      setBasicDiscountValue(discount.discountValue || DEFAULT_BASIC_DISCOUNT);
      setDiscountValue(discount.discountValue || DEFAULT_BASIC_DISCOUNT);
    }
  }, [discount, formIsChanged]);

  useDebounce(() => {
    if (formIsChanged) {
      updateDiscount({ id, discountValue: basicDiscountValue });
    }
  }, [basicDiscountValue, formIsChanged]);

  const { contentLoading } = useContentLoader(async () => {
    await getDiscountById(id);
    await getBrandsDiscountList({ discountId: id, limit: 0 });
    await getBrandList();
  });

  if (contentLoading) {
    return <ContentLoader />;
  }

  return (
    <div className="discount">
      <div className="discount__title">
        <span className="discount__title-parent">Скидки</span>

        <span>{` / ${discount?.name}`}</span>
      </div>

      <div className="discount__basic-discount">
        <div>
          <div>Базовая скидка на все товары</div>

          <div className="discount__table-item-description">Применяется по умолчанию, если нет других условий</div>
        </div>

        <InputNumber
          value={basicDiscountValue}
          min={0}
          max={100}
          formatter={discountFormatter}
          onChange={onBasicDiscountValueChange}
          controls={false}
        />
      </div>

      <div className="discount__subtitle">Условные скидки</div>

      <div className="discount__create-discount-form">
        <Select
          className="discount__create-discount-form-select"
          popupClassName="discount__create-discount-form-select-popup"
          showSearch
          options={brandOptions}
          value={brand}
          onChange={setBrand}
          placeholder="Выберите бренд"
          suffixIcon={<ChevronIcon />}
        />

        <InputNumber
          value={discountValue}
          min={0}
          max={100}
          formatter={discountFormatter}
          onChange={(value) => setDiscountValue(Number(value))}
          controls={false}
        />

        <Button className="btn btn-primary" onClick={onCreateBrandDiscountClick} disabled={!brand}>
          <PlusOutlined />

          <span>Создать условие</span>
        </Button>
      </div>

      <Table
        className="discount__table"
        dataSource={dataSource}
        columns={columns}
        showHeader={false}
        pagination={false}
        loading={brandDiscountLoading}
        locale={{ emptyText: EMessage.DiscountsOnBrandsListIsEmpty }}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  brandsDiscountListState: state.brandsDiscountListState,
  brandListState: state.brandListState,
  discountState: state.discountState,
  brandDiscountState: state.brandDiscountState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getBrandsDiscountList: dispatch.brandsDiscountListState.getBrandsDiscountList,
  getBrandList: dispatch.brandListState.getBrandList,
  getDiscountById: dispatch.discountState.getDiscountById,
  updateDiscount: dispatch.discountState.updateDiscount,
  createBrandDiscount: dispatch.brandDiscountState.createBrandDiscount,
  deleteBrandDiscount: dispatch.brandDiscountState.deleteBrandDiscount,
});

export const Discount = connect(mapState, mapDispatch)(DiscountComponent);
