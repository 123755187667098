import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Image } from 'common/components/Image';
import { Counter } from 'common/components/Counter-r';
import { VendorCode } from 'common/components/VendorCode';
import { EMessage } from 'common/const/enum';
import { priceFormatter } from 'common/helpers/formatter.helper';
import { DEFAULT_GOODS_COUNT } from 'common/config';
import { IBasket, IBasketGoods, IBasketItem } from 'entities/Basket/Basket.models';
import { IRequest, IRequestGoods } from 'entities/Requests/Requests.models';
import { IGoods } from 'entities/Goods/Goods.models';
import { IAddress } from 'entities/Addresses/Addresses.models';
import { getUserName } from 'entities/Users/Users.helper';

export const basketGoodsToPayloadGoods = (goodsList: (IBasketGoods | IRequestGoods)[]) =>
  goodsList.map((goods) => ({ goodId: goods.goodId, count: goods.count }));

export const getBasketOrderInfo = (
  selectedGoodsIds: number[],
  goodsList: (IBasketGoods | IRequestGoods)[],
  basket: IBasket | IRequest | null,
) => {
  const selectedGoodsCount = goodsList
    .filter((goods) => selectedGoodsIds.includes(goods.goodId))
    .reduce((acc, curr) => acc + curr.count, 0);
  const selectedGoodsPrice = goodsList
    .filter((goods) => selectedGoodsIds.includes(goods.goodId))
    .reduce((acc, curr) => acc + curr.price * curr.count, 0);
  const vat = goodsList
    .filter((goods) => selectedGoodsIds.includes(goods.goodId))
    .reduce((acc, curr) => acc + (curr.priceWithTaxes - curr.price) * curr.count, 0);
  const deliveryPrice = Number(basket?.deliveryPrice);
  const totalPrice = selectedGoodsPrice + deliveryPrice;
  const totalPriceWithVat = totalPrice + vat;

  return {
    selectedGoodsCount: `${selectedGoodsCount} шт.`,
    selectedGoodsPrice: priceFormatter(selectedGoodsPrice),
    vat: priceFormatter(vat),
    totalPriceWithVat,
  };
};

export const getGoodsWithIncreasedPriceLimitModalDescription = (goodsList: (IBasketGoods | IRequestGoods)[]) => {
  const goodsNames = goodsList.map((goods) => `“${goods.name}”`);

  return `Цена товара/ов ${goodsNames.join(
    ', ',
  )} превышает лимит, установленный для вашего аккаунта. Для оформления заказа требуется удалить его/их из корзины.`;
};

export const getBasketDeliveryAddressAlertTitle = (subdivisionId?: number, optionsLength?: number, addressId?: number) => {
  if (!subdivisionId) {
    return EMessage.AccountIsNotLinkedToSubdivision;
  } else if (!optionsLength) {
    return EMessage.SubdivisionDoesNotHaveDeliveryAddresses;
  } else if (!addressId) {
    return EMessage.MustSelectDeliveryAddress;
  } else {
    return null;
  }
};

export const getBasketLegalAlertTitle = (subdivisionId?: number, optionsLength?: number, legalId?: number) => {
  if (!subdivisionId) {
    return EMessage.AccountIsNotLinkedToSubdivision;
  } else if (!optionsLength) {
    return EMessage.SubdivisionDoesNotHaveLegalEntities;
  } else if (!legalId) {
    return EMessage.MustSelectLegal;
  } else {
    return null;
  }
};

export const activeBasketListDataToPayload = (data?: IBasketItem[]) => data?.map((item) => ({ key: item.id, ...item }));

export const renderActiveBasketListRecords = () => {
  return [
    {
      dataIndex: 'id',
      render: (text) => `№ ${text}`,
    },
    {
      dataIndex: 'accountName',
    },
    {
      dataIndex: 'subdivisionName',
    },
    {
      dataIndex: 'user',
      render: (_, record) => getUserName(record.firstName, record.lastName),
    },
    {
      dataIndex: 'email',
    },
    {
      dataIndex: 'phone',
    },
  ] as ColumnsType<IBasketItem>;
};

export const getActiveBasketListFilterOptions = (data?: IBasketItem[]) => {
  const basketsIdsOptions = data?.map((item) => ({ label: `${item.id}`, value: item.id }));
  const basketsUsersOptions = data?.map((item) => ({ label: `${item.firstName} ${item.lastName}`, value: item.id }));

  return { basketsIdsOptions, basketsUsersOptions };
};

export const getBasketProgressColor = (percent: number) => {
  if (percent >= 95) {
    return '#EC6665';
  } else if (percent >= 60) {
    return '#F6C543';
  } else {
    return '#69A443';
  }
};

export const renderBasketRecords = (onCountChange: (id: number, value: number) => void) => {
  return [
    {
      key: 'image',
      render: (_, { image }) => <Image size={104} src={image} />,
      width: 144,
    },
    {
      key: 'name',
      render: (_, { name, sellerCode, vendorCode }) => {
        return (
          <div>
            <span className="text-h4-item-name basket__goods-list-item-name">{name}</span>

            <div>
              <span className="text-tag color-dark-grey" style={{ marginRight: 20 }}>
                {sellerCode}
              </span>

              <VendorCode code={vendorCode} />
            </div>
          </div>
        );
      },
    },
    {
      key: 'price',
      render: (_, { count, price }) => {
        return (
          <div>
            <span className="text-accent basket__goods-list-item-price">{priceFormatter(count * price)}</span>

            {count > DEFAULT_GOODS_COUNT && <span className="text-rag color-dark-grey">{`${priceFormatter(price)}/шт`}</span>}
          </div>
        );
      },
    },
    {
      key: 'count',
      render: (_, { goodId, count }) => {
        return <Counter count={count} responsive onChange={(value) => onCountChange(goodId, value)} />;
      },
      onCell: () => ({ style: { textAlign: 'right' } }),
    },
  ] as ColumnsType<IBasketGoods>;
};

export const getBasketGoodsPayload = (list: IBasketGoods[]) =>
  list.map((goods) => ({ goodId: goods.goodId, count: goods.count }));

export const filterBasketGoodsList = {
  byId: (list: IBasketGoods[], id: number) => list.filter((goods) => goods.goodId === id),
  notEqualId: (list: IBasketGoods[], id: number) => list.filter((goods) => goods.goodId !== id),
  byMaxGoodPrice: (list: IBasketGoods[], maxGoodPrice?: number) => {
    if (maxGoodPrice !== undefined) {
      return list.filter((goods) => goods.price < maxGoodPrice && goods.isAvailableForCustomer);
    }

    return list.filter((goods) => goods.isAvailableForCustomer);
  },
};

export const getBasketGoodsListDataSource = (list: IBasketGoods[]) => list.map((goods) => ({ key: goods.goodId, ...goods }));

export const getBasketGoodsListRowKeys = (list: IBasketGoods[]) => list.map((goods) => goods.goodId);

export const getBasketRequestCompositionInfo = (list: IBasketGoods[], availableLimit?: number) => {
  const count = list.reduce((acc, goods) => acc + goods.count, 0);
  const amount = list.reduce((acc, goods) => acc + goods.count * goods.price, 0);
  const amountWithTaxes = list.reduce((acc, goods) => acc + goods.count * goods.priceWithTaxes, 0);
  const availableAmount = availableLimit ? availableLimit - amount : 0;

  return { count, amount, amountWithTaxes, availableAmount, limitExceeded: availableLimit ? availableAmount < 0 : false };
};

export const goodsToBasketGoods = (goods: IGoods) => ({
  goodId: goods.id,
  count: DEFAULT_GOODS_COUNT,
  brand: goods.brand,
  image: goods.image,
  price: goods.price,
  priceWithTaxes: goods.priceWithTaxes,
  name: goods.name,
  externalId: goods.externalId,
  vendorCode: goods.vendorCode,
  sellerCode: goods.sellerCode,
  properties: goods.properties,
  remains: goods.remains,
  tnvd: goods.tnvd,
  isAvailableForCustomer: goods.isAvailableForCustomer,
});

export const renderBasketRequestCompositionRecords = () => [
  {
    key: 'label',
    dataIndex: 'label',
    width: 208,
  },
  {
    key: 'content',
    dataIndex: 'content',
  },
];

export const getBasketAddressOptions = (list: IAddress[], selectedAddressId: number | null) => {
  return list.map((address) => ({
    label: (
      <div>
        <div className={address.id === selectedAddressId ? 'text-h4-item-name' : 'text-lists'} style={{ marginBottom: 8 }}>
          {address.name}
        </div>

        {address.isMain && <div className="text-tag color-dark-grey">Основной адрес</div>}
      </div>
    ),
    value: address.id,
    desc: address.name,
  }));
};
