import { EColor, ERoute, EStorageKey, EUserFrameRole, EUserRole, EUserRoleLabel } from 'common/const/enum';
import { getStorageItem } from 'common/helpers/axios.helper';
import { IUser, IUserFrameItem, IUserListState } from 'entities/Users/Users.models';

export const getUsersFrameRoleOptions = (isAccountOwner?: boolean, isSeller?: boolean) => {
  return Object.values(EUserFrameRole).map((role) => ({
    label: EUserRoleLabel[role],
    value: role,
    disabled:
      role === EUserFrameRole.User ||
      (role === EUserFrameRole.AccountAdmin && isAccountOwner) ||
      (role === EUserFrameRole.SellerManager && !isSeller) ||
      (role === EUserFrameRole.Manager && isSeller),
  }));
};

export const getUserName = (firstName?: string, lastName?: string) => `${firstName || ''} ${lastName || ''}`;

export const getUserInitials = (firstName?: string, lastName?: string) =>
  `${firstName ? firstName[0] : ''}${lastName ? lastName[0] : ''}`;

export const getProfileFrames = (navigate: (path: string) => void, subdivisionId?: number, roles?: string[]) => {
  const frames: IUserFrameItem[] = [];
  const xBasketId = getStorageItem<string>(EStorageKey.XBasketId);

  if (!subdivisionId && !roles?.includes(EUserRole.SellerManager)) {
    return [];
  }

  frames.push(
    {
      title: 'Мои заказы',
      description: 'Информация о ранее размещенных заказах',
      onClick: () => navigate(ERoute.RequestsForUser),
    },
    {
      title: 'Активная корзина товаров',
      description: 'Нет незавершенных заказов',
      onClick: () => {
        if (xBasketId) {
          navigate(`/active-basket-list/${xBasketId}`);
        } else {
          navigate(ERoute.Basket);
        }
      },
    },
  );

  if (roles?.includes(EUserRole.Manager)) {
    frames.push({
      title: 'Заявки от сотрудников',
      description: 'Обработка заявок от сотрудников',
      onClick: () => navigate(ERoute.RequestsForManager),
    });
  }

  if (roles?.includes(EUserRole.SellerManager)) {
    frames.push({
      title: 'Заказы клиентов',
      description: 'Активные корзины клиентов и запросы на подбор товаров',
      onClick: () => navigate(ERoute.ActiveBasketList),
    });
  }

  return frames;
};

export const updateUserListState = {
  addUser: (state: IUserListState, payload: IUser) => ({ ...state, data: [...state.data, payload] }),
  updateUser: (state: IUserListState, payload: IUser) => ({
    ...state,
    data: state.data.map((user) => (user.id === payload.id ? payload : user)),
  }),
  deleteUser: (state: IUserListState, payload: number) => ({
    ...state,
    data: state.data.filter((user) => user.id !== payload),
  }),
};

export const getUserStatusColor = (status: string) => {
  if (status === 'active') {
    return EColor.Blue;
  }

  return EColor.Gray;
};
