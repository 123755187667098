import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { toDataSourceMapper } from 'common/helpers/data.helper';
import { EMessage, EModalCollectionKey } from 'common/const/enum';
import { RootDispatch, RootState } from 'app/store';
import { listDataToAddContractSelectOptions, renderContractListForSuperAdminRecords } from 'entities/Contract/Contract.helper';
import { AddContractModal } from 'entities/Modal/components/AddContractModal';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export const ContractListForSuperAdminComponent: FC<AllType> = (props) => {
  const {
    contractListState,
    contractState,
    sellerListState,
    buyerListState,
    openAddContractModal,
    getContractList,
    createContract,
    deleteContract,
    getSellerList,
    getBuyerList,
    showModal,
    hideModal,
  } = props;
  const { data: contractList, loading: contractListLoading } = contractListState;
  const { loading: contractLoading } = contractState;
  const { data: sellerList } = sellerListState;
  const { data: buyerList } = buyerListState;

  const [sellerId, setSellerId] = useState<number | null>(null);
  const [buyerId, setBuyerId] = useState<number | null>(null);

  const dataSource = toDataSourceMapper(contractList);
  const columns = renderContractListForSuperAdminRecords(onDeleteContractClick);
  const sellerOptions = listDataToAddContractSelectOptions(sellerList);
  const buyerOptions = listDataToAddContractSelectOptions(buyerList);

  function onDeleteContractClick(contractId: number) {
    deleteContract({ id: contractId });
  }

  const onAddContractClick = () => {
    showModal(EModalCollectionKey.AddContract);
  };

  const onAddContractModalCancel = () => {
    setSellerId(null);
    setBuyerId(null);
    hideModal(EModalCollectionKey.AddContract);
  };

  const onAddContractModalAdd = () => {
    if (sellerId && buyerId) {
      createContract({ sellerId, buyerId, onSuccess: onAddContractModalCancel });
    }
  };

  const { contentLoading } = useContentLoader(async () => {
    await getContractList({ limit: 0 });
    await getSellerList({ limit: 0 });
    await getBuyerList();
  });

  return (
    <div className="contract-list">
      {contentLoading ? (
        <ContentLoader />
      ) : (
        <>
          <div className="contract-list__title">Список контрактов</div>

          <Button className="btn btn-primary" onClick={onAddContractClick}>
            <PlusOutlined />

            <span>Контракт</span>
          </Button>

          <Table
            className="contract-list__table"
            dataSource={dataSource}
            columns={columns}
            showHeader={false}
            pagination={false}
            loading={contractListLoading || contractLoading}
            locale={{ emptyText: EMessage.ContractListForSuperAdminIsEmpty }}
          />
        </>
      )}

      <AddContractModal
        open={openAddContractModal}
        loading={contractLoading}
        sellerOptions={sellerOptions}
        buyerOptions={buyerOptions}
        sellerId={sellerId}
        buyerId={buyerId}
        changeSellerId={setSellerId}
        changeBuyerId={setBuyerId}
        onCancel={onAddContractModalCancel}
        onAdd={onAddContractModalAdd}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  contractListState: state.contractListState,
  contractState: state.contractState,
  sellerListState: state.sellerListState,
  buyerListState: state.buyerListState,
  openAddContractModal: state.modalCollection.addContractModalParams.open,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getContractList: dispatch.contractListState.getContractList,
  createContract: dispatch.contractState.createContract,
  deleteContract: dispatch.contractState.deleteContract,
  getSellerList: dispatch.sellerListState.getSellerList,
  getBuyerList: dispatch.buyerListState.getBuyerList,
  showModal: dispatch.modalCollection.showModal,
  hideModal: dispatch.modalCollection.hideModal,
});

export const ContractListForSuperAdmin = connect(mapState, mapDispatch)(ContractListForSuperAdminComponent);
