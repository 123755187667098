import React, { FC } from 'react';
import { ENeedStatus, ENeedStatusLabel } from 'common/const/enum';

interface IComponentProps {
  status: string;
}

export const NeedStatus: FC<IComponentProps> = ({ status }) => (
  <div className={`text-tag-accent need-status ${status}`}>{ENeedStatusLabel[status as ENeedStatus]}</div>
);
