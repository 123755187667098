import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Input, Layout, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { ENotificationType, EPositionFormMode } from 'common/const/enum';
import { IFormValues } from 'common/models';
import { rules } from 'common/helpers/form.helper';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { getWorkspacePositionPath } from 'common/helpers/router.helper';
import { RootDispatch, RootState } from 'app/store';
import { WorkspaceNavigationPanel } from 'entities/Workspaces/components/WorkspaceNavigationPanel';
import { CategoryCascaderModal } from 'entities/Modal/components/CategoryCascaderModal';
import { WorkspaceList } from 'entities/Workspaces/components/WorkspaceList';
import { Notification } from 'entities/Modal/components/Notification';
import { IWorkspacePosition } from 'entities/Workspaces/Workspaces.models';
import { getPositionName } from 'entities/Workspaces/Workspace.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const WorkspaceComponent: React.FC<AllType> = (props) => {
  const {
    categoryListState,
    workspaceState,
    workspaceGroupedState,
    getCategoryList,
    updateWorkspace,
    setWorkspaceError,
    getWorkspaceGrouped,
  } = props;
  const { data: categoryList } = categoryListState;
  const { loading: workspaceLoading, error: workspaceError } = workspaceState;
  const { data: workspaceGrouped, loading: workspaceGroupedLoading } = workspaceGroupedState;

  const [categoryId, setCategoryId] = useState<number>();
  const [position, setPosition] = useState<IWorkspacePosition | null>(null);
  const [openCategoryCascaderModal, setOpenCategoryCascaderModal] = useState<boolean>(false);
  const [openDeleteWorkspacePositionModal, setOpenDeleteWorkspacePositionModal] = useState<boolean>(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = useForm();
  const scrollRef = useRef<HTMLDivElement>(null);

  const workspaceId = Number(id);

  const onAddPositionClick = () => {
    setOpenCategoryCascaderModal(true);
  };

  const onCategoryCascaderModalCancelClick = () => {
    setOpenCategoryCascaderModal(false);
  };

  const onCategoryCascaderModalSelectClick = (value: (string | number)[]) => {
    const categoryId = value[value.length - 1];

    setOpenCategoryCascaderModal(false);
    navigate(getWorkspacePositionPath(EPositionFormMode.Create, workspaceId, Number(categoryId)));
  };

  const editPosition = (categoryId: number, positionId: number) => {
    navigate(getWorkspacePositionPath(EPositionFormMode.Edit, workspaceId, categoryId, positionId));
  };

  const copyPosition = (categoryId: number, positionId: number) => {
    navigate(getWorkspacePositionPath(EPositionFormMode.Copy, workspaceId, categoryId, positionId));
  };

  const deletePosition = (positionId: number) => {
    if (workspaceGrouped) {
      const position = workspaceGrouped.workspace.positions.find((position) => position.positionId === positionId);

      if (position) {
        setPosition(position);
        setOpenDeleteWorkspacePositionModal(true);
      }
    }
  };

  const onDeleteWorkspacePositionModalCancelClick = () => {
    setPosition(null);
    setOpenDeleteWorkspacePositionModal(false);
  };

  const onDeleteWorkspacePositionModalConfirmClick = () => {
    if (workspaceGrouped && position) {
      updateWorkspace({
        id: workspaceId,
        positions: workspaceGrouped.workspace.positions.filter((item) => item.positionId !== position.positionId),
        onSuccess: () => {
          setPosition(null);
          setOpenDeleteWorkspacePositionModal(false);
          getWorkspaceGrouped(workspaceId);
        },
      });
    }
  };

  const onSubmit = (values: IFormValues) => {
    setWorkspaceError(null);
    updateWorkspace({ id: workspaceId, name: values.name });
  };

  useEffect(() => {
    return () => {
      setWorkspaceError(null);
    };
  }, []);

  useEffect(() => {
    if (workspaceGrouped) {
      form.setFieldsValue({ name: workspaceGrouped.workspace.name });
    }
  }, [workspaceGrouped]);

  useEffect(() => {
    form.setFields([{ name: 'name', errors: workspaceError ? [workspaceError] : undefined }]);
  }, [workspaceError]);

  const { contentLoading } = useContentLoader(async () => {
    await getWorkspaceGrouped(workspaceId);
    await getCategoryList({ limit: 0 });
  });

  return (
    <Layout className="workspace">
      {contentLoading ? (
        <ContentLoader />
      ) : (
        <>
          <WorkspaceNavigationPanel
            subdivisionName={workspaceGrouped?.workspace.subdivisionName}
            catalog={workspaceGrouped?.catalog}
            categoryId={categoryId}
            scrollRef={scrollRef}
            onSelect={setCategoryId}
          />

          <Spin wrapperClassName="workspace__spin" spinning={workspaceLoading || workspaceGroupedLoading}>
            <div className="workspace__content">
              <Form className="workspace__content-form" form={form} onFinish={onSubmit}>
                <Form.Item name="name" rules={[rules.required()]}>
                  <Input placeholder="Введите название карты рабочего места" />
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue, getFieldError }) => {
                    const name = getFieldValue('name');
                    const error = getFieldError('name');

                    return (
                      <Button className="btn btn-default" htmlType="submit" disabled={!name?.length || !!error.length}>
                        Сохранить
                      </Button>
                    );
                  }}
                </Form.Item>
              </Form>

              <Button
                className="btn btn-primary"
                icon={<PlusOutlined />}
                onClick={onAddPositionClick}
                disabled={!workspaceGrouped}
              >
                Добавить позицию
              </Button>

              <WorkspaceList
                categoryList={workspaceGrouped?.categories}
                scrollRef={scrollRef}
                editPosition={editPosition}
                copyPosition={copyPosition}
                deletePosition={deletePosition}
              />
            </div>
          </Spin>
        </>
      )}

      <CategoryCascaderModal
        open={openCategoryCascaderModal}
        categoryList={categoryList}
        onCancel={onCategoryCascaderModalCancelClick}
        onSelect={onCategoryCascaderModalSelectClick}
      />

      <Notification
        open={openDeleteWorkspacePositionModal}
        type={ENotificationType.Warning}
        description={`Позиция “${getPositionName(position)}” будет удалена`}
        loading={workspaceLoading}
        onConfirmClick={onDeleteWorkspacePositionModalConfirmClick}
        onCancelClick={onDeleteWorkspacePositionModalCancelClick}
      />
    </Layout>
  );
};

const mapState = (state: RootState) => ({
  categoryListState: state.categoryListState,
  workspaceState: state.workspaceState,
  workspaceGroupedState: state.workspaceGroupedState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getCategoryList: dispatch.categoryListState.getCategoryList,
  updateWorkspace: dispatch.workspaceState.updateWorkspace,
  setWorkspaceError: dispatch.workspaceState.setWorkspaceError,
  getWorkspaceGrouped: dispatch.workspaceGroupedState.getWorkspaceGrouped,
});

export const Workspace = connect(mapState, mapDispatch)(WorkspaceComponent);
