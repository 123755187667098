import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { destroyMessage, showLoadingMessage, showSuccessMessage } from 'common/helpers/message.helper';
import { IRootModel } from 'app/store';
import { categoriesTransport } from 'entities/Categories/Categories.transport';
import {
  ICategoryListParams,
  ICategoryCreatePayload,
  ICategoryDeletePayload,
  ICategoryDetailed,
  ICategory,
  ICategoryListState,
  ICategoryState,
  ICategoryUpdatePayload,
} from 'entities/Categories/Categories.models';

export const categoryListState = createModel<IRootModel>()({
  state: {
    data: [],
    count: 0,
    loading: false,
  } as ICategoryListState,
  reducers: {
    setCategoryList: (state, payload: ICategory[]) => ({ ...state, data: payload }),
    setCategoryListCount: (state, payload: number) => ({ ...state, count: payload }),
    setCategoryListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    addCategory: (state, payload: ICategoryDetailed) => ({
      ...state,
      data: [...state.data, payload],
    }),
    updateCategory: (state, payload: ICategoryDetailed) => ({
      ...state,
      data: state.data.map((category) => (category.id === payload.id ? payload : category)),
    }),
    deleteCategory: (state, payload: number) => ({
      ...state,
      data: state.data.filter(({ id }) => id !== payload),
    }),
  },
  effects: (dispatch) => ({
    async getCategoryList(params: ICategoryListParams) {
      dispatch.categoryListState.setCategoryListLoading(true);

      try {
        const response = await categoriesTransport.getCategoryList(params);
        dispatch.categoryListState.setCategoryList(response.data);
        dispatch.categoryListState.setCategoryListCount(response.count);
        return response.data;
      } catch (error) {
        axiosErrorHandler(error);
        return null;
      } finally {
        dispatch.categoryListState.setCategoryListLoading(false);
      }
    },
    async searchCategoriesByName(params: ICategoryListParams) {
      try {
        const response = await categoriesTransport.getCategoryList(params);
        return response.data;
      } catch (error) {
        axiosErrorHandler(error);
        return [];
      }
    },
  }),
});

export const categoryState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as ICategoryState,
  reducers: {
    setCategory: (state, payload: ICategoryDetailed | null) => ({ ...state, data: payload }),
    setCategoryLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getCategoryById(id: number) {
      dispatch.categoryState.setCategoryLoading(true);

      try {
        const response = await categoriesTransport.getCategoryById(id);
        dispatch.categoryState.setCategory(response);
        return response;
      } catch (error) {
        axiosErrorHandler(error);
        return null;
      } finally {
        dispatch.categoryState.setCategoryLoading(false);
      }
    },
    async createCategory(payload: ICategoryCreatePayload) {
      dispatch.categoryState.setCategoryLoading(true);

      showLoadingMessage(0);

      try {
        const response = await categoriesTransport.createCategory(payload);
        dispatch.categoryListState.addCategory(response);
        dispatch.categoryState.onSuccess(payload);
        showSuccessMessage();
      } catch (error) {
        destroyMessage();
        axiosErrorHandler(error);
      } finally {
        dispatch.categoryState.setCategoryLoading(false);
      }
    },
    async updateCategory(payload: ICategoryUpdatePayload) {
      dispatch.categoryState.setCategoryLoading(true);

      showLoadingMessage(0);

      try {
        const response = await categoriesTransport.updateCategory(payload);
        dispatch.categoryListState.updateCategory(response);
        dispatch.categoryState.setCategory(response);
        dispatch.categoryState.onSuccess(payload);
        showSuccessMessage();
      } catch (error) {
        destroyMessage();
        axiosErrorHandler(error);
      } finally {
        dispatch.categoryState.setCategoryLoading(false);
      }
    },
    async deleteCategory(payload: ICategoryDeletePayload, models) {
      dispatch.categoryState.setCategoryLoading(true);

      const selectedCategory = models.categoryState.data;

      showLoadingMessage(0);

      try {
        const response = await categoriesTransport.deleteCategory(payload);

        if (response.id === selectedCategory?.id) {
          dispatch.categoryState.setCategory(null);
        }

        dispatch.categoryListState.deleteCategory(response.id);
        dispatch.categoryState.onSuccess(payload);
        showSuccessMessage();
      } catch (error) {
        destroyMessage();
        axiosErrorHandler(error);
      } finally {
        dispatch.categoryState.setCategoryLoading(false);
      }
    },
    selectCategory(id: number, models) {
      const categoryList = models.categoryListState.data;
      const category = categoryList.find((categoryItem) => categoryItem.id === id);

      if (category) {
        dispatch.categoryState.setCategory(category as ICategoryDetailed);
      }
    },
  }),
});
