/* eslint-disable sonarjs/no-duplicate-string */
import { DEFAULT_EMPTY_VALUE } from 'common/config';
import {
  ESupplyCorrectionLabel,
  ESupplyCorrectionValue,
  ESupplyListMenuTab,
  ESupplyStatus,
  ESupplyStatusLabel,
} from 'common/const/enum';

export const supplyCorrectionOptions = [
  {
    label: ESupplyCorrectionLabel.price,
    value: ESupplyCorrectionValue.Price,
  },
  {
    label: ESupplyCorrectionLabel.date,
    value: ESupplyCorrectionValue.Date,
  },
];

export const supplyListFilterStatusOptionsForUser = [
  {
    label: 'Все статусы',
    value: DEFAULT_EMPTY_VALUE,
  },
  {
    label: ESupplyStatusLabel.created,
    value: ESupplyStatus.Created,
  },
  {
    label: ESupplyStatusLabel.processing,
    value: ESupplyStatus.Processing,
  },
  {
    label: ESupplyStatusLabel.pending,
    value: ESupplyStatus.Pending,
  },
  {
    label: ESupplyStatusLabel.approved,
    value: ESupplyStatus.Approved,
  },
  {
    label: ESupplyStatusLabel.delivery,
    value: ESupplyStatus.Delivery,
  },
  {
    label: ESupplyStatusLabel.delivered,
    value: ESupplyStatus.Delivered,
  },
  {
    label: ESupplyStatusLabel.cancelled,
    value: ESupplyStatus.Cancelled,
  },
];

export const supplyListForManagerMenuTabs = [
  {
    key: ESupplyListMenuTab.Pending,
    label: 'Требующие подтверждение',
  },
  {
    key: ESupplyListMenuTab.Processing,
    label: 'Ожидаемые поставки',
  },
  {
    key: ESupplyListMenuTab.Completed,
    label: 'Завершённые поставки',
  },
];

export const incomingSupplyListForSellerMenuTabs = [
  {
    key: ESupplyListMenuTab.Created,
    label: 'Новые заказы',
  },
  {
    key: ESupplyListMenuTab.Processing,
    label: 'Переданы в учётную систему',
  },
  {
    key: ESupplyListMenuTab.Pending,
    label: 'На рассмотрении у заявителя',
  },
];

export const supplyListForSellerMenuTabs = [
  {
    key: ESupplyListMenuTab.Active,
    label: 'Активные поставки',
  },
  {
    key: ESupplyListMenuTab.Completed,
    label: 'Завершенные поставки',
  },
];
