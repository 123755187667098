import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Spin, Table } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { GoodsDeliveryBadge } from 'common/components/GoodsDeliveryBadge';
import { SuccessDrawer } from 'common/components/SuccessDrawer';
import { WarningDrawer } from 'common/components/WarningDrawer';
import { useDebounce } from 'common/hooks/useDebounce';
import { showMessage } from 'common/helpers/message.helper';
import { removeStorageItem, saveStorageItem } from 'common/helpers/axios.helper';
import { EDateFormat, EMessage, ERoute, EStorageKey, EUserRole } from 'common/const/enum';
import { ReactComponent as InfoIcon } from 'app/assets/images/redesign/info-red.svg';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { RootDispatch, RootState } from 'app/store';
import { BasketEmpty } from 'entities/Basket/components/BasketEmpty';
import { BasketProgress } from 'entities/Basket/components/BasketProgress';
import { BasketRequestComposition } from 'entities/Basket/components/BasketRequestComposition';
import { BasketAddGoodsButton } from 'entities/Basket/components/BasketAddGoodsButton';
import { BasketMoreButton } from 'entities/Basket/components/BasketMoreButton';
import {
  filterBasketGoodsList,
  getBasketGoodsListDataSource,
  getBasketGoodsListRowKeys,
  getBasketGoodsPayload,
  getBasketRequestCompositionInfo,
  goodsToBasketGoods,
  renderBasketRecords,
} from 'entities/Basket/Basket.helper';
import { IBasketGoods } from 'entities/Basket/Basket.models';
import { IGoods } from 'entities/Goods/Goods.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = (props) => {
  const {
    // state
    auth,
    xBasketId,
    basket,
    basketLoading,
    basketGoodsCount,
    requestsCount = 0,
    // dispatch
    getBasketById,
    updateBasket,
    createBasketRequest,
    updateStatisticsRequestsCount,
    setAuthXBasketId,
    setAuthUserCatalogId,
  } = props;

  const [goodsList, setGoodsList] = useState<IBasketGoods[]>([]);
  const [selectedGoodsList, setSelectedGoodsList] = useState<IBasketGoods[]>([]);
  const [goodsId, setGoodsId] = useState<number | null>(null);
  const [countIsChanged, setCountIsChanged] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [openSuccessDrawer, setOpenSuccessDrawer] = useState<boolean>(false);
  const [openSuccessDrawerForLinkedBasket, setOpenSuccessDrawerForLinkedBasket] = useState<boolean>(false);
  const [openWarningDrawer, setOpenWarningDrawer] = useState<boolean>(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const basketId = id ? Number(id) : undefined;
  const isCurrentBasket = xBasketId === basket?.id;
  const isManager = auth?.access.roles.includes(EUserRole.Manager);
  const checkAll = goodsList.length === selectedGoodsList.length;
  const indeterminate = selectedGoodsList.length > 0 && selectedGoodsList.length < goodsList.length;
  const goodsListDataSource = useMemo(() => getBasketGoodsListDataSource(goodsList), [goodsList]);
  const goodsListSelectedRowKeys = useMemo(() => getBasketGoodsListRowKeys(selectedGoodsList), [selectedGoodsList]);
  const goodsListExpandedRowKeys = useMemo(() => getBasketGoodsListRowKeys(goodsList), [goodsList]);
  const { amountWithTaxes, availableAmount, limitExceeded } = useMemo(() => {
    return getBasketRequestCompositionInfo(selectedGoodsList, basket?.availableLimit);
  }, [selectedGoodsList, basket?.availableLimit]);

  const onCountChange = (id: number, value: number) => {
    setGoodsList((prev) => prev.map((goods) => (id === goods.goodId ? { ...goods, count: value } : goods)));
    setSelectedGoodsList((prev) => prev.map((goods) => (id === goods.goodId ? { ...goods, count: value } : goods)));
    setCountIsChanged(true);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    if (indeterminate) {
      setSelectedGoodsList([]);
      return;
    }

    if (e.target.checked) {
      setSelectedGoodsList(filterBasketGoodsList.byMaxGoodPrice(goodsList, basket?.maxGoodPrice));
    } else {
      setSelectedGoodsList([]);
    }
  };

  const onGoodsSelectionChange = (id: number, checked: boolean) => {
    if (checked) {
      const goods = goodsList.find((goods) => goods.goodId === id) as IBasketGoods;

      setSelectedGoodsList((prev) => [...prev, goods]);
    } else {
      setSelectedGoodsList((prev) => filterBasketGoodsList.notEqualId(prev, id));
    }
  };

  const onDeleteGoodsClick = () => {
    if (basket && goodsId) {
      const newGoodsList = filterBasketGoodsList.notEqualId(goodsList, goodsId);

      updateBasket({
        id: basket.id,
        goods: getBasketGoodsPayload(newGoodsList),
        onSuccess: () => {
          setGoodsList(newGoodsList);
          setSelectedGoodsList((prev) => filterBasketGoodsList.notEqualId(prev, goodsId));
          setOpenWarningDrawer(false);
          showMessage({ content: EMessage.GoodsHasBeenRemoved, icon: <BinIcon className="basket__message-icon" /> });
        },
      });
    }
  };

  const handleAddToBasket = (goods: IGoods, onSuccess: () => void) => {
    if (basket) {
      const goodsForBasket = goodsToBasketGoods(goods);
      const newGoodsList = [...goodsList, goodsForBasket];

      updateBasket({
        id: basket.id,
        goods: getBasketGoodsPayload(newGoodsList),
        onSuccess: () => {
          setGoodsList(newGoodsList);

          if (
            basket?.maxGoodPrice !== undefined &&
            basket.maxGoodPrice >= goodsForBasket.price &&
            goodsForBasket.isAvailableForCustomer
          ) {
            setSelectedGoodsList((prev) => [...prev, goodsForBasket]);
          }

          onSuccess();
        },
      });
    }
  };

  const onLinkBasket = () => {
    if (basket) {
      saveStorageItem(EStorageKey.XBasketId, basket.id);
      saveStorageItem(EStorageKey.UserCatalogId, basket.defaultCatalogId as number);
      setAuthXBasketId(basket.id);
      setAuthUserCatalogId(basket.defaultCatalogId as number);
      setOpenSuccessDrawerForLinkedBasket(true);
    }
  };

  const onUnlinkBasket = () => {
    removeStorageItem(EStorageKey.XBasketId);
    removeStorageItem(EStorageKey.UserCatalogId);
    setAuthXBasketId(null);
    setAuthUserCatalogId(null);
  };

  const onSendRequestClick = async () => {
    setRequestLoading(true);
    await createBasketRequest({
      goods: getBasketGoodsPayload(selectedGoodsList),
      onSuccess: () => {
        setOpenSuccessDrawer(true);

        if (isManager) {
          updateStatisticsRequestsCount(requestsCount + selectedGoodsList.length);
        }

        setGoodsList((prev) => {
          return prev.filter((goods) => {
            return !selectedGoodsList.some((selectedGoods) => selectedGoods.goodId === goods.goodId);
          });
        });
        setSelectedGoodsList([]);
      },
    });
    setRequestLoading(false);
  };

  useEffect(() => {
    const fetch = async () => {
      if (basketId) {
        setFetchLoading(true);
        const response = await getBasketById(basketId);

        if (response) {
          const { goods, maxGoodPrice } = response;

          setGoodsList(goods);
          setSelectedGoodsList(filterBasketGoodsList.byMaxGoodPrice(goods, maxGoodPrice));
        }

        setFetchLoading(false);
      }
    };

    fetch();
  }, [basketId]);

  useDebounce(() => {
    if (basket && countIsChanged) {
      updateBasket({
        id: basket.id,
        goods: getBasketGoodsPayload(goodsList),
        onSuccess: () => setCountIsChanged(false),
      });
    }
  }, [basket, goodsList, countIsChanged]);

  return (
    <div className="redesign basket">
      <div className="basket__container">
        <div className="basket__header">
          <BasketAddGoodsButton loading={basketLoading} isCurrentBasket={isCurrentBasket} addToBasket={handleAddToBasket} />

          <BasketMoreButton
            id={basket?.id}
            isSeller
            isCurrentBasket={isCurrentBasket}
            onLink={onLinkBasket}
            onUnlink={onUnlinkBasket}
          />
        </div>

        <div className="basket__content">
          <div className="basket__title">
            {/* eslint-disable-next-line */}
            <span className="text-h1">{`Корзина ${basket?.id ? `#${basket.id}` : ''}`}</span>

            <span className="text-h3 basket__title-count">{basketGoodsCount}</span>
          </div>

          {!!goodsList.length && (
            <Checkbox checked={checkAll} indeterminate={indeterminate} style={{ marginBottom: 16 }} onChange={onCheckAllChange}>
              Включить в заявку
            </Checkbox>
          )}

          <Spin wrapperClassName="basket__spin" spinning={fetchLoading} indicator={<SpinIndicator />}>
            <div className="basket__scroll">
              <div className="basket__scroll-container">
                {!!goodsList.length && (
                  <Table
                    className="table-expandable basket__goods-list"
                    rowClassName="expanded"
                    dataSource={goodsListDataSource}
                    columns={renderBasketRecords(onCountChange)}
                    rowSelection={{
                      selectedRowKeys: goodsListSelectedRowKeys,
                      onSelect: ({ goodId }, checked) => onGoodsSelectionChange(goodId, checked),
                      columnWidth: 20,
                      getCheckboxProps: ({ price, isAvailableForCustomer }) => ({
                        disabled: !isAvailableForCustomer
                          ? true
                          : basket?.maxGoodPrice !== undefined
                          ? basket.maxGoodPrice < price
                          : false,
                      }),
                    }}
                    expandable={{
                      showExpandColumn: false,
                      expandedRowKeys: goodsListExpandedRowKeys,
                      expandedRowRender: ({ goodId, count, remains, price, isAvailableForCustomer }) => {
                        return (
                          <>
                            {basket?.maxGoodPrice !== undefined && basket.maxGoodPrice < price && (
                              <div className="basket__goods-list-item-notification">
                                <InfoIcon />

                                <span className="text-tag color-white">
                                  Стоимость позиции превышает допустимый лимит на цену товара.
                                </span>
                              </div>
                            )}

                            {!isAvailableForCustomer && (
                              <div className="basket__goods-list-item-notification">
                                <InfoIcon />

                                <span className="text-tag color-white">Товар недоступен для заказа</span>
                              </div>
                            )}

                            <div className="basket__goods-list-item-footer">
                              <GoodsDeliveryBadge remains={remains} count={count} />

                              <Button
                                className="button-text"
                                onClick={() => {
                                  setGoodsId(goodId);
                                  setOpenWarningDrawer(true);
                                }}
                              >
                                <span>Удалить товар</span>

                                <BinIcon />
                              </Button>
                            </div>
                          </>
                        );
                      },
                    }}
                    pagination={false}
                    showHeader={false}
                  />
                )}

                <BasketEmpty show={!basket?.goods.length && !basketLoading} />
              </div>
            </div>
          </Spin>

          {basket?.availableLimit && (
            <BasketProgress
              limit={basket?.availableLimit}
              amount={amountWithTaxes}
              availableAmount={availableAmount}
              limitExceeded={limitExceeded}
            />
          )}
        </div>
      </div>

      {!!goodsList.length && (
        <BasketRequestComposition
          basket={basket}
          isSeller
          isCurrentBasket={isCurrentBasket}
          selectedGoodsList={selectedGoodsList}
          limitExceeded={limitExceeded}
          loading={requestLoading}
          onSendClick={onSendRequestClick}
        />
      )}

      <SuccessDrawer
        open={openSuccessDrawer}
        content={`Заявка от ${dayjs().format(EDateFormat.FullDateDotSeparator)} в очереди на рассмотрение`}
        subtitle="Отправлено!"
        onClose={() => setOpenSuccessDrawer(false)}
      />

      <SuccessDrawer
        open={openSuccessDrawerForLinkedBasket}
        content={`Корзина #${basket?.id} привязана к вашему аккаунту. Вы можете добавлять в неё товары из каталога напрямую.`}
        subtitle="Готово!"
        btnTitle="В каталог"
        onClose={() => setOpenSuccessDrawerForLinkedBasket(false)}
        onConfirm={() => navigate(ERoute.Catalog)}
      />

      <WarningDrawer
        open={openWarningDrawer}
        content="Вы уверены, что хотите удалить товар?"
        subtitle="Отменить данное действие будет невозможно."
        confirmBtnTitle="Удалить"
        loading={basketLoading}
        onClose={() => setOpenWarningDrawer(false)}
        onConfirm={onDeleteGoodsClick}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  auth: state.authState.data,
  xBasketId: state.authState.xBasketId,
  basket: state.basketState.data,
  basketLoading: state.basketState.loading,
  basketGoodsCount: state.basketState.goodsCount,
  requestsCount: state.statisticsState.data.requestsCount,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getBasketById: dispatch.basketState.getBasketById,
  updateBasket: dispatch.basketState.updateBasket,
  createBasketRequest: dispatch.basketState.createBasketRequest,
  updateStatisticsRequestsCount: dispatch.statisticsState.updateStatisticsRequestsCount,
  setAuthXBasketId: dispatch.authState.setAuthXBasketId,
  setAuthUserCatalogId: dispatch.authState.setAuthUserCatalogId,
});

export const ActiveBasket = connect(mapState, mapDispatch)(Component);
