import dayjs, { Dayjs } from 'dayjs';
import type { RangePickerProps } from 'antd/es/date-picker';
import { EDateFormat } from 'common/const/enum';

export const toPayloadDate = (date: Dayjs) => date.format(EDateFormat.FullDateReverse);

export const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  return current && current < dayjs().startOf('day');
};

export const getCounterWidthStyles = (formattedValue: string, minWidth: number, maxWidth: number) => {
  return { maxWidth: `${maxWidth}px`, minWidth: `${minWidth}px`, width: `${Math.min(formattedValue.length * 8, 300)}px` };
};
