import axios from 'axios';
import { IAuth, IAuthLoginPayload, IRestorePasswordEmailPayload, IRestorePasswordPayload } from 'entities/Auth/Auth.models';

const basePath = 'auth';

export const authTransport = {
  addAuth: (payload: IAuthLoginPayload): Promise<IAuth> => axios.post(`${basePath}`, payload),
  deleteAuth: (): Promise<void> => axios.delete(`${basePath}`),
  refreshToken: (refreshToken: string): Promise<IAuth> => axios.put(`${basePath}`, { refreshToken }),
  sendRestorePasswordEmail: (payload: IRestorePasswordEmailPayload): Promise<void> =>
    axios.post(`${basePath}/send-restore-password-email`, payload),
  restorePassword: (payload: IRestorePasswordPayload): Promise<IAuth> => axios.patch(`${basePath}/restore-password`, payload),
};
