import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { ContentLoader } from 'common/components/ContentLoader';
import { EMessage, EModalCollectionKey, ESettingsMenuTab } from 'common/const/enum';
import { toDataSourceMapper } from 'common/helpers/data.helper';
import { getSettingsPath } from 'common/helpers/router.helper';
import { DEFAULT_BASIC_DISCOUNT } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { renderDiscountListRecords } from 'entities/Discount/Discount.helper';
import { CreateDiscountModal } from 'entities/Modal/components/CreateDiscountModal';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export const DiscountListComponent: FC<AllType> = (props) => {
  const { discountListState, discountState, openCreateDiscountModal, getDiscountList, createDiscount, showModal, hideModal } =
    props;

  const { data: discountList, loading: discountListLoading } = discountListState;
  const { loading: discountLoading } = discountState;

  const [discountName, setDiscountName] = useState<string>('');
  const navigate = useNavigate();

  const dataSource = toDataSourceMapper(discountList);
  const columns = renderDiscountListRecords();

  const onCreateNewDiscountClick = () => {
    showModal(EModalCollectionKey.CreateDiscount);
  };

  const onCreateDiscountModalCancelClick = () => {
    setDiscountName('');
    hideModal(EModalCollectionKey.CreateDiscount);
  };

  const onCreateDiscountModalAddClick = () => {
    createDiscount({ name: discountName, discountValue: DEFAULT_BASIC_DISCOUNT, onSuccess: onCreateDiscountModalCancelClick });
  };

  const { contentLoading } = useContentLoader(async () => {
    await getDiscountList({ limit: 0 });
  });

  if (contentLoading) {
    return <ContentLoader />;
  }

  return (
    <div className="discount-list">
      <div className="discount-list__title">Скидки</div>

      <Button className="btn btn-primary" onClick={onCreateNewDiscountClick}>
        <PlusOutlined />

        <span>Новый вид скидок</span>
      </Button>

      <Table
        className="discount-list__table"
        dataSource={dataSource}
        columns={columns}
        showHeader={false}
        pagination={false}
        loading={discountListLoading}
        locale={{ emptyText: EMessage.DiscountListIsEmpty }}
        onRow={(record) => ({ onClick: () => navigate(getSettingsPath(ESettingsMenuTab.Discounts, record.id)) })}
      />

      <CreateDiscountModal
        open={openCreateDiscountModal}
        loading={discountLoading}
        name={discountName}
        changeName={setDiscountName}
        onCancelClick={onCreateDiscountModalCancelClick}
        onAddClick={onCreateDiscountModalAddClick}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  discountListState: state.discountListState,
  discountState: state.discountState,
  openCreateDiscountModal: state.modalCollection.createDiscountModalParams.open,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getDiscountList: dispatch.discountListState.getDiscountList,
  createDiscount: dispatch.discountState.createDiscount,
  showModal: dispatch.modalCollection.showModal,
  hideModal: dispatch.modalCollection.hideModal,
});

export const DiscountList = connect(mapState, mapDispatch)(DiscountListComponent);
