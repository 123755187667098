import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { getSidebarItemClassName } from 'common/helpers/sidebar.helper';

interface IComponentProps {
  to: string;
  icon: React.ReactNode;
  label: string;
  end?: boolean;
  badge?: number | string | null;
  iconClassName?: string;
  onClick?: () => void;
}

export const SidebarItem: FC<IComponentProps> = ({ to, icon, label, end, badge, iconClassName, onClick }) => {
  return (
    <NavLink to={to} className={getSidebarItemClassName} onClick={onClick} end={end}>
      <div className="sidebar-r__item-content">
        <div className={`sidebar-r__item-icon ${iconClassName} ${badge ? 'sidebar-r__item-icon-badge' : ''}`}>{icon}</div>

        <div className="sidebar-r__item-label">
          <span className="text-h4">{label}</span>
        </div>

        {badge && <span className="text-tag color-white sidebar-r__item-badge">{badge}</span>}
      </div>
    </NavLink>
  );
};
