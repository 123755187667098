import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Select, Spin } from 'antd';
import { IOption } from 'common/models';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { ISubdivisionUpdatePayload } from 'entities/Subdivisions/Subdivisions.models';

interface IComponentProps {
  subdivisionId?: number;
  subdivisionLegalId?: number;
  options: IOption[];
  loading: boolean;
  updateSubdivision: (payload: ISubdivisionUpdatePayload) => Promise<void>;
}

export const SubdivisionLegal: React.FC<IComponentProps> = (props) => {
  const { subdivisionId, subdivisionLegalId, options, loading, updateSubdivision } = props;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [legalId, setLegalId] = useState<number | null>(null);

  const subdivisionLegal = options.find((option) => option.value === legalId);

  const toggleModalVisibility = () => setShowModal((prev) => !prev);

  const onCancelClick = () => {
    toggleModalVisibility();
  };

  const onSetClick = () => {
    if (subdivisionId && legalId) {
      updateSubdivision({ id: subdivisionId, legalId, onSuccess: onCancelClick });
    }
  };

  useEffect(() => {
    setLegalId(subdivisionLegalId ? subdivisionLegalId : null);
  }, [subdivisionLegalId]);

  return (
    <div className="subdivision-info__field">
      <div className="subdivision-info__label">Юридическое лицо</div>

      <div className="subdivision-info__field-container">
        <Input value={subdivisionLegal?.label} placeholder="Выберите юридическое лицо" disabled />

        <Button className="btn btn-primary" onClick={toggleModalVisibility}>
          Изменить
        </Button>
      </div>

      <Modal className="modal-gray" open={showModal} footer={false} closeIcon={false} width={360}>
        <Spin spinning={loading}>
          <div className="modal-gray__body">
            <div className="modal-gray__title">Юридическое лицо</div>

            <Select
              options={options}
              value={legalId}
              onChange={setLegalId}
              suffixIcon={<ChevronIcon />}
              placeholder="Выберите юридическое лицо"
            />
          </div>

          <div className="modal-gray__footer">
            <Button className="btn btn-default" onClick={onCancelClick}>
              Отмена
            </Button>

            <Button className="btn btn-primary" onClick={onSetClick} disabled={!legalId}>
              Установить
            </Button>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};
