import React, { FC } from 'react';

interface IComponentProps {
  className?: string;
  title: string;
  description: string;
  children: React.ReactNode;
  onClick?: () => void;
}

export const ContractRow: FC<IComponentProps> = ({ className, title, description, children, onClick }) => (
  <div className={`contract-row ${className ? className : ''}`} onClick={onClick}>
    <div>
      <div>{title}</div>

      <div className="contract-row__description">{description}</div>
    </div>

    {children}
  </div>
);
