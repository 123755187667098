import React, { FC, ReactNode } from 'react';

interface IComponentProps {
  icon: ReactNode;
  name: string;
  iconClassName?: string;
}

export const NameLabel: FC<IComponentProps> = ({ icon, name, iconClassName }) => {
  return (
    <div className="name-label" title={name}>
      <div className={`text-profile-icon color-bright-green name-label__icon ${iconClassName}`}>{icon}</div>

      <div className="text-h4-item-name color-bright-green name-label__name ellipsis">{name}</div>
    </div>
  );
};
