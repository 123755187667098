import { IOption } from 'common/models';

export const sortSelectOptions = (options: IOption[]) => {
  return options.sort((a, b) => {
    if (isNaN(Number(a.label))) {
      return a.label.localeCompare(b.label);
    } else {
      return Number(a.label) - Number(b.label);
    }
  });
};
