import React from 'react';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { EAccountStatus, EAccountStatusTitle, EColor } from 'common/const/enum';
import { ThreeDotMenu } from 'common/components/ThreeDotMenu';
import { IAccount, IAccountListState } from 'entities/Account/Account.models';
import { IUser } from 'entities/Users/Users.models';
import { getUserName } from 'entities/Users/Users.helper';

const getAccountStatusColor = (status: string) => {
  if (status === 'active') {
    return EColor.Blue;
  }

  return EColor.Gray;
};

export const renderAccountListRecords = (
  handleLoginToAccountClick: (id: number, isSeller: boolean) => void,
  handleEditClick: (account: IAccount) => void,
  handleBlockClick: (accountId: number) => void,
  handleUnblockClick: (accountId: number) => void,
  handleDeleteClick: (accountId: number) => void,
  handleMakeSellerClick: (id: number) => void,
) => {
  return [
    {
      key: 'status',
      onCell: () => {
        return {
          className: 'account-list__cell-status',
        };
      },
      render: (_, record: IAccount) => (
        <div className={`status ${getAccountStatusColor(record.status)}`}>{EAccountStatusTitle[record.status]}</div>
      ),
    },
    {
      key: 'id',
      onCell: () => {
        return {
          className: 'account-list__cell-id',
        };
      },
      render: (_, record) => <span className="account-list__item-id">{record.id}</span>,
      ellipsis: true,
    },
    {
      key: 'name',
      render: (_, record) => <span className="account-list__item-name">{record.name}</span>,
      ellipsis: true,
    },
    {
      key: 'tariffPlan',
      onCell: () => {
        return {
          className: 'account-list__cell-tariffPlan',
        };
      },
      render: (_, record) => (
        <div className="account-list__item-tariffPlan">
          <span>{record.tariffPlan}</span>
        </div>
      ),
    },
    {
      key: 'actions',
      onCell: () => {
        return {
          className: 'account-list__cell-actions',
        };
      },
      render: (_, record) => (
        <ThreeDotMenu
          items={[
            {
              key: 'login',
              label: (
                <Button
                  onClick={() => handleLoginToAccountClick(record.id, record.isSeller)}
                  disabled={record.status === EAccountStatus.Blocked}
                >
                  Войти в аккаунт
                </Button>
              ),
              className: 'login',
            },
            {
              key: 'createSeller',
              label: (
                <Button onClick={() => handleMakeSellerClick(record.id)} disabled={record.isSeller}>
                  Сделать продавцом
                </Button>
              ),
              className: 'createSeller',
            },
            {
              key: 'edit',
              label: (
                <Button onClick={() => handleEditClick(record)} disabled={record.status === EAccountStatus.Blocked}>
                  Редактировать
                </Button>
              ),
              className: 'edit',
            },
            {
              key: 'block',
              label: (
                <Button
                  onClick={() => {
                    if (record.status === EAccountStatus.Blocked) {
                      handleUnblockClick(record.id);
                    } else {
                      handleBlockClick(record.id);
                    }
                  }}
                >
                  {record.status === EAccountStatus.Blocked ? 'Разблокировать' : 'Заблокировать'}
                </Button>
              ),
              className: 'block',
            },
            {
              key: 'delete',
              label: <Button onClick={() => handleDeleteClick(record.id)}>Удалить</Button>,
              className: 'delete',
            },
          ]}
        />
      ),
    },
  ] as ColumnsType<IAccount>;
};

export const filterAccountUsers = (search: string, userList?: IUser[]) => {
  if (!userList) {
    return [];
  }

  return userList.filter((user) => {
    return (
      getUserName(user.firstName, user.lastName).toLowerCase().includes(search.toLowerCase()) ||
      user.email.toLowerCase().includes(search.toLowerCase())
    );
  });
};

export const updateAccountListState = {
  addAccount: (state: IAccountListState, payload: IAccount) => ({ ...state, data: [...state.data, payload] }),
  updateAccount: (state: IAccountListState, payload: IAccount) => ({
    ...state,
    data: state.data.map((account) => {
      if (account.id === payload.id) {
        return payload;
      }

      return account;
    }),
  }),
  deleteAccount: (state: IAccountListState, payload: number) => ({
    ...state,
    data: state.data.filter((account) => account.id !== payload),
  }),
};
