import React, { useEffect } from 'react';
import { Layout, Spin, Table } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toDataSourceMapper } from 'common/helpers/data.helper';
import { RootDispatch, RootState } from 'app/store';
import { renderSpecificationListRecords } from 'entities/Specifications/Specifications.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const SpecificationListComponent: React.FC<AllType> = ({ specificationListState, getSpecificationList }) => {
  const { data, loading } = specificationListState;

  const navigate = useNavigate();

  const dataSource = toDataSourceMapper(data);
  const columns = renderSpecificationListRecords();

  useEffect(() => {
    getSpecificationList({ limit: 0 });
  }, []);

  return (
    <Layout className="specification-list">
      <Spin wrapperClassName="specification-list__spin" spinning={loading}>
        <div className="specification-list__container">
          <div className="specification-list__title">Спецификации</div>

          <Table
            dataSource={dataSource}
            columns={columns}
            className="specification-list__table"
            pagination={false}
            showHeader={false}
            size="middle"
            onRow={(record) => ({ onClick: () => navigate(`/specification/${record.id}`) })}
          />
        </div>
      </Spin>
    </Layout>
  );
};

const mapState = (state: RootState) => ({
  specificationListState: state.specificationListState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getSpecificationList: dispatch.specificationListState.getSpecificationList,
});

export const SpecificationList = connect(mapState, mapDispatch)(SpecificationListComponent);
