import React, { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { INavigationItem } from 'common/models';

interface IComponentProps {
  items: INavigationItem[];
}

export const NeedListNavigation: FC<IComponentProps> = ({ items }) => {
  const location = useLocation();

  return (
    <div className="need-list-navigation">
      {items.map((item) => {
        return (
          <NavLink
            className={`text-h1 ${location.pathname === item.path ? 'color-black' : 'color-dark-grey'}`}
            key={item.path}
            to={item.path}
          >
            {item.label}
          </NavLink>
        );
      })}
    </div>
  );
};
