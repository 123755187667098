import { useSearchParams } from 'react-router-dom';
import { ESearchParams } from 'common/const/enum';

export const useSearchParamsHook = () => {
  const [params, setParams] = useSearchParams();

  const getSearchParam = (key: ESearchParams | string) => {
    return params.get(key);
  };

  const setSearchParam = (key: ESearchParams, value: string | number) => {
    setParams(
      (params) => {
        params.set(key, value.toString());
        return params;
      },
      { replace: true },
    );
  };

  const removeSearchParam = (key: ESearchParams) => {
    setParams(
      (params) => {
        params.delete(key);
        return params;
      },
      { replace: true },
    );
  };

  return { searchParams: params, getSearchParam, setSearchParam, removeSearchParam, setParams };
};
