import React, { FC } from 'react';
import { Button, Input, Modal, Spin } from 'antd';

interface IComponentProps {
  open: boolean;
  loading: boolean;
  name: string;
  changeName: (value: string) => void;
  onCancelClick: () => void;
  onAddClick: () => void;
}

export const CreateDiscountModal: FC<IComponentProps> = ({ open, loading, name, changeName, onCancelClick, onAddClick }) => {
  return (
    <Modal className="modal-gray" open={open} footer={false} closeIcon={false} width={360}>
      <Spin spinning={loading}>
        <div className="modal-gray__body">
          <div className="modal-gray__title">Название</div>

          <Input placeholder="Введите название" value={name} onChange={(e) => changeName(e.target.value)} />
        </div>

        <div className="modal-gray__footer">
          <Button className="btn btn-default" onClick={onCancelClick}>
            Отмена
          </Button>

          <Button className="btn btn-primary" onClick={onAddClick} disabled={!name.trim().length}>
            Добавить
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};
