import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRequestSuccess } from 'common/models';
import { uploadExcelFile } from 'common/helpers/file';
import { IRootModel } from 'app/store';
import {
  ISpecificationListState,
  ISpecificationListParams,
  ISpecification,
  ISpecificationState,
  ISpecificationItem,
} from 'entities/Specifications/Specifications.models';
import { specificationsTransport } from 'entities/Specifications/Specifications.transport';

export const specificationListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as ISpecificationListState,
  reducers: {
    setSpecificationList: (state, payload: ISpecificationItem[]) => ({ ...state, data: payload }),
    setSpecificationListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getSpecificationList(params: ISpecificationListParams) {
      dispatch.specificationListState.setSpecificationListLoading(true);

      try {
        const response = await specificationsTransport.getSpecificationList(params);
        dispatch.specificationListState.setSpecificationList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.specificationListState.setSpecificationListLoading(false);
      }
    },
  }),
});

export const specificationState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as ISpecificationState,
  reducers: {
    setSpecification: (state, payload: ISpecification) => ({ ...state, data: payload }),
    setSpecificationLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    onSuccess<T extends IRequestSuccess>(payload: T) {
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    async getSpecificationById(id: number) {
      dispatch.specificationState.setSpecificationLoading(true);

      try {
        const response = await specificationsTransport.getSpecificationById(id);
        dispatch.specificationState.setSpecification(response);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.specificationState.setSpecificationLoading(false);
      }
    },
    async createSpecification(payload: any) {
      dispatch.specificationState.setSpecificationLoading(true);

      try {
        const response = await specificationsTransport.createSpecification(payload);
        dispatch.specificationState.setSpecification(response);
        dispatch.specificationState.onSuccess(payload);
        return response;
      } catch (error) {
        axiosErrorHandler(error);
        return null;
      } finally {
        dispatch.specificationState.setSpecificationLoading(false);
      }
    },
    async uploadSpecificationExcel(id: number) {
      dispatch.specificationState.setSpecificationLoading(true);

      try {
        const response = await specificationsTransport.uploadSpecificationExcel(id);
        uploadExcelFile(response, `specification_${id}`);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.specificationState.setSpecificationLoading(false);
      }
    },
  }),
});
