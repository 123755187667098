import { createModel } from '@rematch/core';
import { axiosErrorHandler } from 'common/helpers/axios.helper';
import { IRootModel } from 'app/store';
import { requestsTransport } from 'entities/Requests/Requests.transport';
import { IRequestListState, IRequestListParams, IRequest, IRequestState, IRequestItem } from 'entities/Requests/Requests.models';

export const requestListState = createModel<IRootModel>()({
  state: {
    data: [],
    loading: false,
  } as IRequestListState,
  reducers: {
    setRequestList: (state, payload: IRequestItem[]) => ({ ...state, data: payload }),
    setRequestListLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getRequestList(params: IRequestListParams) {
      dispatch.requestListState.setRequestListLoading(true);

      try {
        const response = await requestsTransport.getRequestList(params);
        dispatch.requestListState.setRequestList(response.data);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        dispatch.requestListState.setRequestListLoading(false);
      }
    },
  }),
});

export const requestState = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IRequestState,
  reducers: {
    setRequest: (state, payload: IRequest) => ({ ...state, data: payload }),
    setRequestLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getRequest(id: number) {
      dispatch.requestState.setRequestLoading(true);

      try {
        const response = await requestsTransport.getRequest(id);
        dispatch.requestState.setRequest(response);
        return response;
      } catch (error) {
        axiosErrorHandler(error);
        return null;
      } finally {
        dispatch.requestState.setRequestLoading(false);
      }
    },
  }),
});
