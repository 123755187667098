import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ERoute, ESearchParams, EStorageKey } from 'common/const/enum';
import { DEFAULT_FULL_LIST_LIMIT } from 'common/config';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { getStorageItem } from 'common/helpers/axios.helper';
import { RootDispatch, RootState } from 'app/store';
import { CatalogSearch } from 'entities/Catalog/components/CatalogSearch';
import { CatalogGoodsListFilter } from 'entities/Catalog/components/CatalogGoodsListFilter';
import { CatalogSelector } from 'entities/Catalog/components/CatalogSelector';
import { getCurrentCatalog } from 'entities/Catalog/Catalog.helper';
import { GoodsCard } from 'entities/Goods/components/GoodsCard-r';
import { CatalogMainPage } from 'entities/Catalog/components/CatalogMainPage';
import { CatalogCategoryPage } from 'entities/Catalog/components/CatalogCategoryPage';
import { CatalogSearchPage } from 'entities/Catalog/components/CatalogSearchPage';
import { CatalogCategoryListSkeleton } from 'entities/Catalog/components/CatalogCategoryListSkeleton';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const CatalogComponent: FC<AllType> = ({ catalogList, catalogListLoading, auth, xBasketId, getCatalogList, getBasketById }) => {
  const { catalogId, categoryId } = useParams();
  const navigate = useNavigate();
  const { getSearchParam } = useSearchParamsHook();

  const basketId = xBasketId ? xBasketId : auth?.access.basketId;
  const search = getSearchParam(ESearchParams.Search);
  const currentCatalog = getCurrentCatalog(catalogList, catalogId);

  const getPage = () => {
    if (currentCatalog) {
      if (search !== null) {
        return (
          <CatalogSearchPage
            catalogId={currentCatalog?.id}
            catalogName={currentCatalog?.name}
            catalogListLoading={catalogListLoading}
          />
        );
      }

      if (categoryId) {
        return (
          <CatalogCategoryPage
            catalogId={currentCatalog?.id}
            catalogName={currentCatalog?.name}
            categoryId={Number(categoryId)}
            catalogListLoading={catalogListLoading}
          />
        );
      }

      return (
        <CatalogMainPage
          catalogId={Number(catalogId)}
          catalogName={currentCatalog?.name}
          catalogListLoading={catalogListLoading}
        />
      );
    }

    return <CatalogCategoryListSkeleton />;
  };

  useEffect(() => {
    const fetch = async () => {
      if (basketId) {
        await getBasketById(basketId);
      }

      const userCatalogId = getStorageItem<number>(EStorageKey.UserCatalogId);
      const response = await getCatalogList({ limit: DEFAULT_FULL_LIST_LIMIT });
      const defaultCatalogId = response?.[0]?.id;

      if (!catalogId) {
        if (userCatalogId) {
          navigate(`${ERoute.Catalog}/${userCatalogId}`, { replace: true });
        } else if (defaultCatalogId) {
          navigate(`${ERoute.Catalog}/${defaultCatalogId}`, { replace: true });
        }
      }
    };

    fetch();
  }, []);

  return (
    <div className="redesign catalog">
      <div className="catalog__header">
        <CatalogSelector catalogId={catalogId} catalogList={catalogList} loading={catalogListLoading} />

        <CatalogSearch catalogId={currentCatalog?.id} catalogName={currentCatalog?.name} />

        <CatalogGoodsListFilter />
      </div>

      {getPage()}

      <GoodsCard />
    </div>
  );
};

const mapState = (state: RootState) => ({
  catalogList: state.catalogListState.data,
  catalogListLoading: state.catalogListState.loading,
  auth: state.authState.data,
  xBasketId: state.authState.xBasketId,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getCatalogList: dispatch.catalogListState.getCatalogList,
  getBasketById: dispatch.basketState.getBasketById,
});

export const Catalog = connect(mapState, mapDispatch)(CatalogComponent);
