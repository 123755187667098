import React, { FC } from 'react';
import { Button, Select } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/close.svg';
import { IStockCity } from 'entities/Delivery/Delivery.models';
import { deliveryInDaysOptions } from 'entities/Delivery/Delivery.const';

interface IComponentProps {
  stock: IStockCity;
  city?: string;
  onDeliveryInDaysChange: (id: number, value: number) => void;
  onDeleteStockCityClick: (id: number) => void;
}

export const StockCity: FC<IComponentProps> = ({ stock, city, onDeliveryInDaysChange, onDeleteStockCityClick }) => {
  const handleDeliveryInDaysChange = (value: number) => {
    onDeliveryInDaysChange(stock.id, value);
  };

  return (
    <div className="stock-city">
      <div className="stock-city__status">
        <CheckOutlined />
      </div>

      <div className="stock-city__name">
        <span className="stock-city__name-prefix">из </span>

        <span>{city}</span>
      </div>

      <Select
        options={deliveryInDaysOptions}
        value={stock.deliveryInDays}
        onChange={handleDeliveryInDaysChange}
        placeholder="Выберите срок доставки"
        suffixIcon={<ChevronIcon />}
      />

      <Button className="btn btn-icon stock-city__close" icon={<CloseIcon />} onClick={() => onDeleteStockCityClick(stock.id)} />
    </div>
  );
};
