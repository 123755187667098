import React, { ChangeEvent, useRef, useState } from 'react';
import { Button, Form, Input, Select, Space } from 'antd';
import type { InputRef } from 'antd';
import { IFormItemRule, IOption } from 'common/models';
import { sortSelectOptions } from 'common/helpers/select.helper';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';

interface IComponentProps {
  label?: string;
  name: string | number;
  rules?: IFormItemRule[];
  options: IOption[];
  mode?: 'multiple';
  className?: string;
  disabled?: boolean;
}

export const FormSelectWithCustomOptions: React.FC<IComponentProps> = (props) => {
  const { label, name, rules, options, mode, className, disabled } = props;
  const [items, setItems] = useState<IOption[]>(() => sortSelectOptions(options));
  const [newOptionName, setNewOptionName] = useState('');
  const inputRef = useRef<InputRef>(null);

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewOptionName(event.target.value);
  };

  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    setItems(sortSelectOptions([...items, { label: newOptionName, value: newOptionName }]));
    setNewOptionName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <Form.Item className={className} label={label} name={name} rules={rules}>
      {/* https://ant.design/components/select#select-demo-custom-dropdown-menu */}
      <Select
        options={items}
        showSearch={false}
        suffixIcon={<ChevronIcon />}
        mode={mode}
        disabled={disabled}
        dropdownRender={(menu) => {
          return (
            <>
              <Space className="form-select-with-custom-options-space">
                <Input ref={inputRef} value={newOptionName} onChange={onNameChange} onKeyDown={(e) => e.stopPropagation()} />

                <Button className="btn btn-primary" onClick={addItem} disabled={!newOptionName.length}>
                  Добавить
                </Button>
              </Space>
              {menu}
            </>
          );
        }}
      />
    </Form.Item>
  );
};
