import React, { ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Menu, Select } from 'antd';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { useNeedContext } from 'common/hooks/useNeedContext';
import { ENeedStatus, EOrderField, ERequestsMenuTab, ESearchParams } from 'common/const/enum';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { DEFAULT_EMPTY_VALUE, DEFAULT_LIST_LIMIT, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ArrowDownIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as ExportIcon } from 'app/assets/images/redesign/export.svg';
import { INeedListPayload } from 'entities/Need/Need.models';
import { getNeedListFilterOptions } from 'entities/Need/Need.helper';
import { CreatedNeedListForManager } from 'entities/Need/components/CreatedNeedListForManager';
import { PendingNeedListForManager } from 'entities/Need/components/PendingNeedListForManager';
import { incomingRequestsForManagerMenuTabs } from 'entities/Requests/Requests.const';

interface IComponentProps {
  header: ReactNode;
  navigation: ReactNode;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: React.FC<AllType> = (props) => {
  const {
    // props
    header,
    navigation,
    // state
    subdivisionList,
    userList,
    needList,
    needListLoading,
    needListCount,
    auth,
    // dispatch
    setNeedList,
    getNeedSubdivisionListForManager,
    getNeedUserList,
    getNeedList,
    getNeedListPart,
    uploadNeedExcel,
    getUserById,
  } = props;

  const { getSearchParam, setSearchParam } = useSearchParamsHook();
  const { page, setPage, setFetchLoading, setSelectedNeedList } = useNeedContext();

  const userId = auth?.access.userId;
  const subdivisionOptions = getNeedListFilterOptions.subdivision(subdivisionList);
  const userOptions = getNeedListFilterOptions.user(userList);
  const tab = getSearchParam(ESearchParams.Tab);

  const [payload, setPayload] = useState<INeedListPayload>({
    statuses: tab === ERequestsMenuTab.Pending ? [ENeedStatus.Pending] : [ENeedStatus.Created],
    orderField: tab === ERequestsMenuTab.Pending ? EOrderField.UpdatedAt : EOrderField.CreatedAt,
    asManager: true,
    limit: DEFAULT_LIST_LIMIT,
    offset: DEFAULT_LIST_OFFSET,
  });

  const onCurrentMenuTabChange = (key: ERequestsMenuTab) => {
    const newPayload = {
      statuses: key === ERequestsMenuTab.Pending ? [ENeedStatus.Pending] : [ENeedStatus.Created],
      orderField: key === ERequestsMenuTab.Pending ? EOrderField.UpdatedAt : EOrderField.CreatedAt,
      asManager: true,
      limit: DEFAULT_LIST_LIMIT,
      offset: DEFAULT_LIST_OFFSET,
    };

    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedNeedList([]);
    setNeedList([]);
    setSearchParam(ESearchParams.Tab, key);
    // @ts-ignore
    setPayload(newPayload);
    // @ts-ignore
    getNeedList(newPayload);
  };

  const onSubdivisionChange = async (value: number) => {
    const newPayload = { ...payload, subdivisionIds: value === DEFAULT_EMPTY_VALUE ? undefined : [value], userId: undefined };

    setPayload(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedNeedList([]);
    await getNeedList(newPayload);
    await getNeedUserList({ statuses: payload.statuses, subdivisionId: value === DEFAULT_EMPTY_VALUE ? undefined : value });
  };

  const onUserChange = async (value: number) => {
    const newPayload = { ...payload, userId: value === DEFAULT_EMPTY_VALUE ? undefined : value };

    setPayload(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedNeedList([]);
    await getNeedList(newPayload);
  };

  const onSearchClick = () => {
    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedNeedList([]);
    getNeedList(payload);
  };

  const renderContent = () => {
    if (tab === ERequestsMenuTab.Pending) {
      return (
        <PendingNeedListForManager
          list={needList}
          loading={needListLoading}
          count={needListCount}
          payload={payload}
          onPayloadChange={setPayload}
        />
      );
    }

    return (
      <CreatedNeedListForManager
        list={needList}
        loading={needListLoading}
        count={needListCount}
        payload={payload}
        onPayloadChange={setPayload}
      />
    );
  };

  useEffect(() => {
    const fetch = async () => {
      setFetchLoading(true);
      setPage(DEFAULT_PAGINATION_PAGE);
      setSelectedNeedList([]);

      if (userId) {
        await getUserById(userId);
      }

      await getNeedSubdivisionListForManager({ statuses: payload.statuses });
      await getNeedUserList({ statuses: payload.statuses });
      await getNeedList(payload);
      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <InfiniteScrollContainer
      canLoad={!needListLoading && needList.length < needListCount}
      page={page}
      scrollToTopTrigger={[payload]}
      onPageChange={setPage}
      callback={async (page) => {
        await getNeedListPart({ ...payload, offset: DEFAULT_LIST_LIMIT * page });
      }}
    >
      {header}

      {navigation}

      <Menu
        items={incomingRequestsForManagerMenuTabs}
        selectedKeys={[tab === null ? ERequestsMenuTab.Created : tab]}
        onClick={({ key }) => onCurrentMenuTabChange(key as ERequestsMenuTab)}
        mode="horizontal"
      />

      <div className="need-list__filters">
        <Select
          rootClassName="redesign"
          popupClassName="need-list__filters-select-popup"
          className="need-list__filters-select"
          options={subdivisionOptions}
          value={payload.subdivisionIds ? payload.subdivisionIds[0] : DEFAULT_EMPTY_VALUE}
          onChange={onSubdivisionChange}
          placeholder="Выберите подразделение"
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownIcon />}
        />

        <Select
          rootClassName="redesign"
          popupClassName="need-list__filters-select-popup"
          className="need-list__filters-select"
          options={userOptions}
          value={payload.userId ? payload.userId : DEFAULT_EMPTY_VALUE}
          onChange={onUserChange}
          placeholder="Выберите пользователя"
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownIcon />}
        />

        <Input
          style={{ flex: 1 }}
          placeholder="Поиск"
          value={payload.search}
          onChange={(e) => setPayload((prev) => ({ ...prev, search: e.target.value }))}
        />

        <Button className="button-circle primary" icon={<SearchIcon />} onClick={onSearchClick} />

        <Button className="button-circle secondary" icon={<ExportIcon />} onClick={() => uploadNeedExcel(payload)} />
      </div>

      {renderContent()}
    </InfiniteScrollContainer>
  );
};

const mapState = (state: RootState) => ({
  subdivisionList: state.needListState.subdivisionList,
  userList: state.needListState.userList,
  needList: state.needListState.data,
  needListLoading: state.needListState.loading,
  needListCount: state.needListState.count,
  auth: state.authState.data,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setNeedList: dispatch.needListState.setNeedList,
  getNeedSubdivisionListForManager: dispatch.needListState.getNeedSubdivisionListForManager,
  getNeedUserList: dispatch.needListState.getNeedUserList,
  getNeedList: dispatch.needListState.getNeedList,
  getNeedListPart: dispatch.needListState.getNeedListPart,
  uploadNeedExcel: dispatch.needListState.uploadNeedExcel,
  getUserById: dispatch.userState.getUserById,
});

export const IncomingRequestsForManager = connect(mapState, mapDispatch)(Component);
