/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Checkbox, Layout, Spin, Table } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import dayjs from 'dayjs';
import { EDateFormat, EMessage, ENotificationType, ERoute, ESpecificationStatus } from 'common/const/enum';
import { useDebounce } from 'common/hooks/useDebounce';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as CloseIcon } from 'app/assets/images/close.svg';
import { renderSpecificationRecords, specificationGoodsToDataSource } from 'entities/Specifications/Specifications.helper';
import { ISpecificationGoods } from 'entities/Specifications/Specifications.models';
import { Notification } from 'entities/Modal/components/Notification';
import { AddVendorCodeModal } from 'entities/Modal/components/AddVendorCodeModal';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

// TODO

const SpecificationComponent: React.FC<AllType> = (props) => {
  const { specificationState, getSpecificationById, uploadSpecificationExcel } = props;

  const { data, loading } = specificationState;

  const { id } = useParams();
  const navigate = useNavigate();
  const [goodsList, setGoodsList] = useState<ISpecificationGoods[]>([]);
  const [selectedGoodsIds, setSelectedGoodsIds] = useState<number[]>([]);
  const [goodsIsChanged, setGoodsIsChanged] = useState<boolean>(false);
  const [goodsId, setGoodsId] = useState<number | null>(null);
  const [vendorCode, setVendorCode] = useState<string>('');
  const [changeSpecificationLoading, setChangeSpecificationLoading] = useState<boolean>(false);
  const [openDeleteSpecificationGoodsModal, setOpenDeleteSpecificationGoodsModal] = useState<boolean>(false);
  const [openDeleteSpecificationSelectedGoodsModal, setOpenDeleteSpecificationSelectedGoodsModal] = useState<boolean>(false);
  const [openDeleteSpecificationModal, setOpenDeleteSpecificationModal] = useState<boolean>(false);
  const [openAddVendorCodeModal, setOpenAddVendorCodeModal] = useState<boolean>(false);

  const canUpdateSpecification = data?.status === ESpecificationStatus.OnApproval;
  const dataSource = specificationGoodsToDataSource(goodsList);
  const columns = renderSpecificationRecords(onGoodsCountChange, onAddVendorCodeClick, !canUpdateSpecification);
  const checkAll = !!goodsList.length && goodsList.length === selectedGoodsIds.length;

  const onCancelClick = () => {
    navigate(ERoute.SpecificationList);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const checkedList = goodsList.map((goods) => goods.positionId);
    setSelectedGoodsIds(e.target.checked ? checkedList : []);
  };

  function onGoodsCountChange(positionId: number, count: number) {
    setGoodsIsChanged(true);

    setGoodsList((prev) => {
      const goodsIndex = prev.findIndex((item) => item.positionId === positionId);

      prev.splice(goodsIndex, 1, {
        ...prev[goodsIndex],
        count,
      });

      return [...prev];
    });
  }

  function onAddVendorCodeClick(goodsId: number, initialValue?: string) {
    setGoodsId(goodsId);

    if (initialValue) {
      setVendorCode(initialValue);
    }

    setOpenAddVendorCodeModal(true);
  }

  const onDeleteGoodsClick = (goodsId: number) => {
    setGoodsId(goodsId);

    // Last item in specification
    if (goodsList.length === 1) {
      setOpenDeleteSpecificationModal(true);
    } else {
      setOpenDeleteSpecificationGoodsModal(true);
    }
  };

  const onDeleteSpecificationGoodsModalCancelClick = () => {
    setGoodsId(null);
    setOpenDeleteSpecificationGoodsModal(false);
  };

  const onDeleteSpecificationGoodsModalConfirmClick = () => {};

  const onDeleteSelectionClick = () => {
    if (goodsList.length === selectedGoodsIds.length) {
      setOpenDeleteSpecificationModal(true);
    } else {
      setOpenDeleteSpecificationSelectedGoodsModal(true);
    }
  };

  const onDeleteSpecificationSelectedGoodsModalCancelClick = () => {
    setOpenDeleteSpecificationSelectedGoodsModal(false);
  };

  const onDeleteSpecificationSelectedGoodsModalConfirmClick = () => {};

  const onDeleteSpecificationModalCancelClick = () => {
    setGoodsId(null);
    setOpenDeleteSpecificationModal(false);
  };

  const onDeleteSpecificationModalConfirmClick = () => {
    if (data) {
      setChangeSpecificationLoading(true);
    }
  };

  const onOrderClick = () => {};

  const onAddVendorCodeModalCancelClick = () => {
    setVendorCode('');
    setGoodsId(null);
    setOpenAddVendorCodeModal(false);
  };

  const onAddVendorCodeModalAddClick = () => {
    if (data) {
      const goodsIndex = goodsList.findIndex((goods) => goods.goodId === goodsId);

      goodsList.splice(goodsIndex, 1, {
        ...goodsList[goodsIndex],
        vendorCode,
      });
    }
  };

  columns.push({
    dataIndex: 'delete',
    render: (_, record) => {
      return (
        <Button
          className="btn btn-icon"
          icon={<CloseIcon />}
          onClick={() => onDeleteGoodsClick(record.goodId)}
          disabled={!canUpdateSpecification}
        />
      );
    },
  });

  useEffect(() => {
    if (id) {
      getSpecificationById(Number(id));
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      setGoodsList(data.goods);
    }
  }, [data]);

  useDebounce(() => {}, [data, goodsList, goodsIsChanged]);

  if (!data && loading) {
    return (
      <div className="spin-container">
        <Spin />
      </div>
    );
  }

  return (
    <Layout className="specification">
      <Spin wrapperClassName="specification__spin" spinning={loading}>
        <div className="specification__container">
          <div className="specification__header">
            <div>
              <span className="specification__title">Спецификация</span>

              {data?.id && <span className="specification__id">{`№ ${data?.id}`}</span>}

              {data?.createdAt && (
                <span className="specification__date">{`от ${dayjs(data?.createdAt).format(
                  EDateFormat.FullDateDotSeparator,
                )}`}</span>
              )}

              <Button className="btn btn-icon specification__close" icon={<CloseIcon />} onClick={onCancelClick} />
            </div>

            {data && (
              <Button className="btn btn-primary" onClick={() => uploadSpecificationExcel(data.id)}>
                Экспортировать в Excel
              </Button>
            )}
          </div>

          <Button
            className="btn btn-primary"
            onClick={onOrderClick}
            disabled={!selectedGoodsIds.length || !canUpdateSpecification}
          >
            Оформить заказ
          </Button>

          <div className="specification__actions">
            <Checkbox checked={checkAll} onChange={onCheckAllChange} disabled={!goodsList.length || !canUpdateSpecification}>
              Выделить все / Снять выделение
            </Checkbox>

            <Button
              className="specification__actions-remove-all"
              icon={<CloseIcon />}
              onClick={onDeleteSelectionClick}
              disabled={!goodsList.length || !selectedGoodsIds.length}
            >
              Удалить выделенное
            </Button>
          </div>

          <Table
            rowSelection={{
              selectedRowKeys: selectedGoodsIds,
              onChange: (selectedRowKeys: React.Key[]) => {
                setSelectedGoodsIds(selectedRowKeys as number[]);
              },
              getCheckboxProps: () => ({ disabled: !canUpdateSpecification }),
            }}
            className="specification__table"
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            showHeader={false}
          />
        </div>
      </Spin>

      <Notification
        open={openDeleteSpecificationGoodsModal}
        type={ENotificationType.Warning}
        description={EMessage.GoodsWillBeRemovedFromSpecification}
        loading={changeSpecificationLoading}
        onCancelClick={onDeleteSpecificationGoodsModalCancelClick}
        onConfirmClick={onDeleteSpecificationGoodsModalConfirmClick}
      />

      <Notification
        open={openDeleteSpecificationSelectedGoodsModal}
        type={ENotificationType.Warning}
        description={EMessage.SelectedGoodsWillBeRemovedFromSpecification}
        loading={changeSpecificationLoading}
        onCancelClick={onDeleteSpecificationSelectedGoodsModalCancelClick}
        onConfirmClick={onDeleteSpecificationSelectedGoodsModalConfirmClick}
      />

      <Notification
        open={openDeleteSpecificationModal}
        type={ENotificationType.Warning}
        description={EMessage.SpecificationWillBeRemoved}
        loading={changeSpecificationLoading}
        onCancelClick={onDeleteSpecificationModalCancelClick}
        onConfirmClick={onDeleteSpecificationModalConfirmClick}
      />

      <AddVendorCodeModal
        open={openAddVendorCodeModal}
        loading={loading}
        vendorCode={vendorCode}
        onVendorCodeChange={setVendorCode}
        onCancelClick={onAddVendorCodeModalCancelClick}
        onAddClick={onAddVendorCodeModalAddClick}
      />
    </Layout>
  );
};

const mapState = (state: RootState) => ({
  specificationState: state.specificationState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getSpecificationById: dispatch.specificationState.getSpecificationById,
  uploadSpecificationExcel: dispatch.specificationState.uploadSpecificationExcel,
});

export const Specification = connect(mapState, mapDispatch)(SpecificationComponent);
