import React from 'react';
import { Button } from 'antd';

interface IComponentProps {
  title: string;
  description: string | string[];
  btnTitle?: string;
  onClick: () => void;
}

export const Frame: React.FC<IComponentProps> = ({ title, description, btnTitle = 'Редактировать', onClick }) => {
  return (
    <div className="frame">
      <div className="frame__body">
        <p className="frame__body-title">{title}</p>

        {Array.isArray(description) ? (
          <div className="frame__body-description-container">
            {description.map((item, index) => (
              <span key={index} className="frame__body-description-item">
                {item}
              </span>
            ))}
          </div>
        ) : (
          <span className="frame__body-description">{description}</span>
        )}
      </div>

      <div className="frame__footer">
        <Button className="btn btn-primary" onClick={onClick}>
          {btnTitle}
        </Button>
      </div>
    </div>
  );
};
