import React, { RefObject } from 'react';
import { Button } from 'antd';
import { propertyListToPresetList, renderPresetList } from 'common/helpers/preset.helper';
import { ThreeDotMenu } from 'common/components/ThreeDotMenu';
import { IWorkspaceCategory } from 'entities/Workspaces/Workspaces.models';

interface IComponentProps {
  categoryList?: IWorkspaceCategory[];
  scrollRef: RefObject<HTMLDivElement>;
  editPosition: (categoryId: number, positionId: number) => void;
  copyPosition: (categoryId: number, positionId: number) => void;
  deletePosition: (positionId: number) => void;
}

export const WorkspaceList: React.FC<IComponentProps> = (props) => {
  const { categoryList, scrollRef, editPosition, copyPosition, deletePosition } = props;

  return (
    <div className="workspace-list">
      {!categoryList?.length ? (
        <div>Список пуст. Добавьте позицию из Каталога</div>
      ) : (
        <div className="workspace-list__container" ref={scrollRef}>
          {categoryList?.map((category) => {
            return (
              <div key={category.categoryId} className="workspace-list__category" id={category.categoryId.toString()}>
                <div className="workspace-list__category-title">{category.categoryName as string}</div>

                <div>
                  {category.positions
                    .sort((a, b) => b.properties.length - a.properties.length)
                    .map((position) => {
                      const presetList = propertyListToPresetList(position.properties);

                      return (
                        <div key={position.positionId} className="preset">
                          <div className="preset__container">{renderPresetList(presetList)}</div>

                          <ThreeDotMenu
                            items={[
                              {
                                key: 'edit',
                                label: (
                                  <Button onClick={() => editPosition(position.categoryId, position.positionId)}>
                                    Редактировать
                                  </Button>
                                ),
                              },
                              {
                                key: 'copy',
                                label: (
                                  <Button onClick={() => copyPosition(position.categoryId, position.positionId)}>
                                    Дублировать
                                  </Button>
                                ),
                              },
                              {
                                key: 'delete',
                                label: <Button onClick={() => deletePosition(position.positionId)}>Удалить</Button>,
                                className: 'delete',
                              },
                            ]}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
