import { useEffect, useRef } from 'react';

export const useHorizontalScroll = (deps: any[]) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;

      container.scrollTo({
        left: container.scrollWidth,
        behavior: 'smooth',
      });
    }
  }, [containerRef, ...deps]);

  return { containerRef };
};
