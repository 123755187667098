import axios from 'axios';
import { IInvitationActivatePayload, IInvitationCreatePayload, IInvitation } from 'entities/Invitation/Invitation.models';

const basePath = 'invitation';

export const invitationTransport = {
  getInvitation: (hash: string): Promise<IInvitation> => axios.get(`${basePath}/${hash}`),
  activateInvitation: (payload: IInvitationActivatePayload): Promise<IInvitation> => axios.patch(`${basePath}/activate`, payload),
  inviteUser: (payload: IInvitationCreatePayload): Promise<IInvitation> => axios.post(`${basePath}`, payload),
};
